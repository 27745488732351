.overview-chart {
  background: #fff;
  border-radius: 20px;
  padding: 3rem 2rem;
  margin-right: 0.5rem;
  @media (max-width: 840px) {
    margin: 20px 0 15px;
  }

  &-notes {
    margin-left: 1rem;
    font-weight: 300;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #222222;
  }
}
