.default_element {
  width: 100%;
  position: absolute;
  height: 100%;
  right: 0;
  bottom: 0px;
  background: black;
}

.conference_chime {
  position: relative;
  width: 100%;
  background: white;
  height: calc(100vh - 27.5rem);
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 2px solid white;
  @media screen and (max-width: 840px) {
    height: calc(100vh - 15rem);
  }

  #sharevideo {
    width: 80% !important;
  }
}

.chime_main__view {
  position: absolute !important;
  left: 0;
  top: 0;
  width: 80%;
  height: 100%;
  // z-index: -10;
}

.chime_side__view {
  position: absolute !important;
  height: 100px !important;
}

.disable_mute {
  pointer-events: none;
}

.disabled__video {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, #000000, #000000);
  border: 1px solid #3e3e3e;
  position: relative;

  &_info {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    padding: 0 0.5rem;
    justify-content: space-between;
    .img-wrap-eve {
      background-color: #a0a0a5;
      border-radius: 50%;
      padding: 0.1rem;
      display: grid;
      place-items: center;
      width: 2rem;
      height: 2rem;
    }
    img {
      height: 1rem;
      object-fit: contain;
    }
  }
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
// .scrollbar_chime::-webkit-scrollbar {
//   display: none;
// }

/* Hide scrollbar for IE, Edge and Firefox */
// .scrollbar {
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */
// }

/* background of the scrollbar except button or resizer */
.scrollbar_chime::-webkit-scrollbar-track {
  background-color: #fff;
}
.scrollbar_chime::-webkit-scrollbar-track:hover {
  background-color: #a0a0a5;
}

/* scrollbar itself */
.scrollbar_chime::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}
.scrollbar_chime::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
.scrollbar_chime::-webkit-scrollbar-button {
  display: none;
}

/* div box */

.scrollbar_chime {
  // height: 68vh;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  gap: 1rem;
  padding-left: 1rem;
  width: 20%;
  min-height: 0;
  overflow-y: auto;
  flex-grow: 1;
}

.remoteChimePlayer {
  width: 100% !important;
  min-height: 92px;
  position: relative;
}

.volume__controls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: auto;
  max-width: 90%;
  margin: 0.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  background: rgba(75, 79, 82, 0.6);
  padding: 3px 2px 3px 2px;
  font-size: 10px;
  color: #fff;
  padding-right: 1rem;
}

.disabled__container {
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  background: #3e3e3e;
  display: grid;
  place-items: center;
  position: relative;
  .disabled__video__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 4rem;
    object-fit: contain;
    border-radius: 50%;
    border: 2px solid rgb(190, 189, 189);
  }
  .disabled__video__icon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    .video_off_icon {
      color: rgb(131, 130, 130);
      font-size: 2rem;
      font-weight: 100;
      position: relative;
      &::after {
        content: '';
        height: 1.5px;
        width: 145%;
        background: rgb(131, 130, 130);
        position: absolute;
        top: 0;
        left: -35%;
        transform: rotate(-45deg);
        margin-top: 40%;
      }
    }
  }
}

.grid__view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;

  ._active-speaker {
    border-color: rgb(255, 0, 0);
    // animation: mymove 2s infinite;
    @keyframes mymove {
      from {
        border-color: rgb(255, 0, 0);
      }
      to {
        border-color: rgb(255, 255, 255);
      }
    }
  }
  &__item {
    flex: 1;
    position: relative;
    border: 3px solid white;

    .disabled__container .disabled__video__image {
      height: 8rem;
    }
  }
}

.chime_main__view .disabled__container .disabled__video__image {
  height: 15rem;
}
