.list-wapper {
    width: 100%;
    text-align: center;
    // display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 32px;

    .trivia {
        margin: 5rem 15rem;
        color: #000000;
        border: 1px solid lightgray!important;
        box-shadow: 0 2px 4px #00000040;
        border-radius: 15px;
        .header {
            // display: flex;
            width: 100%;

            .title {
                font-size: 24px;
                font-weight: 600;
                text-align: left;
            }

            .add {
                font-size: 16px;
                font-weight: 500;
                text-align: right;
                color: #256BF6;

                &:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }
            }
        }

        .question {
            text-align: left;

            &_body {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #F4F4F4;
                border: 1px soid lightgray;
                border-radius: 15px;
                min-height: 84px;
                padding: 0 10px;

                &_text {
                    padding: 0 10px;
                }

                &_action {
                    min-width: 70px;

                    &_edit {
                        padding: 0 3px;

                        &:hover {
                            cursor: pointer;
                            opacity: 0.8;
                        }
                    }

                    &_delete {
                        padding: 0 3px;

                        &:hover {
                            cursor: pointer;
                            opacity: 0.8;
                        }
                    }
                }

                &:hover {
                    cursor: pointer;
                    box-shadow: 0 20px 20px #00000040;

                }
            }
        }
    }

}

@media screen and (min-width:780px) and (max-width: 1050px) {
    .list-wapper {
        padding-top: 32px;

        .trivia {
            margin: 0 3.5rem;

            .header {
                padding-top: 0;
                padding-bottom: 0;
            }

            .question {
                padding: 0;

            }
        }

    }
}

@media screen and (max-width:780px) {
    .list-wapper {
        padding-top: 32px;

        .trivia {
            margin: 0 1.5rem;

            .header {
                padding-top: 0;
                padding-bottom: 0;
            }

            .question {
                padding: 0;

                &_body {
                    &_action {
                        min-width: 50px;
                    }
                }
            }
        }

    }
}