.event__status {
  width: 100%;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vh 1rem;
  gap: 1rem;
  border: 1px solid rgb(221, 221, 252);
  &-container {
    background: #ca293e;
    border-radius: 10px;
    // border-top-right-radius: 0;
    // border-bottom-right-radius: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3rem;
    color: #ffffff;
    @media (max-width: 840px) {
      padding: 0 1rem;
    }
    .__count_down {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 0;
    }

    .live__status {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    h1 {
      font-size: 20px;
      line-height: 25px;
      color: #fff;
      /* identical to box height */
    }
  }

  .join_button {
    height: 100%;
    background: green;
    color: white;
    display: grid;
    place-items: center;
    padding: 1rem 7rem;
    cursor: pointer;
    border-radius: 15px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    text-transform: uppercase;
    font-weight: bold;
    &:hover {
      background: rgb(16, 133, 16);
    }
    @media (max-width: 840px) {
      padding: 1rem;
    }
  }

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .review {
    &__container {
      flex: 1;
      padding: 0 2rem;
      span {
        margin-right: 1rem;
        font-weight: bold;
      }
    }

    &__total {
      cursor: pointer;
      color: rgba(46, 112, 242, 1);
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
