.intro-summary-head {
  display: flex;
  align-items: center;
  gap: 1rem;

  &-icon {
    font-size: 1.2rem;
    background: #f4f5fa;
    padding: 0.5rem;
    border-radius: 10px;
    aspect-ratio: 1;
  }
  &-text {
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1.2rem;
      font-weight: 400;
      color: #6c7b8a;
      margin-bottom: 0;
    }
  }
}
