.main_header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  .main-herader-inner {
    display: flex;
    align-items: center;
  }
  .wrap_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85px;
    height: 50px;
    margin-right: 5rem;

    .cloning_progress {
      background-color: transparent;
      color: white;
      border: none;
      font-size: 18px;
    }

    #cloning_course {
      color: white;
      font-size: 14px;
    }
  }

  &__logo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
  }
  .shl-learning-center {
    background: #2d6ff2;
    border: none;
    outline: none;
    text-shadow: none;
    span {
      color: #fff;
    }
  }

  &__goto_button {
    border-radius: 100px;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ffffff;
    color: #ffffff;
    cursor: pointer;
    gap: 1rem;
    padding: 0.7rem 2rem;

    &:hover {
      opacity: 0.7;
      color: #ffffff;
    }
  }
  .menu-toggle-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .menu-toggle {
      display: none;
      margin-top: 1.3rem;
      margin-left: 0.3rem;
      border: none;
      box-shadow: none;
      float: right;
      font-size: 32px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      padding: 0;
      position: relative;
      margin-right: 20px;
      @media (max-width: 1180px) {
        display: block;
      }
      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #fff;
        box-shadow: 0 1px 0px #000;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: transform 0.35s ease;
        &:nth-child(1) {
          top: 0px;
        }
        &:nth-child(2),
        &:nth-child(3) {
          top: 8px;
        }
        &:nth-child(4) {
          top: 16px;
        }
      }
    }
  }
}
@media (max-width: 1180px) {
  .author-menus,
  #language_select,
  .wrap_started,
  .sharebot_button,
  .main_header__goto_button,
  .mr-mobile-menu.show-menu,
  .mr-mobile-author-menu {
    display: none !important;
  }
  .show-menu #language_select,
  .show-menu .wrap_started,
  .show-menu .sharebot_button,
  .show-menu .main_header__goto_button,
  .mr-mobile-menu.show-menu {
    display: flex !important;
  }

  .show-menu .author-menu {
    display: block !important;
  }
}
@media (min-width: 1180px) {
  .mr-mobile-menu {
    display: none;
  }
}

.mr-mobile-menu {
  display: none;
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08),
    9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
  width: 50vh !important;
  max-width: 100vw;
  position: fixed;
  z-index: 9999;
  transition: all 0.3s;
  .mr-mobile-content {
    width: 100%;
    height: 100%;
    overflow: auto;
    background: #1d1d1d;
    pointer-events: auto;
    padding: 15px;
    .sharelook-line {
      width: 100%;
      height: 1px;
      background: #f0f0f0;
      position: absolute;
      right: 0;
      margin-top: 10px;
    }
    .mr-mobile-brand-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .mr-mobile-item {
      box-sizing: border-box;
      font-variant: tabular-nums;
      font-feature-settings: 'tnum', 'tnum';
      margin: 0;
      padding: 0;
      color: rgb(255, 255, 255);
      font-size: 14px;
      line-height: 0;
      text-align: left;
      list-style: none;
      outline: none;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 3rem;

      li {
        display: flex;
        align-items: center;
        transition: border-color 0.3s, background 0.3s,
          padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);

        padding: 1rem;

        > a,
        > div {
          border: none;
          outline: none;
          text-shadow: none;
          padding: 0;
          background-color: transparent;
          margin: 0;
          color: rgb(255, 255, 255);
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 1rem;

          i {
            font-size: 20px;
          }

          &:hover {
            span {
              color: rgba(240, 240, 240, 0.781);
            }

            i {
              color: rgba(240, 240, 240, 0.781);
            }
          }
          span {
            color: #ffffff;
            font-weight: 500;
            font-size: 14px;
            cursor: pointer;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 0.25px;
          }
          img {
            margin-right: 10px;
          }
        }
        #mobile_language_select {
          margin: 0;
          width: 100%;
          > div:nth-child(1) {
            width: 100% !important;
            div {
              background: transparent;
              color: #ffffff;
              height: 55px;
              line-height: 55px;
              text-shadow: none;
              span {
                color: #ffffff;
              }
            }
          }
        }
        .author-menu {
          background: transparent !important;
          border: 1px solid #f0f0f0;
          width: 100%;
          padding: 0 !important;
        }
      }

      .mr-mobile-list-item {
        .author-menu {
          > div {
            display: block !important;
            > div {
              border-left: none;
              > div {
                right: auto;
                padding: 10px;
                color: #ffffff;
                letter-spacing: 1px;
                text-shadow: none;
                width: 100%;
                i {
                  position: absolute;
                  right: 10px;
                }
                > div:nth-child(2) {
                  > div:nth-child(1) {
                    font-weight: 600;
                    text-transform: uppercase;
                    color: rgba(255, 255, 255, 0.75);
                    line-height: 1.3;
                  }
                  > div:nth-child(2) {
                    font-weight: bold;
                    text-transform: uppercase;
                    color: #ffffff;
                    line-height: 1.3;
                    font-size: 14px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .mr-close-wrapper {
    text-align: right;
    padding: 10px 7px;
    .mr-close {
      width: 20px;
      height: 20px;
      cursor: pointer;
      display: inline-block;
      position: relative;
      background: transparent;
      border: none;
      transition: all 0.4s ease-in-out 0.3s;
      transform: scale(1) rotate(0deg);
      .mr-inner {
        display: inline-block;
        &::before {
          content: '';
          position: absolute;
          background: #ffffff;
          top: 50%;
          left: 50%;
          width: 2px;
          height: 100%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
        &::after {
          content: '';
          position: absolute;
          background-color: #ffffff;
          top: 50%;
          left: 50%;
          width: 2px;
          height: 100%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }
  }
}

#report_type_select,
#report-header-time {
  position: relative;
  z-index: 10;
}

.wrap_progress_bar {
  width: 350px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.wrap_started {
  background: #2d6ff2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 1rem;
  border-radius: 20px;
  padding: 0.65rem 1.75rem;
  img {
    width: 18px;
    height: 18px;
  }
  span {
    color: white;
    background-color: transparent;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  &:hover {
    opacity: 0.8;
  }
}

.sharebot_button {
  cursor: pointer;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  border: 1.5px solid #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  &:hover {
    border-top: 1.5px solid #698bf2;
    border-right: 1.5px solid #698bf2;
    border-bottom: 1.5px solid #5057d5;
    border-left: 1.5px solid #5057d5;
  }
  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
  }
}

.popup .ant-modal-content {
  top: -8em;
  background: transparent;
}

.popup .ant-modal-close-x {
  background: #ffffff;
  padding: 6px;
}

.info-modal .ant-modal-close-x {
  padding: 12px 12px 0px 0px;
  font-size: 28px;
}

.video_preview {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 5em;

  .ant-carousel {
    width: 55% !important;
  }

  i {
    font-size: 24px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 2rem;
    background-color: #333333;
    cursor: pointer;
  }
}

.__title {
  color: #ffffff;
  font-size: 30px;
  text-align: center;
}

form {
  #language_select {
    .ant-select {
      width: auto !important;
    }
  }
}

#language_select {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  .ant-select-selection {
    background-color: transparent !important;
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
  .ant-select-selection__placeholder {
    color: #ffffff;
  }
  .ant-select-arrow {
    color: rgba(255, 255, 255, 255);
    display: none;
  }
  .ant-select-dropdown-menu-item-selected {
    color: #ffffff;
    font-weight: 600;
    background-color: #126a9e;
  }
  .ant-select-selection-selected-value {
    color: #ffffff;
  }
  .ssl-flag-wrap {
    width: 45px;
    height: 45px;
    color: transparent;
    img {
      border-radius: 100%;
      height: 100%;
      width: 100%;
      padding: 2px;
      object-fit: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1.5px solid #e3e3e3;
      &:hover {
        border-top: 1px solid #698bf2;
        border-right: 1px solid #698bf2;
        border-bottom: 1px solid #5057d5;
        border-left: 1px solid #5057d5;
      }
    }
  }
}

.rsc-header-title {
  color: white;
}

/*.rsc-ts-image{
  display: none;
}*/

.rsc-submit-button {
  display: none;
}

.buQDII {
  display: none;
}

.share-bot-icon {
  height: 40px;
  margin-top: 6px;
  margin-right: 2rem;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
}

.bot-container {
  width: 100%;
  .question {
    font-weight: bold;
    margin-left: 0;
    margin-top: 0;
    text-transform: capitalize;
  }
  .answer {
    &-top {
      display: flex;
      gap: 0.5rem;
      width: 100%;

      span {
        font-weight: 500;
      }
    }

    &-main {
      text-align: justify;
    }

    &-container {
      padding-right: 1rem;
    }

    &-action {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      position: relative;

      &-title {
        color: #389cd7;

        &:hover {
          text-decoration: underline;
        }
      }

      span {
        cursor: pointer;
        font-weight: bold;
        font-size: 1.5rem;
      }

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        background: rgb(241, 241, 241);
        position: absolute;
        bottom: -7.5px;
      }

      &-last::after {
        height: 0;
      }
    }
  }
  .context {
    margin-top: 2rem;
    text-align: justify;
    position: relative;
    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background: rgb(241, 241, 241);
      position: absolute;
      left: 0;
      bottom: -15px;
    }

    &-item {
      display: flex;
      gap: 2rem;
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      font-size: 14px;

      border-bottom: 1px solid black;

      h2 {
        font-size: 14px;
      }
    }
  }
}

.sharebot-header {
  background: #1890ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 1.5rem;
  font-size: 2rem;

  h2 {
    font-size: 2rem;
    color: white;
    margin-left: 1rem;
  }

  span {
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }
}

.shareboot-main-wrapper {
  @media (max-width: 840px) {
    right: 0 !important;
    bottom: 30px !important;
    margin: auto;
    width: 100%;
    height: calc(100% - 144px);
    border: none;
    padding: 15px;
  }
}

.sharebot-container {
  .rsc-content {
    position: relative;
    overflow-y: auto;
    height: calc(calc(100vh - 18rem) - 105px);
    .rsc-ts-bot:nth-of-type(2) {
      margin-top: 2rem;
    }

    .rsc-ts-bot .rsc-ts-image {
      box-shadow: none;
    }
  }

  .rsc-footer {
    margin: 1rem;
    input {
      font-size: 14px;
      border: 1px solid #2d6ff223;
    }
  }

  .rsc-cs {
    background: none;
    box-shadow: none;
  }

  .sharebot-tabs {
    position: fixed;
    z-index: 2;
    width: 96%;
    background: white;
    display: flex;
    justify-content: space-between;
    margin: 0 2rem;
    margin-top: -2.25rem;
    box-shadow: 0 0 1px -1px rgb(218, 218, 218);

    .sharebot-tab {
      padding: 1rem 3rem;
      width: 100%;
      text-align: center;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
        box-shadow: 0 0 1px 0 rgb(218, 218, 218);
      }
    }

    .active {
      background: #f5f8fb;
      border: none;
      color: #1890ff;
      font-weight: bold;
    }
  }
}

.reference {
  margin-top: 1rem;
  font-size: 1.25rem;
  border-top: 1px solid #efefef;
  padding-top: 1rem;

  span {
    font-weight: bold;
    text-decoration: solid;
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #000;
  }

  ul {
    margin-bottom: 0;
  }

  li {
    list-style-type: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &-content {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;

    &-container {
      max-height: 60vh;
      overflow-y: auto;
    }

    &-title {
      font-weight: bold;
      color: black;
      margin-bottom: 2rem;
    }

    &-all {
      margin-right: 2rem;
      text-align: justify;
    }
  }
}

.author-menu.mr-mobile-author-menu {
  height: 43px;
}

/* Ensuring the flag is displayed properly and round */

#language_select .ant-select-selection-item {
  display: flex;
  align-items: center;
}

#language_select .ant-select-selection-item .Flag {
  border-radius: 50%;
  overflow: hidden;
  width: 20px; /* Adjust the width and height as needed */
  height: 20px;
  margin-right: 8px; /* Space between the flag and the text */
}

/* Hide the text in the dropdown */
#language_select .ant-select-selection-item .ant-select-selection-item-content {
  display: none;
}

/* Ensuring the dropdown itself also hides the text but still shows the flag */

#language_select .ant-select-item-option-content {
  display: flex;
  align-items: center;
}

#language_select .ant-select-item-option-content .Flag {
  border-radius: 50%;
  overflow: hidden;
  width: 20px; /* Adjust the width and height as needed */
  height: 20px;
  margin-right: 8px; /* Space between the flag and the text */
}

#language_select .ant-select-item-option-content span {
  display: none; /* Hide the text in the dropdown items */
}
