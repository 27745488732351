.certificate_modal_wrap {
  position: relative;
  overflow: hidden;
}
.certificate_modal_title {
  padding: 0 20px;
  font-weight: bold;
  font-size: 23px;
  line-height: 29px;
  text-transform: capitalize;
  color: #000000;
}

.certificate_modal_content {
  display: flex;
  flex-direction: row;
  // max-height: 541px;
  .certificate_create_left {
    width: 55%;
    // max-height: 650px;
    // min-height: 650px;
    // overflow: auto;
  }
  .certificate_create_right {
    width: 45%;
    .detail {
      width: 100%;
    }
    .certificate_modal_title {
      padding-left: 0;
      margin-bottom: 20px;
    }
    .certificate_history {
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      height: 512px;
      border-radius: 16px;
      border: 1px solid #e3e3e3;
      .title {
        padding: 1.5rem;
        margin-bottom: 0px;
        border-bottom: 1px solid rgb(227, 227, 227);
        background: rgba(233, 239, 246, 0.3);
        border-radius: 16px 16px 0px 0px;
      }
      .history_items {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 1.5rem;
        .history_item {
          min-width: 100%;
          height: 65px;
          display: flex;
          margin-bottom: 10px;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          background: #fff;
          cursor: pointer;
          padding: 0.8rem;
          margin: 1rem 0;
          border: 1.5px solid #edf2f5;
          border-radius: 8px;
          &_details {
            display: flex;
            justify-content: space-evenly;
            flex: 1;
          }
          img {
            max-height: 45px;
            min-width: 65px;
            margin: 10px;
            object-fit: contain;
          }
          &_info {
            flex: 1;
            display: flex;
            margin-left: 5px;
            flex-direction: column;
            justify-content: center;
            align-items: left;
            h3 {
              font-weight: bold;
              font-size: 10px;
              line-height: 13px;
              color: #000000;
              word-break: break-all;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              padding-right: 15px;
            }
          }
          &_action {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 20px;
            justify-content: space-evenly;
            align-items: center;
            background: #f4f4f4;
            &_img {
              height: 50%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover {
                background: rgb(218, 217, 217);
              }
            }
            img {
              min-width: 10px;
              max-height: 10px;
            }
          }
        }
      }
    }
  }
}

.certificate_container {
  padding: 20px;

  .left_panel {
    .ant-tabs-tabpane {
      max-height: 160px;
      min-height: 160px;
      overflow: auto;
    }
    .ant-tabs-nav {
      background: #f4f4f4;
      position: relative;
      width: 100%;
      > div {
        display: flex;
        justify-content: space-between;
        background: #fff;
      }
    }
    .tabs {
      .tab {
        .certs-container {
          display: flex;
          align-items: center;
          justify-content: flex-start !important;
          overflow-x: auto !important;
          white-space: nowrap;
          gap: 10px;
          width: 100%;
          height: 100px;
          margin-bottom: 1rem;
          overflow-y: hidden;
          .certificate {
            &__icon {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              padding: 1rem 2rem;
              border: 1.5px solid #e3e3e3;
              border-radius: 8px;
              transition: 0.2s;
              margin-bottom: 1rem;
              &:hover {
                border: 1.5px solid #5057d5;
              }
              &.ant-radio-button-wrapper-checked {
                border: 1.5px solid #5057d5;
              }
            }
          }
        }
      }
    }
    // .tabs {
    //   .tab {
    //     .certs-container {
    //       padding: 0 !important;
    //       width: 100%;
    //       height: 100%;
    //       overflow-y: scroll !important;
    //       overflow-x: scroll;
    //     }
    //     .certificate {
    //       padding: 10px;

    //       &__icon {
    //         margin: 0;
    //         height: 80px;
    //         width: 80px;
    //       }
    //     }
    //     .icon {
    //       padding-left: 10px;
    //       width: 120%;
    //       max-height: 250px !important;
    //       grid-template-columns: repeat(3, 60px);
    //       border-bottom: 1px solid rgb(255, 255, 255);
    //       .certificate__icon {
    //         width: 35px;
    //         height: 35px;
    //         border: none;
    //         outline: none;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         background: none;
    //         img {
    //           width: 30px;
    //           height: 30px;
    //           object-fit: contain;
    //         }
    //         &:hover {
    //           background-color: whitesmoke;
    //         }
    //       }
    //     }
    //     .user_icon {
    //       max-height: 220px !important;
    //       display: grid;
    //       padding: 3px 0 0 5px;
    //       grid-template-columns: repeat(3, 50px);
    //       overflow-x: hidden;
    //       overflow-y: auto;
    //       .certificate__icon {
    //         width: 45px;
    //         height: 45px;
    //         border: none;
    //         outline: none;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         background: none;
    //         cursor: pointer;
    //         img {
    //           width: 30px;
    //           height: 30px;
    //           object-fit: contain;
    //         }
    //         &:hover {
    //           background-color: whitesmoke;
    //         }
    //       }
    //     }
    //     .text_position {
    //       display: flex;
    //       margin: 5px 0;
    //     }
    //     .certificate_color_picker {
    //       height: 30px;
    //       width: 172px;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //       color: white;
    //       background-color: rgb(99, 99, 250);
    //       border-radius: 15px;
    //       margin: 5px 5px;
    //       font-size: 12px;
    //       cursor: pointer;
    //     }
    //     .font_size_picker {
    //       width: 100%;
    //       height: 40px;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //       padding: 10px auto;
    //       border: 1px solid rgb(197, 196, 196);
    //       margin: 10px 0;
    //       background: rgb(206, 206, 207);
    //       cursor: pointer;
    //       &:hover {
    //         background: rgb(181, 181, 182);
    //       }
    //     }
    //     .btn-upload {
    //       position: relative;
    //       overflow: hidden;
    //       display: inline-block;
    //       cursor: pointer;
    //       width: 100%;
    //       input[type='file'] {
    //         position: absolute;
    //         opacity: 0;
    //         z-index: 0;
    //         max-width: 100%;
    //         height: 100%;
    //         display: block;
    //       }
    //       .btn {
    //         width: 100%;
    //         padding: 8px 20px;
    //         background: #337ab7;
    //         border: 1px solid #2e6da4;
    //         color: #fff;
    //         border: 0;
    //       }
    //       &:hover .btn {
    //         padding: 8px 20px;
    //         background: #2e6da4;
    //         color: #fff;
    //         border: 0;
    //       }
    //     }
    //     .text_env {
    //       padding: 10px;
    //       display: flex;
    //       flex-direction: column;
    //       input {
    //         margin: 15px 0;
    //       }
    //       .font_select {
    //         width: 100%;
    //       }
    //       .certificate_color_picker {
    //         margin-left: 0;
    //       }
    //       .text_decoration {
    //         width: 100%;
    //         display: flex;
    //         flex-direction: row;
    //         justify-content: space-between;
    //         margin-bottom: 15px;
    //         .item {
    //           flex: 1;
    //           display: flex;
    //           justify-content: center;
    //           align-items: center;
    //           padding: 10px;
    //           cursor: pointer;
    //           color: black;
    //           border: 1px solid rgb(199, 198, 198);
    //           background: rgb(206, 206, 207);
    //           cursor: pointer;
    //           &:hover {
    //             background: rgb(181, 181, 182);
    //           }
    //         }
    //         .checked {
    //           background: rgb(163, 182, 185);
    //         }
    //       }
    //       .text_position {
    //         display: flex;
    //         flex-direction: row;
    //         .text_picker {
    //           display: flex;
    //           justify-content: center;
    //           align-items: center;
    //           height: 30px;
    //           width: 50%;
    //           border: 1px solid black;
    //         }
    //       }
    //       .slant {
    //         display: flex;
    //         flex-direction: row;
    //         border: 1px solid black;

    //         .slant_picker {
    //           flex: 1;
    //           display: flex;
    //           justify-content: center;
    //           align-items: center;
    //           background: rgb(197, 197, 197);
    //           padding-left: 3px;
    //           overflow: hidden;
    //           cursor: pointer;
    //         }
    //         .slant_icon {
    //           width: 30px;
    //           height: 30px;
    //           display: flex;
    //           justify-content: center;
    //           align-items: center;
    //           background: rgb(255, 255, 255);
    //           cursor: pointer;
    //         }
    //       }
    //     }
    //     .user_upload_image {
    //       height: 100px;
    //       width: 100px;
    //       margin: 20px;
    //       object-fit: contain;
    //     }
    //   }
    // }
    .ant-tabs-content {
      margin-top: 0;
      margin-bottom: 0;
      background-color: #fff;
      height: auto;
      color: black;
    }
    .tab_icon {
      width: 80px;
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: space-evenly;
      .img-template {
        width: 50px;
        height: 50px;
        border: 1.5px solid #edf2f5;
        border-radius: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
      }
      span {
        font-size: 14px;
        color: #212121;
        font-weight: 600;
      }
    }
    .ant-tabs-tab {
      margin: 0;
      padding: 0;
    }
    .ant-tabs .ant-tabs-left-content {
      padding-left: 0;
    }
    .ant-tabs-tab-active {
      .tab_icon {
        background-color: #fff;
      }
      .img-template {
        border: 1.5px solid #5b6ee2;
      }
    }
    .ant-tabs-ink-bar {
      background-color: transparent;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      width: 0px;
      height: 0px;
      background-color: 'white';
      content: '';
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      z-index: 1;
      color: transparent;
      border: 1px solid #000000;
      -webkit-box-shadow: 0 0 0 0 transparent;
      box-shadow: 0 0 0 0 transparent;
    }
    .certificate__icon {
      width: 50px;
      height: 35px;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      > span {
        position: absolute;
        padding: 10px;
        &:last-child {
          position: absolute;
          padding: 10px;
        }
      }
      img {
        width: 30px;
        height: 30px;
      }
      &:hover {
        background-color: whitesmoke;
      }
    }
    .canvas {
      margin: 20px;
      width: 90%;
      height: 330px;
      background: #ffffff;
      &_info {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        .menu {
          border: 1px solid rgb(219, 216, 216);
          padding: 10px;
          border-radius: 5px;
          position: relative;
          width: 180px;
          margin-top: 10px;
          overflow: hidden;
          span {
            position: absolute;
            background-color: red;
            top: 0;
            right: 0;
            bottom: 0;
            color: white;
            width: 35px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            cursor: pointer;
            &:hover {
              background-color: rgb(238, 87, 87);
            }
          }
        }
      }
    }
  }
  .right_panel {
    width: 527px;
    background-color: #f4f4f4;
    height: 512px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .col {
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: space-between;
  }
}
.detail {
  margin-bottom: 1rem;
  .cert-item-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  input {
    border: none;
    background: #ffffff;
    border-radius: 5px;
    border: 1.5px solid #e3e3e3;
    &:focus {
      outline: none;
    }
    padding: 5px;
  }
  .title {
    input {
      height: 36px;
    }
  }
  .details {
    margin-top: 20px;
    textarea {
      height: 82px;
      border: none;
      background: #ffffff;
      border-radius: 5px;
      &:focus {
        outline: none;
      }
      padding: 5px;
      resize: none;
    }
  }
  .d-col {
    display: flex;
    flex-direction: column;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      margin-bottom: 5px;
      &::after {
        content: ' *';
        color: red;
      }
    }
  }
  .buttons {
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
      margin-left: 10px;
      height: 30px;
      padding: 5px 35px;
      background: #aaaaaa;
      border-radius: 50px;
      cursor: pointer;
      font-size: 13px;
      line-height: 16px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
}
.save_certificate {
  margin: 40px auto 0 auto;
  height: 30px;
  background: linear-gradient(180deg, #698bf2 0%, #5057d5 100%);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  &:hover {
    opacity: 0.8;
    background: linear-gradient(180deg, #698bf2 0%, #5057d5 100%);
    color: white;
  }
}

.user_icon {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  overflow-x: auto !important;
  white-space: nowrap;
  gap: 10px;
  width: 100%;
  margin-bottom: 0.5rem;
  overflow-y: hidden;
  .certificate__icon {
    border: 1.5px solid #e3e3e3;
    border-radius: 8px;
    transition: 0.2s;
    margin-bottom: 2rem;
    width: 60px;
    height: 40px;
    padding: 0rem 2rem;
    position: relative;
    > img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.btn-upload {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn {
    background: #58bb68;
  }
  input[type='file'] {
    position: absolute;
    opacity: 0;
    z-index: 0;
    max-width: 100%;
    height: 100%;
    display: block;
  }
}

.signature-upload-wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
}
