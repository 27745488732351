// Variables with simulation-specific prefixes
$sim-aspect-ratio: 56.25%; // 16:9 aspect ratio (9/16 = 0.5625)
$sim-max-width: 1152px;
$sim-loading-bg: #f4f5fa;
$sim-unity-bg: #f4f5fa;
$sim-progress-width: 50%;
$sim-progress-margin: 1rem;
$sim-wrapper-bg: #000;

.simulation-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $sim-wrapper-bg;

  // Fullscreen styles
  &:fullscreen {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $sim-wrapper-bg;

    .simulation-unity-container {
      height: 100%;
      width: 100%;
      aspect-ratio: 16 / 9;
      margin: auto;

      .simulation-aspect-wrapper {
        padding-bottom: 0 !important; // Remove padding-based aspect ratio
        height: 100% !important;
      }
    }
  }

  // Fullscreen styles
  &:fullscreen,
  &:-webkit-full-screen,
  &:-moz-full-screen,
  &:-ms-fullscreen {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $sim-wrapper-bg;

    .simulation-unity-container {
      width: 100vh !important; // Use viewport height for width calculation
      height: 100vh !important;
      max-width: 177.78vh !important; // 16:9 ratio (16/9 = 1.7778)
      margin: auto;

      .simulation-aspect-wrapper {
        padding-bottom: 0 !important; // Remove padding-based aspect ratio
        height: 100% !important;
      }
    }
  }
}

.simulation-unity-container {
  position: relative;
  width: 100%;
  //   max-width: $sim-max-width;
  margin: 0 auto;

  .simulation-aspect-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: $sim-aspect-ratio;

    // Rest of the existing styles...
    .simulation-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .simulation-unloaded {
      @extend .simulation-content;
      background-color: $sim-loading-bg;

      span {
        position: relative;
        font-size: 1rem;
        color: #374151;
        z-index: 3;
      }
    }

    .simulation-loading {
      @extend .simulation-content;
      background-color: $sim-loading-bg;
      flex-direction: column;

      .simulation-progress {
        position: relative;
        margin-bottom: $sim-progress-margin;
        z-index: 3;
      }

      & > span {
        position: relative;
        font-size: 1rem;
        color: #374151;
        z-index: 3;
      }
    }

    .simulation-player {
      @extend .simulation-content;
      overflow: hidden;

      canvas {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 3;
      }
    }
  }
}
