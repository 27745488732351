.modal-spin-container {
  columns: 2 auto;
}

.modal-header-search {
  margin-left: 2rem;
}

.modal-spin-container-tag {
  margin-top: 0.8rem;
}

.modal-alert {
  margin-right: 4rem;
  margin-top: 1rem;
}

.wrap_btn_actions-modal {
  margin-top: 10px;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  .action_button {
    border-radius: 25px;
    width: 150px;
  }
}

.marketplace-schedule {
  &-header {
    margin-top: 1rem;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 1rem;
  }

  &-container {
    width: 100%;

    button {
      margin-top: 2rem;
    }
  }

  &-month {
    &-title {
      text-transform: uppercase;
      margin-bottom: 1rem;
      position: relative;
      margin-left: 1.5rem;
      color: black;

      &::before {
        content: '';
        height: 5px;
        width: 5px;
        background: black;
        border-radius: 50%;
        position: absolute;
        top: 40%;
        left: -1.5rem;
      }
    }
  }

  &-day {
    margin-bottom: 1rem;
    &-title {
      text-transform: uppercase;
      margin-bottom: 0.5rem;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 0.5px;
    }

    .ant-tag {
      padding: 0.75rem 1.5rem;
      font-weight: bold;
    }
  }

  &-events {
    background: #f1f1f1;
    padding: 2rem;
    margin-bottom: 1rem;
    width: 100%;

    &:first-of-type {
      margin-top: 2rem;
    }
  }
}

.attach_file {
  &_panel {
    background: rgb(242, 242, 242);
  }

  &_button {
    display: flex;
    gap: 1rem;
  }
}

.ant-popover-message > .anticon {
  display: none;
}
