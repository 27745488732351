.view-channel {
  &-container {
    padding: 3rem;
    display: grid;
    grid-template-columns: 1fr 400px;
    gap: 2rem;
  }

  &-video-info {
    height: 6rem;
    padding: 0 2rem;
    background: #f5f5f5;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    margin-top: 1rem;
  }

  &-right {
    position: relative;
  }
}

.ai-video-queue-modal-container {
  .ant-modal-content {
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }

  .ai-video-queue-modal-title {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-bottom: 16px;

    .warning-icon {
      margin-right: 8px;
      color: #095fcf;
    }
  }

  .ai-video-queue-modal-content {
    padding: 16px;
    background-color: #fff9ef;
    border-radius: 4px;

    span {
      width: 100%;
      text-align: center;
      color: black;
      font-weight: 500;
    }
  }

  .ai-video-queue-modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    button {
      margin-left: 8px;
    }
  }
  .ant-alert {
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 8px 16px;

    &-warning {
      background-color: #fff3e1;

      .ant-alert-message {
        margin-left: 20px;
      }
    }
  }
}
.video-head-back {
  .p-breadcrumbs {
    padding: 2rem 3.2rem 0;
  }
}
