.wrap_page {
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.1);
  padding: 30px;
  background-color: #fff;
  border-radius: 16px;
}

.page {
  position: relative;
  width: 100%;
  height: 102.4rem;
  background-color: #fff;
  border: 0.1rem dashed #d8d8d8;

  &_dark {
    background-color: #fff;
    padding: 3.2rem;
    text-align: center;
  }

  &_hidden {
    opacity: 0;
  }

  &_previous {
    border: 0.1rem dashed #d8d8d8;
    box-shadow: none;
    opacity: 0.6;
    transition: opacity 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &::after {
      content: 'Go to Previous Page';
      position: absolute;
      top: 3.2rem;
      right: 3.2rem;
    }
  }

  &_new {
    border: 0.1rem dashed #d8d8d8;
    box-shadow: none;
    opacity: 0.6;
    transition: opacity 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &::after {
      content: 'Add a new page';
      position: absolute;
      top: 3.2rem;
      left: 3.2rem;
    }
  }

  &_next {
    border: 0.1rem dashed #d8d8d8;
    box-shadow: none;
    opacity: 0.6;
    transition: opacity 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &::after {
      content: 'Go to next page';
      position: absolute;
      top: 3.2rem;
      left: 3.2rem;
    }
  }

  &__dropzone {
    $self: &;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: border-color 0.2s, background 0.2s;
    // cursor: pointer;

    // &:hover {
    //   border-color: #0068FF;
    //   background: rgba(#0068FF, .05);

    //   #{$self}__text {
    //     color: #0068FF;
    //   }
    // }

    // &__text {
    //   transition: color .2s;
    // }
  }

  &__elements {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  &__data {
    display: none;
  }
}

.fix-input-problem-safari {
  *:not(input):not(textarea),
  *:focus:not(input):not(textarea) {
    -webkit-user-select: text !important;
  }
}

.page_grid {
  position: absolute;
  height: 55.3rem;
  width: 100%;
  z-index: 2;
}

.active_grid {
  background: rgba(#fff, 0.4);
  border-color: #0068ff;
  border-radius: 100%;
}

.main-page-wrap {
  .page {
    height: auto;
  }
  .page_previous {
    height: 8rem;
    border-radius: 16px 16px 0 0;
  }
  .page-choose-preview {
    border-radius: 0 0 16px 16px;
  }
}
