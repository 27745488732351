@import "../../../styles/variables";

.header_with_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.head_nopadding {
  .p-breadcrumbs {
    background-color: transparent;
    padding: 0;
    font-size: 1.5rem;
  }
}

.assessments {
  &__list {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ececec;
  }

  &__item {
    width: 100%;
    border-bottom: 1px solid #ececec;
  }
}

.assessment-card {
  $self: &;
  transition: background .2s;

  &:hover {
    background: map-get($colors, light);

    #{$self}__thumb {
      &::after {
        background: rgba(map-get($colors, black), 0);
      }

      img {
        transform: scale(1.04);
      }
    }
  }

  &__inner {
    display: flex;
    padding: 2rem;
  }

  &__thumb {
    position: relative;
    width: 24rem;
    overflow: hidden;

    &::after {
      position: relative;
      z-index: 1;
      content: '';
      display: block;
      padding-bottom: 56.25%;
      background: rgba(map-get($colors, black), .1);
      transition: background .2s;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;

      object-fit: cover;
      transition: transform .2s;
    }
  }

  &__live-badge {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    background: map-get($colors, orange);
    box-shadow: 0 0 0 .4rem rgba(map-get($colors, black), .1);
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    z-index: 2;
  }

  &__content {
    flex: 1;

    display: flex;
  }

  &__col {
    width: 50%;
    flex-shrink: 0;
    padding: 0 2rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-right: 1px solid #ececec;
  }

  &__title {
    display: inline-block;
    font-weight: 700;
    color: map-get($colors, blue);
    transition: opacity .2s;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }

  &__controls {
    padding-left: 2rem;

    display: flex;
    flex-direction: column;
  }

  &__control {
    height: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .2s;

    &:hover {
      opacity: .6;
    }

    i {
      font-size: 1.8rem;
      color: map-get($colors, blue);
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__tag {
    padding: .4rem 1.2rem;
    font-size: 1.2rem;
    border-radius: 2rem;
    border: 1px solid map-get($colors, blue);
    color: map-get($colors, blue);

    margin: 0 1rem 1rem 0;
  }
}