.course-creation-ai-conversation {
  height: 40vh;
  overflow-y: auto;

  &__message {
    display: flex;
    margin-bottom: 1rem;

    &.msg--system {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 1em 0 0.5em;
        line-height: 1.3;
      }

      code {
        background: #f4f5fa;
        padding: 0.2em 0.4em;
        border-radius: 3px;
        font-family: monospace;
      }

      pre code {
        display: block;
        padding: 1em;
        overflow-x: auto;
      }

      ul,
      ol {
        padding-left: 2em;
        margin: 0.5em 0;
      }
    }

    &-from {
      background: #5c646f;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      display: grid;
      place-items: center;
      position: relative;
      aspect-ratio: 1;
      margin-right: 1rem;

      span {
        position: absolute;
        color: white;
        font-size: 10px;
        font-weight: bold;
      }
    }

    &-content {
      border-radius: 16px;
      padding: 1rem;
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      max-width: 88%;

      p {
        padding-bottom: 0;
        margin-bottom: 0;
      }

      button {
        max-width: fit-content;
        border-radius: 30px;
        height: 40px;
        padding: 0 3rem;
      }

      &__preview-button {
        padding: 1rem;
        width: fit-content;
        text-align: center;
        color: #5b6ee2;
        background: #fff;
        border-radius: 16px;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        border: 1.5px solid #5b6ee2;
        border-radius: 30px;

        i {
          font-size: 18px;
        }

        &:hover {
          box-shadow: 0 0 0 1px #5b6ee2;
        }

        &:active {
          background: #f4f7fc;
        }
      }
    }
  }

  .msg--system {
    .course-creation-ai-conversation__message-content {
      background: #edf2f5;
      color: #212121;
      width: 100%;

      ol {
        margin: 0;

        li {
          line-height: 1.8;
        }
      }
    }
  }

  .msg--user {
    flex-direction: row-reverse;

    .course-creation-ai-conversation__message-content {
      background: #d5d9e2;
      color: #212121;
      margin-right: 3rem;
    }
  }

  .dot-container {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 0 5px 5px 5px;
  }

  .dot {
    width: 10px;
    height: 10px;
    background-color: #808080;
    border-radius: 50%;
    margin: 0 5px;
    opacity: 0;
    animation: typing 1.5s infinite;
  }

  .dot:nth-child(1) {
    animation-delay: 0.5s;
  }

  .dot:nth-child(2) {
    animation-delay: 1s;
  }

  @keyframes typing {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}

.course-creation-ai-chat {
  ol {
    list-style: decimal;
    margin-left: 3rem;
    margin-top: 2rem;

    li li {
      list-style: inside;
    }
  }

  ul {
    margin-left: 2rem;
  }

  button {
    margin-bottom: 2rem;
  }
}

.question_item_user {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  h3 {
    color: #fff;
  }
}

.course-creation-ai-modal {
  // max-width: 600px;
  border-radius: 10px;

  .ant-card {
    flex-basis: 400px;
    flex-grow: 1;
    flex-shrink: 1;
  }

  &__body {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    .ant-card {
      border-radius: 20px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    padding: 2rem 3rem;
    margin: 1rem 0;

    h2 {
      font-size: 20px;
      font-weight: 500;
      color: #212121;
    }
  }

  &__input {
    resize: none;
    border: 1.5px solid #e3e3e3;
    border-radius: 8px;

    &-container {
      margin-top: 20px;
      position: relative;
      -ms-overflow-style: none;
      scrollbar-width: none;
      display: flex;
      align-items: center;
      gap: 10px;

      ::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
      }
    }
  }

  &__send {
    padding: 1.3rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #698bf2 0%, #5057d5 100%);

    i {
      color: white;
      font-size: 14px;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  /* Disabled state */
  &__send--disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
  }

  .course-ai-preview {
    border: 1.5px solid #e3e3e3;
    border-radius: 16px;

    display: flex;
    position: relative;
    flex-wrap: wrap;
    flex-direction: column;
    height: max-content;
    line-height: 2;
    width: 100%;

    .course-ai-titile {
      border-radius: 16px 16px 0 0;
      padding: 1rem 3rem;
      background: #e9eff64d;
    }

    .course-ai-sec {
      padding: 2rem 3rem;
    }

    .ant-divider-vertical {
      height: 100px;
      margin-right: 3rem;
    }

    .course-ai-btn {
      padding: 0 30px 20px 30px;
    }

    // &-section {
    //   flex: 1;
    //   display: flex;
    //   flex-direction: column;

    //   &:nth-of-type(2) {
    //     border-left: 1px solid #e8e8e8;
    //     padding-left: 3rem;
    //   }
    // }

    &-item {
      // display: inline-block;
      padding: 4px 0;

      p {
        all: unset;
        color: #000;
        font-weight: 600;
        margin-left: 1ch;
        word-break: keep-all;
      }
    }

    &-button {
      max-width: fit-content;
      margin-left: auto;
      border-radius: 30px;
    }
  }
}

.course-creation-ai-container {
  display: flex;
  gap: 20px;
}

.knowledge-center-course-ai-container {
  background: #fff;
  padding: 2rem;
  max-width: 100%;
}

.course-creation-ai-wrap {
  display: flex;
  gap: 30px;

  .course-ai-preview {
    flex: 0.4;
  }

  .course-creation-ai-box {
    flex: 0.6;
  }

  &.show-creation-content {
    flex-direction: column;

    .course-ai-sec {
      display: flex;
      gap: 6px;
    }
  }
}

.course-creation-ai-box {
  border: 1.5px solid #e3e3e3;
  border-radius: 16px;
  line-height: 2;

  &-body {
    padding: 2rem 3rem;
  }

  &-title {
    border-radius: 16px 16px 0 0;
    padding: 1rem 3rem;
    background: #e9eff64d;
  }
}

.ai-go-back-btn {
  position: absolute;
  bottom: 5%;
  right: 6%;

  .shl-primary-btn {
    width: max-content;
    border-radius: 30px;
    height: 40px;
    padding: 0px 16px;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
    background: linear-gradient(180deg, #698bf2 0%, #5057d5 100%);
  }
}
