.upload-modal-item__weight {
  min-height: 40px;

  span {
    position: relative;
    top: 8px;
  }
}

.upload-modal-item__weight__complete {
  text-transform: uppercase;
  position: relative;
  top: 8px;
}

.upload-modal__list-wrapper {
  border-top: none;
  padding: 0px;

  .upload-modal__error-item-wrapper {
    background-color: #fff;
    border-width: 2px;
    border-color: rgb(216, 216, 216);
    border-style: dashed;

    .upload-modal__cancel-error {
      right: 15px;
    }
  }
}

.upload-modal__inner {
  padding: 10px;
  background: #f9f9f9;
  margin: 0px -43px;
}

.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  height: 40px;
}

.upload-modal__list-container {
  padding-right: 0px;
}

.upload-modal__list {
  padding: 5px 25px 20px;
  background-color: #fff;
  border-width: 2px;
  border-color: rgb(216, 216, 216);
  border-style: dashed;
}

.upload-dropzone__text-wrapper {
  display: block;
  padding: 50px;

  img {
    margin-bottom: 40px;
    width: 40px;
  }
}

.upload-modal__dropzone {
  padding: 0px !important;
}

.upload-modal__dropzone > div {
  height: auto !important;
}

.upload-modal {
  .ant-row {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.img-fit-into-dropzone {
  width: 89%;
  height: 100%;
  object-fit: cover;
  margin-left: 18px;
  margin-bottom: 5px;
}

.thumbnail-fit-into-dropzone {
  width: 50%;
  height: 100%;
  object-fit: cover;
  margin-left: 118px;
  margin-bottom: 5px;
}
