.wrap-lesson-info {
  display: flex;
  align-items: center;
  align-self: flex-start;
  width: 100% !important;
}

.card__wrapper {
  position: absolute;
  text-align: 0;
  top: 25px;
  right: 0;
}

.rating__modal {
  border-radius: 5px;
  min-width: 70vw;
  .ant-form {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .ant-form-item {
    width: 100% !important;
  }
  .ant-btn {
    max-width: 250px !important;
  }
  .ant-form-item-children {
    display: flex;
    justify-content: center;
  }
}

// Rating Component Style - Suprim

.rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  .ant-rate {
    font-size: 3.75em !important;
  }
  .ant-rate-star:not(:last-child) {
    margin-right: 15px !important;
  }
  h2 {
    font-size: 2.5rem;
    color: #000;
    text-transform: capitalize;
  }
  p {
    margin-bottom: 24px;
  }
  textarea.ant-input {
    border-radius: 10px !important;
    padding: 15px 12px !important;
    max-width: 540px;
    margin: 0 auto;
  }
  &__btn {
    margin-top: 0.5rem;
  }
}

.btn-actions {
  margin-top: 1rem;
  button:not(:last-child) {
    margin-right: 1rem;
  }
}
.rate-btn {
  display: block;
  margin-left: auto;
  text-transform: capitalize;
  width: 15rem;
  height: 4rem;
  font-weight: 700;
  &.center {
    margin: 0 auto !important;
  }
}

.rating__lists {
  margin-top: 1.875rem;
  overflow-y: scroll;
  height: 300px;
  padding-right: 1rem;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 5px;
  }
  .rating__item {
    margin-bottom: 1rem;
  }
  .rating__item:last-child {
    margin-bottom: 0rem;
  }
}

.rate__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .rate-avg {
    display: flex;
    align-items: center;
    h2 {
      color: #000;
      display: flex;
      align-items: center;
      margin-right: 0.875rem;
      font-size: 1.4rem;
    }
    &__value {
      font-weight: 800;
      font-size: 1.875rem;
      margin-left: 1.2rem;
    }
  }
}

.rating__item {
  padding: 12px;
  border-radius: 5px;
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    &_left {
      display: flex;
      align-items: center;
      margin-right: 0.875rem;
      .rate {
        min-width: 150px;
      }
      .name {
        font-size: 1.5rem;
        span.bold {
          font-weight: 600;
          color: #000;
        }
        span {
          color: #a3a2a2;
        }
      }
    }
    &_right {
      .fav {
        margin-right: 0.875rem;
      }
      .ant-btn {
        background-color: transparent !important;
        border: none;
      }
    }
  }
}
.course-tab {
  .ant-tabs-extra-content {
    display: flex;
    align-items: center;
    line-height: 63px !important;
  }
}

.react-transform-component > div {
  width: 100%;
}
