// AiImages.scss

.ai-images-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  margin-top: 3rem;

  .image-generation-note {
    font-size: 12px;
    color: #0a4b8e;
    background: #f4f5fa;
    padding: 0.8rem;
    border-radius: 5px;
    margin-bottom: 0 !important;
  }

  .ai-images-form {
    padding: 3rem 2rem 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    [name='prompt'] {
      width: 60%;

      textarea {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        resize: none;
      }
    }

    [name='title'] {
      width: 60%;

      input {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }
    }

    .include-text {
      span {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
      }
    }

    &-label {
      span {
        color: #000;
        text-transform: none;
        font-size: 1.6rem;
        padding-left: 0.6rem;
      }

      &-icon {
        color: #126dfb !important;
        font-size: 1.8rem !important;
        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }

      display: flex;
      align-items: center;
    }

    h1 {
      font-size: 24px;
      font-weight: 800;
      text-transform: uppercase;
      color: #136cfb;
    }

    .ant-btn {
      &.ant-btn-primary {
        background: linear-gradient(to right, #136cfb, #0a4b8e);
        border: none;

        &:hover {
          background: linear-gradient(to right, #0a4b8e, #136cfb);
        }
      }
    }
  }

  .ai-midjourney-chat {
    width: 76%;
    outline: 1px solid #e9e9e9;
    margin: 1rem auto;

    @media (max-width: 840px) {
      width: 100%;
    }

    &-start-new {
      width: 100%;
      text-align: center;
      color: #949494;
      font-size: 12px;
      cursor: pointer;
      margin-top: 2rem;
      margin-bottom: -1rem;
    }

    &-messages {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      height: 100%;
      max-height: 500px;
      overflow-y: auto;
      padding: 1rem;
      margin: 0 auto;
    }

    &-message {
      display: flex;
      gap: 1rem;
      width: 100%;

      &-avatar {
        height: 40px;
        width: 40px;
        aspect-ratio: 1;
        background: #136cfb;
        border-radius: 50%;
        display: grid;
        place-items: center;
        color: #fff;
      }

      p {
        background: #e4e6eb;
        padding: 10px;
        border-radius: 0 5px 5px 5px;
        text-align: left;
        margin-top: 10px;
      }

      &--revised {
        flex-direction: column;

        button {
          // width: 50%;
          width: fit-content;
          margin-left: calc(40px + 1rem);

          @media (max-width: 768px) {
            margin-left: 0;
            font-size: 12px;
          }
        }
      }

      .dot-container {
        display: flex;
        align-items: center;
        background: #e4e6eb;
        padding: 1rem;
        border-radius: 0 5px 5px 5px;
        margin-bottom: -10px;
        margin-top: 5px;
      }

      .dot {
        width: 10px;
        height: 10px;
        background-color: #808080;
        border-radius: 50%;
        margin: 0 5px;
        opacity: 0;
        animation: typing 1.5s infinite;
      }

      .dot:nth-child(1) {
        animation-delay: 0.5s;
      }

      .dot:nth-child(2) {
        animation-delay: 1s;
      }

      @keyframes typing {
        0% {
          opacity: 0;
        }

        50% {
          opacity: 1;
        }

        100% {
          opacity: 0;
        }
      }
    }

    &-message--user {
      margin-left: auto;
      flex-direction: row-reverse;

      .ai-midjourney-chat-message-avatar {
        text-align: right;
      }

      p {
        background: #136cfb;
        color: #fff;
        border-radius: 5px 0 5px 5px;
      }

      img {
        border-radius: 50%;
        height: 40px;
        width: 40px;
      }
    }

    &-container {
      border-top: 1px solid rgb(230, 229, 229);
      padding: 3rem 0;
      width: 100%;

      & > p {
        font-size: 16px;
        font-weight: 400;
      }
    }

    &-input {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 5px;

      &-send {
        height: 20px;
        object-fit: contain;
      }
    }
  }

  .ai-images-result {
    padding: 2rem 0;
    border-top: 1px solid #e9e9e9;

    .image-generation-status {
      p {
        font-weight: 500;
      }
    }
  }

  .ant-form-item-label {
    font-weight: bold;
  }

  .image-preview {
    text-align: center;
    padding-top: 2rem;

    img {
      height: 400px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      transition: transform 0.2s ease-in-out;
      margin: 2rem 0;
      background: #e9e9e9;
      object-fit: contain;

      &:hover {
        transform: scale(1.05);
      }
    }

    h2 {
      font-size: 18px;
      font-weight: bold;
    }

    a {
      display: block;
      color: #136cfb;
      text-decoration: none;
      font-weight: bold;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: #0a4b8e;
      }
    }

    &-actions {
      padding-top: 1rem;

      .ant-select {
        max-width: 300px;
      }

      &-buttons {
        display: flex;
        gap: 2rem;
        width: 100%;
        justify-content: center;
      }
    }
  }

  .ant-progress {
    width: 80%;
    border-radius: 10px;
    background-color: rgba(19, 108, 251, 0.1);

    .ant-progress-inner {
      border-radius: 10px;
      background: linear-gradient(to right, #136cfb, #0a4b8e);
    }
  }

  .ant-select {
    width: 100%;
  }

  .ant-select-selection {
    // border-color: #136cfb;
    background-color: #fff;
    border-radius: 5px;
  }

  .ant-btn {
    font-weight: bold;
    border-radius: 5px;
  }
}

.carousel-container {
  img {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}
