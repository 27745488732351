.mp-top_section_row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.button-market_place {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-blue_color {
  color: #fff;
  border-color: transparent;
  background: linear-gradient(180deg, #698bf2 0%, #5057d5 100%);
  border-radius: 30px;
}

.quill-div_row {
  flex-grow: 1;
  display: flex;
  margin-top: 4rem;
}

.quill_editor {
  flex-grow: 1;
  margin-bottom: 4rem;
  border-radius: 10px;
}

.no_bottom_margin {
  margin-bottom: 0px;
}

.margin_top {
  margin-top: 2rem;
}

.div_row {
  display: flex;
  flex-direction: row;
}

.price_style {
  flex-grow: 1;
}

.switch-bg_inactive {
  width: 75px;
  height: 38px;
  padding: 6px;
  display: flex;
  border-radius: 30px;
  align-items: center;
  justify-content: flex-start;
  background-color: darkgray;
}

.switch-bg_active {
  width: 75px;
  height: 38px;
  padding: 6px;
  display: flex;
  border-radius: 30px;
  align-items: center;
  justify-content: flex-end;
  background-color: #136dfb;
}

.switch-knob {
  width: 30px;
  height: 30px;
  border-width: 1;
  border-radius: 30px;
  border-color: gainsboro;
  background-color: white;
}

.switch-label {
  color: #777777;
  font-size: 1.2rem;
  line-height: initial !important;
}

.margin-bottom {
  margin-bottom: 0.5rem;
}

.line-height {
  line-height: 1.3;
}
