.ant-popover-message-title {
  padding: 0 !important;
}

.draft-tag {
  position: absolute;

  span {
    border-radius: 50px;
    padding: 5px 25px;
    background: #d47f00;
    opacity: 0.8;
    color: white;
    font-size: 10px;
  }

  transform: translateY(25px);

  @media (max-width: 840px) {
    transform: translateY(-38px);
    margin-left: 12rem;
  }
}

.ai-generated-tag {
  background: #00548b;
  opacity: 0.8;
  color: white;
  font-size: 12px;
  border-radius: 50px;
  width: 55px;
  margin-top: -60px;
  text-align: center;
  padding: 0.25rem 1rem;
}

.workshop-course {
  position: relative;
  display: flex !important;
  width: 100%;
  padding: 1.6rem 2.4rem;
  gap: 1.5rem;
  border-radius: 1.6rem;
  border: 1px solid #edf2f5;
  box-shadow: 0px 0px 8px 0px #f1f1ee;

  &-cover {
    width: 16rem;
    height: 16rem;
    min-width: 16rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 1.6rem;
    box-shadow: 0 0 0.8rem 0 #f1f1ee;
  }

  &-body {
    width: calc(100% - 12.8rem);
    position: relative;

    &-header {
      display: flex;
      align-items: center;
      justify-content: left;
      width: 100%;
      position: relative;

      &-title {
        position: relative;
        width: 100%;

        &-h1 {
          width: 100%;
          white-space: nowrap;
          font-family: Mulish;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 700;
          line-height: 1.2;
          letter-spacing: 0.01em;
          text-align: left;
        }

        &-h2 {
          width: 100%;
          display: flex;
          align-items: center;
          color: #698bf2 !important;
          font-family: Mulish;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.2;
          letter-spacing: 0.01em;
          text-align: left;

          &-titletext {
            color: #698bf2 !important;
            max-width: 55%;
            margin: 0.5rem 0;
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
          }

          &-lessons {
            color: #676973;
            pointer-events: none;
          }
        }
      }

      &-type {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;

        &-tags {
          span {
            border-radius: 50px;
            padding: 8px 12px;
            background: #edf2f5;
            opacity: 0.8;
            color: #212121;
            font-size: 12px;
          }
        }

        &-ai {
          span {
            border-radius: 15px;
            padding: 8px 12px;
            background: #288fd1;
            opacity: 0.8;
            color: #fff;
            font-size: 12px;
          }
        }

        &-tr {
          span {
            border-radius: 15px;
            padding: 8px 12px;
            background: #d47f00;
            opacity: 0.8;
            color: #fff;
            font-size: 12px;
          }
        }
      }

      &-action {
        position: absolute;
        right: 0;
        top: 0;
        width: 10px;
        cursor: pointer;
      }
    }

    &-rate {
      padding-right: 1.2rem;
      margin: 0.5rem 0;
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      color: #212121;
    }

    &-tags {
      display: flex;
      gap: 8px;
      margin: 0.5rem 0;

      &-type {
        span {
          border-radius: 50px;
          padding: 5px 10px;
          background: #edf2f5;
          opacity: 0.8;
          color: #212121;
          font-size: 12px;
        }
      }

      &-translation {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          border-radius: 50px;
          padding: 5px 10px;
          background: #d47f00;
          opacity: 0.8;
          color: #fff;
          font-size: 12px;
        }
      }

      &-draft {
        span {
          border-radius: 50px;
          padding: 5px 10px;
          background: #d47f00;
          opacity: 0.8;
          color: #fff;
          font-size: 12px;
        }
      }
    }

    &-courseswitch {
      display: flex;
      align-items: center;
      gap: 5px;
      font-family: Mulish;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      letter-spacing: 0.01em;
      text-align: left;
      color: #212121;

      .ant-switch-checked {
        background: linear-gradient(180deg,
            #698bf2 0%,
            #5057d5 100%) !important;
      }
    }

    &-date {
      position: absolute;
      bottom: 0;
      left: 0;
      font-family: Mulish;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.4px;
      color: #676973;
    }
  }
}

.workshop-edit-menu {
  display: flex;
  align-items: center;
  gap: 5px;
}