.story-preferences {
  width: 800px !important;
  .ant-modal-header {
    padding-top: 40px;
    .ant-modal-title {
      color: #212121;
      font-family: 'Mulish';
      font-size: 20px;
      font-weight: 500;
    }
  }

  .story-course-form {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-button-center {
      max-width: 100%;
      flex: 0 0 100%;
      justify-content: end;
    }
    > div {
      max-width: 48%;
      flex: 0 0 48%;
      margin-bottom: 0;
      .ant-form-item-children {
        > div {
          > div {
            border-radius: 8px;
            border: 1.5px solid #e3e3e3;
            outline: none;
            box-shadow: none;
            &:hover {
              border: 1.5px solid #5b6ee2;
            }
            > div {
              &:nth-child(2) {
                > span {
                  display: none;
                }
                > div {
                  color: #212121;
                }
              }
            }
          }
        }
      }
    }
    .story-course-form-label {
      color: #212121;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}
