.podcast-section {
    &_title {
        color: #000000;
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
    }

    &_ai {
        display: flex;
        justify-items: center;
        align-items: center;
        height: 65px;

        .ant-checkbox-inner {
            border-radius: 50%;
        }
    }
}