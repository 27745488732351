.shl-create-channel-form-container {
  display: grid;
  grid-template-columns: 1fr 50rem;
  gap: 2rem;
  margin-bottom: 5rem;

  > div {
    min-height: 96.75%;
  }

  > section {
    grid-column: span 2;
  }

  @media screen and (max-width: 850px) {
    grid-template-columns: 1fr;
  }
}
