.modal-stream {
  .ant-modal-body {
    padding: 0;
    border-radius: 0;
  }

  .ant-modal-content {
    border-radius: 0;
  }

  .stream-modal-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: whitesmoke;
    font-size: 2rem;
    padding: 1rem;
  }

  .stream-modal-detail {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  }

  .add-space-bottom {
    padding-bottom: 2rem;
  }

  .add-space-top {
    padding-top: 2rem;
  }

  .stream-modal-button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 2rem;
  }

  .button-stream {
    border-color: #0068ff;
    color: #0068ff;
  }
}

.color-list {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;

  .color {
    height: 23px;
    width: 23px;
    cursor: pointer;

    &.active {
      border: 1px solid #ffffff;
      outline: 2px solid #000000;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

@media (max-width: 840px) {
  .sharelook-report-management {
    padding: 4rem 1.2rem;
    > div:nth-child(1) {
      white-space: nowrap;
      overflow-y: hidden;
      overflow-x: auto;
      input {
        width: 300px;
        margin-left: 20px;
      }
    }
    > div:nth-child(2) {
      > div {
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: auto;
      }
      > div:nth-child(1) {
        display: flex;
        gap: 40px;
      }
    }
  }
  .sharelook-user-management {
    padding: 2rem;
    input {
      width: 100%;
    }
    .sharelook-user-management-select {
      height: 60px;
      > div {
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: auto;
        flex-wrap: wrap;
        gap: 20px;
      }
    }
  }
}
