.shl-animate-logo {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  // visibility: hidden;
  margin: 7em auto 0 auto;
  width: 9em;
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.ideal_path_link {
  stroke: green !important;
  stroke-width: 8px;
}

.wrong_path_link {
  stroke: red !important;
  stroke-width: 8px;
}

.user_right_path {
  stroke: teal !important;
  stroke-width: 10px;
}

.event-stream__cover::after {
  content: '';
  width: 100%;
  display: 'none';
  padding-bottom: 0%;
}

.legend-item {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  .legend-item__shape {
    height: 5px;
    width: 20px;
    margin-right: 1rem;
  }

  .thick__line {
    height: 8px;
  }
}

#mazeReportTooltip {
  display: none;
  width: auto;
  min-width: 300px;
  padding: 5px;
  font-family: 'Mulish', sans-serif;
  font-size: 10pt;
  background-color: #eee;
  border-radius: 6px;
  display: none;
  transform: translateZ(0);
  animation: move 0.4s ease 3;
  position: absolute;
  top: -50%;
  left: 50%;
  z-index: 55;

  & > div {
    background: white;
    padding: 20px;
  }

  p {
    margin-top: 10px;
  }
}
#mazeReportTooltip:before {
  content: '';
  position: absolute;
  top: 50%;
  left: -30px;
  margin-top: -15px;
  border: solid 15px transparent;
  border-right-color: #eee;
  z-index: 1;
}

.black_outline {
  box-shadow: 0 0 1px 1px rgb(219, 219, 219);
  // border-bottom: 2px solid rgb(219, 219, 219);
  margin-left: 2.5rem;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// #unityView {
//   overflow: hidden;
// }

.back_to_dashboard {
  padding: 1rem 3rem;
  border-radius: 50px;
  background: #2d6ff2;
  color: white;
  border-color: transparent;
  cursor: pointer;

  &:hover {
    background: #1e59ce;
  }

  &:active {
    background: #1655d4;
  }
}

.maze-view {
  display: flex;
  justify-content: space-between;
  height: max-content;
  min-height: 80vh;
  gap: 2rem;
  margin-bottom: 1.5rem;

  .ant-alert {
    width: 100%;
  }

  &__cover {
    flex: 0.9;
    border: 1px solid #d7d4d4;
    border-radius: 15px;
    padding-top: 2rem;
    background: linear-gradient(90deg, #f3f3f32c 44.36%, #fff);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .maze-cover {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      width: 100%;
      height: 100%;
    }
  }

  &__sidebar {
    flex: 0.1;
    border-radius: 15px;
    padding: 3rem;
    border: 1px solid #d7d4d4;
  }
  #unityView {
    width: 100%;
    height: 100%;
    aspect-ratio: 1200/720;
  }

  &__heading {
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 1em;
    text-transform: capitalize;
    color: #000000;
    margin-left: 4.5rem;
    text-align: left;
    width: 100%;
  }

  &__result {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding-right: 2rem;

    .black_outline {
      flex: 1;
      height: 85%;
    }
  }

  &__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-bottom: 20rem;
  }
}

.maze-wrap {
  border-top: 1px solid #f8f8f8;
}
