.channel_header {
  .review {
    &__container {
      .ant-spin-nested-loading{
        position: relative;
      }
      flex: 1;
      padding: 0 2rem;
      span {
        margin-right: 1rem;
        font-weight: bold;
      }
    }

    &__total {
      cursor: pointer;
      color: rgba(46, 112, 242, 1);
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
