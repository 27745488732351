.maze-options {
  // width: 100%;
  height: 6rem;
  display: flex;
  font-weight: bold;
  margin-bottom: 2rem;
  &__title {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;

    span {
      font-weight: 100;
    }
  }

  &__values {
    display: flex;
    align-items: center;
    background: #eeeeee;
    padding: 10px;
    margin-left: 1rem;
    &-item {
      display: flex;
      align-items: center;
      span {
        margin-right: 1rem;
      }

      .ant-form-item {
        margin-top: 2.5rem;
      }
    }

    &-item:first-child::after {
      content: '';
      height: 3.5rem;
      width: 1px;
      background: #b4b2b2;
      margin: 0 2rem;
    }
  }
}

.done-button {
  margin: 20px auto 0 auto;
  display: block;
}
