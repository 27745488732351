.agora_main_view {
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
}

.agora_side_view {
  // position: absolute !important;
  width: 100%;
  height: 100%;
  right: 44px;
}

.remotePlayerClass:hover .agora-volume-controls {
  display: block;
}

.agora-volume-controls {
  position: relative;
  bottom: 21px;
  width: 94px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: none;
}

video {
  width: 100%;
  height: 100%;
  background: black;
}
.default_element {
  width: 100%;
  position: absolute;
  height: 100%;
  right: 0;
  bottom: 0px;
  background: black;
}

.blink {
  animation: 1s blink linear infinite;
  font-size: 14px;
  background-color: #2b6ee3;
  color: #fff;
  padding: 5px 7px;
  margin: 10px;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.controller {
  height: 3.25em;
  background: rgba(0, 0, 0, 0.06);
  width: 830px;
}
.controller-items {
  margin-top: 0.3em;
  display: -webkit-flex;
  //padding: 0.2em;
  justify-content: center;
}
.controller-item {
  width: 4.5%;
  margin-left: 1em;
  cursor: pointer;
}
.controller-item-join {
  width: 20%;
  cursor: pointer;
  margin-left: 25em;
  margin-top: 0.3em;
}
.disable-item {
  pointer-events: none;
  opacity: 0.4;
}

.ant-tooltip-inner {
  font-size: 15px !important;
}

.conference_agora {
  position: relative;
  width: 100%;
  height: calc(100vh - 27.5rem);
  border: 2px solid white;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  .streams {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
  }
}

// .chime_main__view {
//   position: absolute !important;
//   left: 0;
//   top: 0;
//   width: 80% !important;
//   z-index: -10;
// }

// .chime_side__view {
//   position: absolute !important;
//   // max-width: 200px;
//   height: 100px !important;
// }

.localvideo_side {
  width: 128px;
  height: 73px;
  position: relative;
}

.disable_mute {
  pointer-events: none;
}

.hidden {
  display: none;
}

// .disabled__video {
//   width: 100%;
//   height: 92px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   background: linear-gradient(0deg, #000000, #000000);
//   border: 1px solid #3e3e3e;
//   position: relative;

//   &_info {
//     position: absolute;
//     bottom: 0;
//     width: 100%;
//     display: flex;
//     padding: 0 0.5rem;
//     justify-content: space-between;
//     .img-wrap-eve {
//       background-color: #a0a0a5;
//       border-radius: 50%;
//       padding: 0.1rem;
//       display: grid;
//       place-items: center;
//       width: 2rem;
//       height: 2rem;
//     }
//     img {
//       height: 1rem;
//       object-fit: contain;
//     }
//   }
// }

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
// .scrollbar_chime::-webkit-scrollbar {
//   display: none;
// }

/* Hide scrollbar for IE, Edge and Firefox */
// .scrollbar {
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */
// }

/* background of the scrollbar except button or resizer */
.scrollbar_chime::-webkit-scrollbar-track {
  background-color: #fff;
}
.scrollbar_chime::-webkit-scrollbar-track:hover {
  background-color: #a0a0a5;
}

/* scrollbar itself */
.scrollbar_chime::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}
.scrollbar_chime::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
.scrollbar_chime::-webkit-scrollbar-button {
  display: none;
}

/* div box */

.scrollbar_chime {
  // height: 68vh;
  margin-left: auto;
  padding-left: 1rem;
  width: 20%;
  min-height: 0;
  overflow-y: auto;
  flex-grow: 1;
}

.remotePlayerClass {
  height: 92px;
  margin-bottom: 1.5rem;
}
.myClass {
  position: relative;
}

.remotePlayerClass:hover .volume-controls {
  display: block;
}

.volume-controls {
  position: absolute;
  bottom: -9rem;
  left: 0.5rem;
  width: 94px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: none;
}

.agora_disabled {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .disabled__name {
    border-radius: 50%;
    background-color: white;
    display: grid;
    width: 15%;
    height: 25%;
    place-items: center;
    font-weight: bold;
    color: #2b6ee3;
  }
}

.chime_main__view .agora_disabled .disabled__name {
  width: 25% !important;
}
