.course-header {
  display: flex;
  align-items: center;
  padding: 2rem;
  background: #fff;
  &.add-new {
    background: linear-gradient(106.91deg, #f8f4e8 1.04%, #e9eef5 44.36%);
    .toolbar {
      background: linear-gradient(106.91deg, #f8f4e8 1.04%, #e9eef5 44.36%);
    }
  }
  .ant-tabs-ink-bar {
    display: none !important;
  }
  .ant-tabs-nav {
    width: 100%;
    .ant-tabs-tab {
      margin: 0;
    }
    > div {
      display: flex;
      justify-content: center;
    }
  }
  .ant-tabs-bar {
    margin-bottom: 0;
  }
  .ant-steps {
    max-width: 50%;
    margin: auto;
  }
  .ant-steps-item-process {
    .ant-steps-item-icon {
      background: transparent;
      .ant-steps-icon {
        color: #5057d5;
      }
    }
    .ant-steps-item-content {
      .ant-steps-item-title {
        color: #5057d5;
      }
    }
  }
  .ant-steps-item-finish {
    .ant-steps-item-icon {
      background: linear-gradient(180deg, #698bf2 0%, #5057d5 100%);
      i {
        color: #fff;
      }
    }
  }
}
