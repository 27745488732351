.info-wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 32px;
    color: #4f4f4f;
    font-weight: 700;
    line-height: 40.16px;
    margin: 40px 0;
  }

  .items {
    display: grid;
    grid-template-columns: repeat(3, 250px);
    gap: 80px;
    margin-top: 40px;
    @media (max-width: 1024px) {
      gap: 40px;
    }
    @media (max-width: 840px) {
      grid-template-columns: repeat(1, 250px);
    }
    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .info-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
          color: #136cfb;
        }

        .img-container {
          height: 90px;
        }

        h3 {
          font-size: 18px;
          color: #000000;
          font-weight: 700;
          text-align: center;
          line-height: 22.59px;
        }
      }

      .info-button-div {
        display: flex;
        align-items: center;
        .info-button {
          border: none;
          font-family: Mulish;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 15px;
          text-transform: uppercase;
          color: #3dbd00;
        }

        img {
          height: 14px;
          width: 14px;
        }
      }
    }
  }
}

.menu_link {
  margin: 40px;
  font-size: 20px;
  font-weight: 800;
  color: #136cfb;
  display: flex;
  justify-content: flex-end;

  &:hover {
    cursor: pointer;
  }
}
