.el-placeholder {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    font-size: 2rem;
    margin-right: 1rem;
    color: #212121;
    background: #edf2f5;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    font-size: 1.6rem;
    text-transform: capitalize;
  }
}

.preview {
  width: 100%;
  height: 100%;

  &_image {
    //border-radius: 25px;  //MAHESH_ADDED
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &_video {
    border-radius: 25px;
    position: relative;
  }

  &_annotation {
    background: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.bg-img {
    opacity: 0.5;
  }

  &_chat {
    padding: 2rem;
    overflow: scroll;
  }

  .ql-editor {
    padding: 0;
    width: 100%;
    height: 100%;
    p {
      font-size: inherit !important;
      span {
        font-size: inherit !important;
      }
    }
  }
}

.embed-container {
  width: 100%;
  height: 100%;
  background: #000;

  iframe,
  object,
  embed {
    width: 100%;
    height: 100%;
  }
}
