.ideal_path_link {
  stroke: green !important;
  stroke-width: 5px;
}

.wrong_path_link {
  stroke: red !important;
  stroke-width: 5px;
}

// #mazeReportTooltip {
//   width: 400px;
//   padding: 20px;
//   font-family: Arial, sans-serif;
//   font-size: 10pt;
//   background-color: white;
//   border-radius: 6px;
//   position: absolute;
//   display: none;
// }

.maze-report-table {
  table tbody tr td:nth-child(2) {
    text-align: center !important;
  }
}

// #mazeReportTooltip::before {
//   content: '';
//   width: 12px;
//   height: 12px;
//   transform: rotate(45deg);
//   background-color: white;
//   box-shadow: 0px 15px 10px -15px #111;
//   position: absolute;
//   left: -6px;
//   top: 68px;
// }

.legend-padding {
  padding-top: 1rem;
  padding-left: 3rem;
}

.table-row {
  width: 92%;
  margin-top: 30px;
  margin-left: 35px;
  table tbody tr {
    background: whitesmoke;
  }
  td:first-child {
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
  }
  td:last-child {
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
  }
}

//maze table listing

.ant-list-items {
  margin-left: 36px;
  margin-right: 36px;
}

//popover changes

.popover-icon {
  font-size: 25px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  margin-right: 20px;
  margin-bottom: 18px;
  cursor: pointer;
  color: #136dfb;
}

// .ant-popover-inner {
//   border-radius: 5px;
//   overflow: hidden;
// }

// .ant-popover-inner-content {
//   padding: 0px;
// }
