.error_message {
  color: #ff4b55;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.ant-modal-content {
  border-radius: 1.2rem !important;
}

.ant-modal-header {
  border-radius: 1rem;
}

.ant-modal-footer {
  border-radius: 1rem;
}

.ant-modal-title {
  color: #212121;
  font-weight: bold;
}
