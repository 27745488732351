.switch-bg_inactive {
  width: 75px;
  height: 38px;
  padding: 6px;
  display: flex;
  border-radius: 30px;
  align-items: center;
  justify-content: flex-start;
  background-color: darkgray;
}

.switch-bg_active {
  width: 75px;
  height: 38px;
  padding: 6px;
  display: flex;
  border-radius: 30px;
  align-items: center;
  justify-content: flex-end;
  background-color: #136dfb;
}

.switch-knob {
  width: 30px;
  height: 30px;
  border-width: 1;
  border-radius: 30px;
  border-color: gainsboro;
  background-color: white;
}

.switch-label {
  color: #777777;
  font-size: 1.2rem;
  line-height: initial !important;
}

.margin-bottom {
  margin-bottom: 0.5rem;
}

.line-height {
  line-height: 1.3;
  text-align: center;
}
