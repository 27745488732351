.event__participants {
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;

  &_count {
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #a4a2a2;
    position: relative;
    margin: 2rem 0 1rem 0;
    &::after {
      content: '';
      height: 1px;
      width: 100%;
      background: #f5f5f5;
      position: absolute;
    }
  }

  &_invite {
    width: 40%;
    background: #2e70f2;
    color: white;
    border-radius: 100px;
    padding: 1rem 4rem;
    margin: 1rem auto 0 auto;
    text-align: center;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: #6e9cf8;
      transform: translateY(-3px);
    }
  }

  &_items {
    flex: 1;
    height: 100%;
    padding-right: 1rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &_users {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;

    &_item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      &_about {
        display: flex;
        align-items: center;
        font-size: 15px;
        line-height: 19px;
        text-transform: capitalize;
        color: #000000;
        width: 100%;
        span {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        img {
          height: 4rem;
          width: 4rem;
          border-radius: 50%;
          border: 3px solid #fff;
          margin-right: 1rem;
        }

        &-imgContainer {
          position: relative;
        }

        .allow__join {
          position: absolute;
          right: 0;
          bottom: -5px;
          height: 25px !important;
          width: 25px !important;
        }

        .passive__joined {
          border: 3px solid #3bb900 !important;
        }
      }

      &_status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: capitalize;
        img {
          height: 2rem;
          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
        i {
          font-size: 2rem;
          margin-right: -1rem;
          margin-left: 1rem;
          color: #3700ff;
          font-weight: bold;
        }
      }
    }
  }

  .audio_container {
    flex: 1;
    display: flex;

    &__item {
      width: 4.5rem;
      display: grid;
      place-items: center;
    }
  }
}
