@import '../../../styles/variables.scss';

.el {
  $self: &;

  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s, opacity 0.2s;
  user-select: none;

  &:hover {
    #{$self}__controls {
      opacity: 1;
      visibility: visible;
    }

    .el__triangle {
      background: #ffffff83;
    }
  }

  &.__transform {
    box-shadow: 0 0 0 1px blue;
  }

  &__controls {
    position: absolute;
    z-index: 3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    right: 0;

    background-color: map-get($colors, pageBuilderTriangle);

    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
  }

  &__control {
    &:not(:last-child) {
      margin-right: 1rem;
    }
    &__info {
      font-size: 20px;
      color: #fff;
      background: #136cfb;
      border-radius: 50%;
      margin-right: 0.5rem;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
      padding: 10px;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
  }

  &__preview {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.6);
    color: black;
    font-family: 'Mulish', sans-serif !important;
  }

  &__triangle {
    resize: both;
    background: rgba(255, 255, 255, 0);
  }

  &__editor {
    width: 100%;
    height: 100%;
  }

  &-editor {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    margin-bottom: 7rem;
    gap: 1.2rem;

    .ql-editor {
      height: auto;
      overflow: hidden;
    }

    &__component {
      flex: 1;
    }

    &__controls {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      height: 4rem;
      background: map-get($colors, darkGreyThree);
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);

      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__control {
      margin-right: 1rem;
    }
  }
}

.default-zIndex {
  z-index: 2;
}

.react-draggable {
  border: 1px solid map-get($colors, pageBuilderBorder);
}

/*MAHESH_ADDED*/

.el__controls {
  background: transparent;

  button {
    background: #136cfb;
    color: #fff;
    border: none;
    width: 36px;
    height: 36px;
    border-radius: 36px;
    padding: 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      background: #136cfb;
    }

    img {
      height: 16px;
      margin: auto;
    }
  }
}

.audio-widget-title {
  position: absolute;
  left: 25px;
  color: #000;
}

#quiz-container {
  font-family: 'Mulish', sans-serif !important;
}
