.lession-choose-wrap {
  > section {
    gap: 3rem;
  }
  .lession-choose-icon {
    > div {
      border: 1.5px solid #e3e3e3;
      width: 60px;
      height: 60px;
      border-radius: 100%;

      svg {
        padding: 12px;
        width: 50px;
        height: 50px;
      }
      //   &:hover {
      //     border: 1.5px solid #698bf2;
      //   }
    }
  }
}
