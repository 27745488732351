.create-course {
  display: flex;
  justify-content: space-between;

  &__content {
    flex: 1;
    padding-right: 3.2rem;
    // max-width: 100rem;
  }

  &__sidebar {
    padding-top: 2.2rem;
    width: 30rem;
    flex-shrink: 0;

    @media screen and (max-width: '714px') {
      flex-wrap: wrap;
      width: 65vw;
    }

    .shl-secondary-btn {
      background: #fff !important;
      border: 1px solid #e3e3e3 !important;
      border-radius: 8px;
      color: #5057d5 !important;
      height: 40px;
    }
  }

  @media screen and (max-width: '714px') {
    flex-wrap: wrap;
  }

  .ant-form-item-label {
    label {
      color: #212121 !important;
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  input {
    border-radius: 8px;
    border: 1px solid #e3e3e3;
  }

  .ant-input-number {
    border: 1px solid #e3e3e3;
    border-radius: 8px;

    input {
      border-radius: 8px;
      border: 1px solid transparent;
    }
  }
}

.b-theme {
  padding-top: 2rem;
  text-align: center;

  &__button {
    margin-bottom: 2rem;
  }

  &__text {
    font-weight: 700;
    font-size: 1.1rem;
    text-transform: uppercase;
  }
}

.lesson_title {
  max-width: 100px;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.error_message {
  color: #ff4b55;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.button-marker_place-div {
  display: flex;
  padding-top: 0px;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  button {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  @media screen and (max-width: '420px') {
    margin-bottom: 20px;
  }
}

.no_bottom_margin {
  margin-bottom: 0px;
}

.canva-editor-items {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: 88.2px;

  .item {
    @media screen and (max-width: '470px') {
      margin-bottom: 10px;
    }
  }

  .text {
    padding-top: 13px;
    font-size: 13px;
  }

  @media screen and (max-width: '470px') {
    flex-direction: column;
    height: 150px;
    align-items: flex-start;
  }
}

@media screen and (max-width: '1440px') {
  .is-mobile-edit-item {
    >div {
      max-width: 50%;
      width: 50%;
    }
  }
}

@media screen and (max-width: '768px') {
  .is-mobile-edit-item {
    >div {
      max-width: 100%;
      width: 100%;
    }
  }
}

.add-lession-btn {
  text-align: center;
  margin-top: 3rem;

  i {
    padding: 4px;
    border-radius: 100%;
    color: #fff;
    background: linear-gradient(180deg, #698bf2 0%, #5057d5 100%);
  }

  .ant-btn[disabled] {
    i {
      background: #d8d8d8 !important;
    }
  }
}

.icon-check .anticon-check {
  background: linear-gradient(180deg, #698bf2 0%, #5057d5 100%);
  color: #fff;
  border-radius: 100%;
  padding: 6px;
}

.ai-warning {
  p {
    padding: 1rem;
    border: 1px solid #ff9900;
    border-radius: 8px;
    margin-bottom: 1.2rem;
  }
}