.filter {
  background: #fff;
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  margin: 0px -44px 20px -44px;

  input {
    border-radius: 10px;
  }

  .list,
  .sort {
    width: auto;
    display: inline-block;
    float: right;
    margin: 5px 10px 0px;

    .WqJSi {
      margin: 0px;
    }

    .ant-dropdown-trigger {
      padding-right: 1rem;
    }

    a {
      margin: 0px 10px;
    }
  }
}

.filter-spacing {
  padding: 0px 44px;
  margin-bottom: 0px;
  .ant-row {
    display: flex;
    align-items: center;
  }
}
