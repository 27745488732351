.audio-tab {
  .card-audio {
    padding: 5px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .card__content {
    padding-bottom: 1rem !important;
  }

  .card_on-select {
    .card__audio-thumb .dFuPFv {
      padding: 30px;
    }
  }

  .ant-tabs-bar {
    width: 224px;
    float: right;
    right: -34px;
    top: -30px;
    font-size: 12px;
    position: relative;
    margin: 0px;
    border: none;

    .ant-tabs-nav-scroll {
      text-align: right;
      margin-right: 5px;
    }
  }

  .ant-tabs-nav .ant-tabs-tab {
    font-size: 12px;
    padding-bottom: 0px;
  }

  .dFuPFv {
    padding: 30px;
    margin-left: -50px;

    img {
      height: 40px;
    }
  }
}

.ant-list-items {
  margin-left: 1em;
  margin-right: 5em;
  padding: 0px;
}

.textarea-warp {
  margin-top: 3em;
  border-width: 1px;
  border-color: rgb(216, 216, 216);
  border-style: dashed;

  textarea {
    min-height: 400px;
    position: relative;
    padding: 20px;

    &:hover {
      border-color: white;
    }
  }

  .audio-warp {
    position: absolute;
    bottom: 10px;
    left: 30px;
    background: #f2f3f4;
    width: calc(100% - 60px);
    border-radius: 10px;

    audio {
      border: none;
      width: calc(100% - 170px);
      margin-top: 5px;
    }

    button {
      margin: 15px;
    }
  }

  .audio-warp-safari {
    position: absolute;
    bottom: 10px;
    left: 30px;
    background: #f2f3f4;
    width: calc(100% - 60px);
    border-radius: 10px;

    audio {
      border: none;
      width: calc(100% - 170px);
      margin-top: 5px;
      margin-left: 3px;
    }

    button {
      margin-right: 2px;
      margin-top: 5px;
    }
  }
}

.ant-tabs-nav-wrap {
  background: #f9f9f9;
}
