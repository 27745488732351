.user-report {
  &-title {
    display: flex;
    font-size: 25px;
    margin-left: 3rem;
    text-transform: uppercase;
    color: black;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 2rem;
    .host {
    }

    .participant {
      margin-left: 3rem;
    }

    .host,
    .participant {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .activeTab {
      color: #246cf6;
    }
  }

  &-profile {
    display: flex;
    justify-content: space-around;
    padding: 0 1.5rem;
    .info {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 3px;
      .picture {
        margin: 0 auto;
        flex: 0.4;
        img {
          border-radius: 50%;
          height: 7rem;
          object-fit: cover;
        }
      }
    }

    .about {
      flex: 0.6;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        word-break: break-all;
      }
    }

    .about,
    .about h2 {
      text-align: left;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
    }

    .status {
      height: 150px;
      padding: 20px 0;
      display: grid;
      place-items: center;
      position: relative;
      .value-statistic {
        color: black !important;
      }

      &::before {
        content: '';
        width: 1px;
        height: 80%;
        position: absolute;
        top: 0;
        left: -1rem;
        background-color: rgba(0, 0, 0, 0.4);
        margin-top: 11%;
      }
      margin-left: 2rem;
    }
  }

  &-table {
    &-title {
      padding-left: 4rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      & > div {
        width: 30rem;
      }
    }

    &-pagination {
      display: flex;
      align-items: center;
      span {
        color: black;
        font-weight: bold;
      }
    }
    &-info {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      img {
        height: 50px;
        object-fit: contain;
        background-color: red;
        width: 20%;
      }

      .about {
        text-align: left;
      }
    }
  }
}

.report-table-cover-container {
  display: grid;
  place-items: center;
  height: 100%;

  img {
    width: 100%;
    min-height: 5rem;
    max-height: 6rem;
    object-fit: cover;
    margin-left: 3rem;
  }
}

.overview-container {
  display: flex;

  .left-chart {
    width: 40rem;
    margin-right: 2rem;
  }
}

.lesson-table .ant-table {
  border-radius: 0;
  box-shadow: none;
  width: auto;
}

.lesson-table .ant-table-wrapper .ant-spin-nested-loading {
  min-height: auto;
}

.lesson-table tbody tr td:nth-child(2) {
  text-align: center;
}

.bluish-background {
  background: linear-gradient(#ffffff, #ffffff) padding-box,
    linear-gradient(110.38deg, #a4efa7 18.22%, #8dc3f8 82.9%) border-box;
  border: 3px solid transparent;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.report-view-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 3rem 0 2rem;
  @media (max-width: 840px) {
    display: block;
    padding: 1rem 0 0;
    margin-bottom: 0 !important;
  }
  > div {
    @media (max-width: 840px) {
      padding: 0.8rem 0;
    }
  }
}

.report-view-item {
  font-size: 18px;
  text-transform: uppercase;
  color: black;
  font-weight: bold;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 840px) {
    margin: 1.2rem 10px 1.2rem;
  }
  img {
    margin-left: 1rem;
    object-fit: contain;
  }
}

.play-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;

  &:last-child::before {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    top: -40%;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.view-graph {
  cursor: pointer;
  color: rgba(36, 108, 246, 1);

  &:hover {
    text-decoration: underline;
  }
}

.quiz-user-table tbody tr td:nth-child(2) {
  text-align: center;
}

.card-container > .ant-tabs-card > .ant-tabs-content {
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 10px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: rgb(255, 255, 255);
  background: #fff;
}

.user-comment {
  display: flex;
  padding-left: 3rem;

  &-info {
    h5 {
      display: flex;
    }
  }
}

.poll_data {
  margin-left: 3rem;
  text-align: left;

  &:after {
    content: '';
    width: '100%';
    background-color: 'black';
  }
  h3 {
    // question
  }

  p {
    // answer
  }
}

.cert-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;

    text-transform: uppercase;

    color: #222222;
  }

  .items {
    width: 389px;
    margin-top: 3rem;
  }

  .history_item {
    height: 84px;

    background: #ffffff;
    border: 0.3px solid #aaaaaa;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 2rem;
    padding: 1rem;

    display: flex;
    align-items: center;
    img {
      margin-right: 2rem;
      height: 5rem;
    }

    .view-icon {
      margin-left: auto;
      color: blue;
    }
  }
}

.user-certificate-container {
  width: 100%;
  height: 30vh;
  background: #ffffff;
  display: grid;
  place-items: center;
  border: 2px dotted #0068ff;
}

.download-button {
  display: block;
  margin: 2rem auto;
}

.host_quiz_data {
  background-color: white;
  padding: 2rem;
  margin-left: 9rem;
  width: 90%;
  h3 {
  }

  p {
    margin-top: 1rem;
  }
}

.report-status-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
  margin-left: 2rem;
}
.user-report-course-table .lesson-table {
  tbody tr td:nth-child(2) {
    text-align: center;
  }
}
.ant-table-body table tbody tr td {
  vertical-align: middle;
}

.quiz-user-table .shl-simulation-user-detail {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  place-items: center;
}

@media (max-width: 840px) {
  .sharelook-report-user {
    padding: 0 !important;
  }
  .user-report-title {
    font-size: 18px;
  }
  .user-report-profile {
    display: block;
    padding: 30px 1rem;
    height: auto;
  }
  .user-report-profile .info {
    display: block;
  }
  .user-report-profile .about {
    text-align: center;
    font-size: 13px;
    line-height: 25px;
  }
  .user-report-profile .status::before {
    background-color: transparent;
  }
  .report-status-container {
    display: block;
    margin-left: 0;
  }
  .report-status-container > div {
    padding: 10px 0;
  }
  .sharelook-user-view-inner {
    margin-top: -40px;
    .report-view-item {
      font-size: 16px;
    }
  }
  .sharelook-user-item {
    font-size: 16px;
  }
  .report-view-item {
    font-size: 15px;
  }

  .report-view-wrap {
    > div {
      &:nth-child(2) {
        > div {
          margin: 0;
          padding: 0;
          padding-left: 0 !important;
          > div {
            width: 100% !important;
            margin: 1rem 0;
            > div {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .sharelook-user-report {
    > div:nth-child(2) {
      > div {
        width: 100%;
        &:nth-child(1) {
          > div {
            > div {
              width: 100%;
              margin: 5px auto;
              .report-count-container {
                width: auto;
                .report-count-info-user {
                  width: 10rem;
                  .count-user {
                    h1 {
                      font-size: 3rem;
                    }
                    h3 {
                      font-size: 1.5rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
