.podcast-play-main {
    width: 100%;

    &-controls {
        display: flex;
        justify-content: center;
    }

    &-name {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
    }

    &-waves {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -2.3rem;

        &-background {
            background-image: url('./assets/wave.svg');
            background-repeat: repeat-x;
            background-position: center;
            background-size: cover 30px;
            margin-top: 1.2rem;
            height: 40px;
            width: 90%;

            .progress-bar {
                background: #fff;
                // width: 300px;
                margin-top: 1.9rem;
                height: 2px;
                cursor: pointer;
                position: relative;
                z-index: 100;

                .current {
                    position: absolute;
                    color: #000;
                    top: -3rem;
                    left: 0;
                }

                .total {
                    position: absolute;
                    color: #fff;
                    top: -3rem;
                    right: 0;
                }

                .progress {
                    background: black;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 100;
                }
            }
        }
    }
}