.event__hosts {
  padding-top: 1rem;
  &_item {
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #d6d6d6;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0.5rem 2rem;
    img {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      object-fit: cover;
    }

    &_info {
      margin-top: 0.5rem;
      margin-left: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #000000cf;
      font-weight: 600;
      font-size: 14px;
    }
  }
}
