.main__view {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: -10;
}

.button__hidden {
  display: none;
}

.side__view {
  position: absolute;
  max-width: 170px;
  height: 100px;
  right: 20px;
}

.chime_recording_main__view {
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100vh !important;
  z-index: -10;
}

.chime_recording_side_view {
  position: absolute !important;
  max-width: 170px;
  height: 85px !important;
  right: 20px;
  top: 10px;
}
.remote__player__class {
  margin-bottom: 20px;
  position: relative;
  height: 92px;
}
.remote__player__class:hover .volume__controls__class {
  display: block;
}

.volume__controls__class {
  position: absolute;
  width: 92px;
  right: 94px;
  bottom: 0px;
  display: none;
}

.volume__button__class {
  width: 20px;
  background: transparent;
  margin-top: 0px;
  padding: 0px 0px 0px 0px;
  cursor: pointer;
  border: none;
  border-radius: 20px;
}

.username__active__class {
  background: rgba(75, 79, 82, 0.6);
  padding: 4px 4px 4.1px 4px;
  font-size: 12px;
  color: #fff;
}

.username__inactive__class {
  background: rgba(75, 79, 82, 0.6);
  padding: 5px 4px 5px 4px;
  font-size: 10px;
  color: #fff;
}

.control__active__class {
  position: absolute;
  left: 20px;
  bottom: -80vh;
}

// button::before {
//   content: attr(data-title);
//   position: absolute;
//   display: inline-block;
//   right: 0;
//   top: -50px;
//   background-color: rgba(0, 0, 0, 0.6);
//   color: #fff;
//   font-weight: bold;
//   padding: 4px 6px;
//   word-break: keep-all;
//   white-space: pre;
// }

// button:hover::before {
//   display: inline-block;
// }
