.insert-audio-detail {
  border-radius: 10px;

  .ant-modal-content {
    padding: 50px 20px 20px;
    border-radius: 10px;
  }

  .ant-form label {
    font-size: 14px;
    color: #000;
    font-weight: 500;
    margin-top: 17px;
    display: block;
  }

  input,
  textarea,
  .css-1hwfws3 {
    border-radius: 5px;
    min-height: 50px;
  }

  .css-gxoknu-control {
    margin: 0px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .custom-select-tag > div {
    padding: 15px 5px;
    border-radius: 5px;

    input {
      position: relative;
      top: -13px;
    }
  }

  hr {
    margin: 30px -40px 15px;
    border-top: 1px solid #b9b8b8;
  }

  .btn-group {
    text-align: right;

    button {
      margin-left: 10px;
      padding: 2px 40px;
    }
  }
}
