.ai-maze-form-modal {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 4rem;
    border-bottom: 1px solid #e8e8e8;
    p {
      margin: 0;
      font-weight: bold;
    }

    i {
      cursor: pointer;
    }
  }

  .ai-maze-form-label {
    i {
      margin-left: 0.5rem;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;

    .ai-maze-form-label {
      font-size: 14px;
    }
  }
}
