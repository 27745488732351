.skill_journey {
  &__container {
    padding: 3rem 4rem;

    // .ant-form-explain {
    //   text-transform: capitalize;
    // }
  }

  &__back {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-left: 1rem;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #363636;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .shl-primary-btn {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  &__contents {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    margin: 3rem 0 5rem 0;

    .tabs-content {
      border: 1px solid #b3b1b1;
      padding: 2rem;
      border-radius: 1rem;
      height: calc(100vh - 23rem);

      hr {
        border-top: 0.3px solid #c4c4c4;
      }

      .choose-products {
        font-size: 12px;
        line-height: 13px;
        text-transform: capitalize;

        color: #080808;
      }

      .items {
        height: calc(100vh - 45rem);

        overflow-y: auto;
        overflow-x: hidden;

        .ant-checkbox {
          margin-right: 1rem;
        }

        .ant-checkbox-input {
          opacity: 0.6;
        }

        span {
          // font-size: 12px;
          // line-height: 15px;
          color: #080808;
        }
      }

      .check-item {
        margin: 1rem 0;
        color: black;
        display: flex;
        align-items: center;

        label {
          margin-left: 1rem;
        }
      }

      .button-basket {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .ant-btn-round.ant-btn-lg {
          margin-top: 20px;
        }
      }
    }
  }

  &__basket {
    border: 1px solid #b3b1b1;
    padding: 0.5rem 2rem;
    border-radius: 1rem;
    height: calc(100vh - 27rem);
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_items {
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .ant-alert-error {
      flex: 1;
      display: grid;
      place-items: center;
      background-color: #efefef;
      border: none;

      span {
        color: rgb(99, 99, 99);
        font-style: italic;
      }
    }

    &_title {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      margin-left: 0;
      margin-right: 2rem;
      margin-top: 2rem;
      position: relative;

      &::after {
        content: '';
        height: 1px;
        width: 97%;
        background: #c4c4c4;
        position: absolute;
        bottom: 10px;
        margin-left: 0;
      }
    }

    &_list {
      display: flex;
      background: #ececec;
      padding: 2rem 1rem;
      position: relative;
      margin-right: 2rem;
      margin-bottom: 0.5rem;
      border-radius: 10px;

      &:hover {
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px;
      }

      .score {
        display: flex;
        flex-direction: column;

        .ant-input-number {
          width: 5rem;
        }

        span {
          color: #363636;
          font-size: 12px;
        }
      }

      .text {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 1rem;

        span {
          color: #080808;
          font-weight: bold;
        }

        img {
          height: 2rem;
          width: 2rem;
          object-fit: contain;
          cursor: pointer;

          &:hover {
            transform: scale(1.05);
          }
        }
      }

      &::after {
        content: '';
        height: 1px;
        width: 97%;
        background: #e2dfdf;
        position: absolute;
        bottom: 0;
      }
    }

    &_item {
      display: flex;
      background: #ececec;
      padding: 2rem 1rem;
      position: relative;
      margin-right: 2rem;

      .score {
        display: flex;
        flex-direction: column;

        .ant-input-number {
          width: 5rem;
        }

        span {
          color: #363636;
          font-size: 12px;
        }
      }

      .text {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 1rem;

        span {
          color: #080808;
          font-weight: bold;
        }

        img {
          height: 2rem;
          width: 2rem;
          object-fit: contain;
          cursor: pointer;

          &:hover {
            transform: scale(1.05);
          }
        }
      }

      &::after {
        content: '';
        height: 1px;
        width: 97%;
        background: #e2dfdf;
        position: absolute;
        bottom: 0;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding-top: 10px;
    margin: 1.2rem 0;

    .ant-form-item {
      margin: 0;
    }

    .ant-form-item-label label {
      font-size: 13px;
    }

    &::before {
      content: '';
      height: 1px;
      width: 100%;
      background: #e2dfdf;
      position: absolute;
      top: 0;
    }

    &_action {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button,
      .ant-input-number {
        margin-left: 1.2rem;
      }
    }

  }
}

.skill-journey-wrapper {
  >div {
    margin-bottom: 0;

    h2 {
      margin-bottom: -10px;
    }
  }
}

// *::-webkit-scrollbar {
//   width: 4px;
// }

/* Track */
// *::-webkit-scrollbar-track {
//   background: #f1f1f1;
//   border-radius: 15px;
// }

/* Handle */
// *::-webkit-scrollbar-thumb {
//   background: rgb(155, 154, 154);
//   border-radius: 15px;
// }

/* Handle on hover */
// *::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }