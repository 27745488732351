.overview-graph {
  background: white;
  border-radius: 20px;
  width: 97.5%;
  padding: 4rem 3rem;
  position: relative;
  margin-left: auto;
  width: 97%;

  @media (max-width: 840px) {
    margin-top: 20px !important;
    padding: 15px 0 !important;
  }
}

.badge-certificate-menus {
  position: absolute;
  top: 3rem;
  right: 0;
  display: flex;

  .menu-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 51px;
    border-radius: 10px;
    margin-right: 3rem;
    cursor: pointer;

    span {
      color: white;
      font-size: 15px;
      font-weight: bold;
    }

    &:hover {
      box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.1);
    }

    h3 {
      color: white;
      font-size: 10px;
    }

    &:nth-child(2) {
      margin-right: 6rem;
    }
  }
}

.report-reward-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2rem;

  .report-reward-item {
    min-height: 400px;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    cursor: pointer;
    border: 1px solid rgb(223, 223, 223);

    &:hover {
      box-shadow: 0 0 2px 2px rgb(222, 222, 222);
    }

    img {
      height: 100%;
      object-fit: contain;
      margin: 0 auto;
    }

    &-info {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      background: #f8f8f8;

      .about {
        h3 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 220px;
          display: block;
        }

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 250px;
          display: block;
        }
      }
    }
  }
}