.select-date {
  display: flex;
  justify-content: space-between;
  margin-top: 1.2rem;

  &-title {
    color: #000000;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 1.2rem;
  }

  &__content {
    flex: 1;
  }

  &__sidebar {
    padding-top: 2.2rem;
    width: 24rem;
    flex-shrink: 0;

    @media screen and (max-width: '714px') {
      flex-wrap: wrap;
      width: 65vw;
    }
  }

  @media screen and (max-width: '714px') {
    flex-wrap: wrap;
  }
}

.time-container {
  display: flex;
  align-items: center;
  flex-direction: row;

  input {
    border-radius: 4px;
  }

  .date-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .time-section {
    margin-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .all-day-picker {
      color: black;
      margin-left: 2rem;
      font-weight: 600;
    }
  }

  .to {
    margin: 0 5px;
  }
}