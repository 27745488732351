.ant-modal-body {
  padding: 3rem;
  .filter-search-bar {
    display: flex;
    justify-content: left;
    margin-bottom: 1rem;
    margin-top: 3rem;
    margin-right: 5rem;
    flex-direction: row;
    width: 80%;

    .filter-users-section {
      display: flex;
      flex-direction: column;
      margin-right: 2rem;

      .custom-filter-select {
        .ant-select-selection--single {
          width: 15rem;
          height: 4.3rem;
          border-radius: 2rem;
          background-color: #2b66f6;

          .ant-select-selection-selected-value {
            margin-left: 1rem;
            padding: 0.5rem;
            color: white;
          }
        }
      }

      .ant-checkbox-wrapper {
        margin-left: 1rem;
      }
    }

    .searchbar-section {
      width: 100%;

      .custom-search-input {
        height: 4.3rem;
        input {
          padding-left: 1rem;
          color: #bfbfbf;
          border-radius: 2rem;
          background-color: #f7f7f7;
        }
      }
    }

    .ant-select {
      color: blue;
    }

    .ant-input {
      border-radius: 1.5rem;
      padding-left: 3rem;
    }

    .ant-input-suffix {
      margin-right: auto;
      color: #67686c;
      font-size: 1.5rem;
    }
  }

  .user-list {
    margin-top: 1rem;
  }

  .invite-button-container {
    margin-top: 2rem;
  }
}
