.b-pages {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
  max-height: 37rem;

  &__item {
    list-style: none;
    padding: 0.8rem;
    margin: 1rem 0;
    border: 1.5px solid #edf2f5;
    border-radius: 8px;
  }
}

.title {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
}

.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
}

.b-page {
  display: flex;
  align-items: center;

  &__handler {
    flex-shrink: 0;
    margin-right: 2rem;
  }

  &__drag {
    position: relative;
    top: 1px;
    display: block;
    width: 1.2rem;
    height: 1rem;
    opacity: 0.2;
    cursor: row-resize;
    background: linear-gradient(
      180deg,
      #000 0%,
      #000 20%,
      #fff 20%,
      #fff 40%,
      #000 40%,
      #000 60%,
      #fff 60%,
      #fff 80%,
      #000 80%,
      #000 100%
    );
  }

  &__info {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__name {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__control {
    svg {
      color: #d80027;
    }
  }
}

.error_message {
  color: #ff4b55;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.lesson-create-main-wrap {
  // display: flex;
  // width: 100%;

  .lesson-create-main-inner {
    display: flex;
    gap: 2rem;

    > div {
      max-width: 100%;
      margin-bottom: 2rem;
      width: 100%;

      .css-upjwvs-container {
        width: 100%;

        .css-2mz5qb-control,
        .css-1bvvkp4-control {
          max-width: 100%;
          border-radius: 8px;
          border: 1.5px solid #e3e3e3;

          &:hover {
            border: 1.5px solid #698bf2;
          }
        }
      }

      .lesson-create-btn {
        width: 100%;

        > span {
          margin: 0;
        }

        > input {
          border-radius: 8px;
          border: 1.5px solid #e3e3e3;

          &:focus {
            border: 1.5px solid #698bf2;
          }
        }
      }
    }

    .lession-topic-label {
      color: #212121;
      font-weight: 600;
      text-transform: capitalize;
      font-size: 12px;
      margin-bottom: 4px;
    }
  }

  .lesson-create-main-bottom {
    position: relative;
    width: 100%;
  }

  // >div:last-child {
  //   min-width: 40rem;
  //   max-width: 40rem;
  // }
}
