.table-headers-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 17rem 0px 1rem;
}

.table-headers-column {
  display: flex;
}

.table-body {
  font-weight: normal;
  display: flex;
  height: fit-content !important;
}
.table-questions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 4rem;
  width: 75%;
}

.table-questions td {
  line-height: 2.2rem;
}

.table-scores {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: -2rem;
}

.table-scores td {
  line-height: 2.2rem;
}

.table-total-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.table-total-title {
  display: flex;
  margin-left: 3rem;
}

.table-total-score {
  display: flex;
  text-align: center;
  margin-right: 14rem;
}
