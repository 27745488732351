.decription {
  display: flex;
  justify-content: space-between;
  margin-top: 1.2rem;

  &__content {
    flex: 1;
  }

  &__sidebar {
    padding-top: 2.2rem;
    width: 24rem;
    flex-shrink: 0;

    @media screen and (max-width: '714px') {
      flex-wrap: wrap;
      width: 65vw;
    }
  }

  @media screen and (max-width: '714px') {
    flex-wrap: wrap;
  }
}

.description-input-title {
  color: #000000;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 1.2rem;
}

.description-input {
  width: 100%;

  .ck-editor__editable_inline {
    min-height: 250px !important;
    /* Ensures a minimum height of 300px */
    max-height: 250px !important;
    /* Ensures the height does not exceed 300px */
    overflow: auto;
    /* Adds scrolling if the content exceeds 300px */
  }

  .ant-input {
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 1.8rem;
    font-size: 1.5rem;

    &:focus {
      border-color: #40a9ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }
}