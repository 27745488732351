.wrap-crop-image {
  width: 100%;
  height: 100%;
  position: fixed;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.50);

  .crop-container {
    margin: 0 auto;
    width: 80rem;
    height: 65rem;
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: white;
    overflow: hidden;
    display: flex;
  }

  .cropper {
    width: 300px;
    height: 300px;
  }

  .wrap-btn-actions {
    width: 80rem;
    height: 80px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    background-color: #f5f5f5;
    border: 1px solid #d8d8d8;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  Button {
    min-width: 150px;
    height: 40px;
    border-radius: 3px;
  }
}

.hw0 {
  width: auto !important;
  height: auto !important;
  bottom: -40px;
}

.left0-hw0 {
  width: auto !important;
  height: auto !important;
  left: 0 !important;
  bottom: -40px;
}

.right0-hw0 {
  width: auto !important;
  height: auto !important;
  left: 0 !important;
  bottom: -40px;
}

.left0-hw0 {
  width: auto !important;
  height: auto !important;
  left: 0 !important;
  top: 0px;
  bottom: -40px;
}

.css-ebdd77 {
  z-index: -1;
}
