.add-guest {
  display: flex;
  justify-content: space-between;

  &__content {
    flex: 1;

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  &__sidebar {
    padding-top: 2.2rem;
    width: 24rem;
    flex-shrink: 0;

    @media screen and (max-width: '714px') {
      flex-wrap: wrap;
      width: 65vw;
    }
  }

  @media screen and (max-width: '714px') {
    flex-wrap: wrap;
  }
}

.discussion-add-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: -1rem;
  padding-top: 1.2rem;
  border-radius: 0.4rem;
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold;
  width: 100%;

  .add-container {
    display: flex;
    flex-direction: column;

    .add-button-container {
      display: flex;
      align-items: center;
      cursor: pointer;
      line-height: 40px;

      .add-icon {
        position: relative;
        cursor: pointer;
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        color: #246cf6;
      }

      .add-text {
        cursor: pointer;
        font-size: 1.2rem;
        color: #246cf6;
      }
    }

    .selected-guests {
      .selected-guest-tag {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #f0f0f0; // change the background color of the tag
        color: #000; // change the text color of the tag
        border: none; // remove the border
        border-radius: 0.5rem; // round the corners of the tag
        padding: 1rem; // add some space around the text inside the tag
        margin-right: 1rem; // add some space between tags
        margin-bottom: 1rem; // add some space between tags in case they wrap to the next line
        font-weight: 600;

        .anticon {
          padding-left: 2rem;
        }
      }
    }
  }

  .delete-all-container {
    flex-grow: 1;
    /* Add this to expand and push the delete button to the right */
    display: flex;
    justify-content: flex-end;
    /* Align the button to the right */
    align-items: center;
  }

  .delete-all-button {
    color: red;
    margin-left: auto;
    /* Add some spacing between the text and the button */

    &.disabled {
      color: #9c9c9c !important;
      /* Set the color to a grey shade when disabled */
      cursor: not-allowed;
      /* Change the cursor to indicate non-interactivity */
    }
  }
}