$main-color: #5057d5;

.filtermedia-wrapper {
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  margin-right: 5px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ .checkmark:after {
    display: block;
    background-color: $main-color;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 20px;
    color: white;
    left: 0;
  }

  .checkmark {
    background-color: white;
    border-radius: 15px;
    padding: 8px 17px;
    font-size: 11px;
    text-transform: uppercase;
    color: $main-color;
    border: 1px solid $main-color;
    position: relative;
    font-weight: 700;

    &::after {
      content: attr(data-label);
      position: absolute;
      display: none;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 13px;
    }
  }
}
