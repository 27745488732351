.form_list_file {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .file_section {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgb(239, 239, 239);
    padding-top: 1rem;

    .file_title {
      font-weight: 400;
    }

    i {
      color: rgb(194, 35, 35);
    }
  }
}
