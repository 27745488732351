// TrainingListContainer.js

.training-list {
  &-header {
    padding: 2rem;
    background: #fff;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 840px) {
      padding: 2rem 1.2rem;
    }

    span {
      font-weight: bold;
      margin-left: 1rem;
    }

    a {
      cursor: pointer;
    }
  }

  &-content {
    background: #f8f8f8;
    // height: calc(100vh - (85px + 5rem));

    .ant-tabs-nav {
      padding: 1rem 3rem;
    }
  }

  &-actions {
    background: #efefef;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 840px) {
      padding: 2rem 1.2rem;
    }
  }

  &-tags {
    display: flex;
    gap: 1rem;

    @media (max-width: 840px) {
      margin-bottom: 1rem;
    }
  }

  &-filters {
    input {
      border-radius: 20px;
      padding: 2rem;
    }
  }

  &-cards {
    padding: 2rem 3rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 1.5rem;

    @media (max-width: 1280px) {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    @media (max-width: 540px) {
      padding: 2rem 1.2rem;
      grid-template-columns: auto;
      grid-gap: 0;
    }
  }

  &-pagination {
    padding: 2rem 5rem 0 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 840px) {
      padding: 2rem 5rem 0 1.2rem;
    }
  }

  &-sequence {
    width: 100%;
    min-height: calc(100vh - 228px);
    overflow-y: auto;
    background: #fff;
    box-shadow: 0 1px 3px 0 rgba(68, 68, 68, 0.055),
      0 2px 5px 0 rgba(63, 62, 62, 0.1);
    position: sticky;

    .smooth-dnd-container {
      width: 100% !important;
      min-height: calc(100vh - 280px) !important;
    }

    .trainning-title {
      width: 100%;
      text-align: center;
      padding: 10px 20px;
      font-size: 1.8rem;
      text-transform: uppercase;
      color: #000000d9;
      font-weight: bold;
      background: #fff;
      min-height: 2.5rem;
      border: 0.1rem solid #ddd;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }
}

.pagination-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;
}
