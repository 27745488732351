.action-list {
  padding: 0px 30px 0px 40px;
  iframe {
    width: 100%;
  }

  audio {
    width: 100%;
  }

  ul {
    display: flex;
    flex-direction: row-reverse;

    li {
      list-style: none;
      display: inline-flex;
      margin: 0px 10px;
      justify-content: right;
    }
  }
}
