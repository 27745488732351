@import '../../../styles/variables';

// .eve-wrap {
//   height: calc(100vh - 8rem);
//   overflow: hidden;
// }

.discussion_room {
  padding: 2rem;

  @media screen and (min-width: 1440px) {
    margin: auto 5rem;
  }

  @media screen and (min-width: 1600px) {
    margin: auto 10rem;
  }

  @media screen and (min-width: 1800px) {
    margin: auto 20rem;
  }

  &__inner {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 992px) {
      flex-direction: row;
    }
  }

  &__content__container {
    margin-right: 3.2rem;
    flex: 0.7;
    overflow-y: hidden;
  }

  &__sidebar {
    flex: 0.3;
    height: calc(100vh - 20rem);
  }

  &-sidebar {
    &__attachement {

      .comment-container {
        background-color: #F0F0F0;
        padding: 20px;
        margin-bottom: 1.2rem;
        border: 1px solid #E5E5E5;
        height: 160px;
        overflow-y: auto;

        &_title {
          text-align: left;
          padding-bottom: 10px;
          border-bottom: 1px solid #E5E5E5;

          span {

            h1 {
              color: black;
              font-size: 12px;
              font-weight: 400;
              text-transform: uppercase;
            }
          }
        }
      }

      &_body {
        margin: 0.6rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &__section {
      position: relative;
      background: #ffffff;
      height: 100%;
      border-radius: 15px;

      &:last-child,
      &_clear {
        padding-bottom: 0;
      }

      &_tab-head {
        width: 100%;
        display: flex;
        justify-content: left;
        align-items: left;

        h1 {
          color: black;
          font-weight: 400;
          text-transform: uppercase;
        }
      }

      .comment-container {
        background-color: #F0F0F0;
        padding: 20px;
        border: 1px solid #E5E5E5;

        &_title {
          text-align: left;
          padding-bottom: 10px;
          border-bottom: 1px solid #E5E5E5;

          span {

            h1 {
              color: black;
              font-size: 12px;
              font-weight: 400;
              text-transform: uppercase;
            }
          }
        }
      }

      .card-container {
        height: 100%;
        //padding: 10px;
      }

      .card-container>.ant-tabs-card>.ant-tabs-content>.ant-tabs-tabpane {
        //padding: 1.5rem;
        overflow-y: auto;
      }

      .card-container>.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab {
        border-color: transparent;
        background: transparent;
        margin-right: 0;
      }

      .card-container>.ant-tabs-card .ant-tabs-nav-wrap {
        background: #ffffff;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          /* Position the line at the bottom of the parent element */
          left: 2%;
          width: 95%;
          /* Adjust the width to control the length of the line */
          height: 1px;
          /* Set the thickness of the line */
          background: #e5e5e5;
          /* Set the line color */
        }
      }

      .card-container {
        color: #e5e5e5;
        border: 1px solid;
        border-color: #e5e5e5;

        .ant-tabs-nav {
          width: 100% !important;
        }
      }
    }
  }

  &-stream {
    position: relative;

    .wrap_video_graph {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    &-item {
      position: relative;
      min-height: 0;
      max-height: calc(100% - 7rem);

      .react-vid-player {
        height: calc(100vh - 25rem) !important;
        width: 100% !important;
      }
    }

    .input-range {
      width: 82.3rem;
      height: 13rem;
      // position: absolute;
      bottom: 4.8rem;
      left: 0.1rem;

      &__track {
        background-color: white;
      }

      &__track--background {
        height: 100%;
        position: relative;
        top: 0;
      }

      &__track--active {
        height: 100%;
        // background-color: map-get($colors, blue);
        background-color: #d7e7ff;
        border-right: 0.1rem solid map-get($colors, blue);
        border-radius: 0;
      }

      &__slider-container {
        display: none;
      }

      &__label--max {
        display: none;
      }

      &__label--min {
        display: none;
      }

      &__label-container {
        display: none;
      }
    }

    // &__blur-pic :before {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background-color: rgba(255, 255, 255, 0.5);
    //   backdrop-filter: blur(0px);
    // }

    &__cover-pic {
      height: calc(100vh - 50rem);
      min-height: 50rem;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #FFFFFF;

      .__schedule__dc-room {
        background-color: #ff0000;
        color: white;
        padding: 0.5rem 2rem;
        width: 100%;
        font-size: 1.6rem;
        letter-spacing: -0.1rem;
      }

      .__count_down {
        background-color: white;
        color: #0068ff;
        position: absolute;
        bottom: 0;
        padding: 0.5rem 1.5rem;
        margin: 1rem;
        width: 97.5%;
        font-weight: bold;
        font-size: 1.6rem;
        flex-direction: row;
        display: flex;
        align-items: center;

        .icon_message {
          position: absolute;
          right: 0;
          margin-right: 1.5rem;
          width: 2.8rem;
        }
      }

      &::after {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 62.25%;
      }
    }

    &__video {
      width: 100%;
      height: 0;
      padding: 0 0 56.25% 0;

      &_NOT_STARTED {
        background: #333;

        &::after {
          content: attr(data-message);

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          display: block;
          padding: 0.8rem 1.6rem;
          color: #fff;
          border: 1px solid #fff;

          font-weight: 600;
          font-size: 1.4rem;
          border-radius: 2rem;
        }
      }

      &_PROCESSING {
        background: #333;

        &::after {
          content: attr(data-message);

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          display: block;
          padding: 0.8rem 1.6rem;
          color: #fff;

          font-weight: 600;
          font-size: 1.4rem;
          text-align: center;
        }
      }

      &_RUNNING {
        &::after {
          content: attr(data-message);

          position: absolute;
          top: 1.6rem;
          right: 1.6rem;

          display: block;
          padding: 0.4rem 0.8rem;
          background: #ff6332;
          color: #fff;

          font-size: 1.2rem;
          text-transform: uppercase;
          border-radius: 2rem;
        }
      }
    }
  }

  &__head {
    margin-top: 2rem;
  }

  &__heading {
    font-size: 1.8rem;
    margin-bottom: 0.8rem;
    display: inline-block;
    width: 600px;
  }

  &__count {
    font-size: 1.8rem;
    margin-bottom: 0.8rem;
    display: inline-block;
    margin-left: 6.2em;
    color: #000000;
    font-weight: 600;
  }

  &-users {
    display: flex;
    align-items: center;

    &__icon {
      font-size: 1.2rem;
      padding-right: 0.8rem;
    }

    &__text {
      font-size: 1.2rem;
      text-transform: uppercase;
    }
  }
}

.hosts {
  &__item {
    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  }
}

.host {
  display: flex;
  align-items: center;

  &__thumb {
    width: 3.2rem;
    height: 3.2rem;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
  }

  &__name {
    padding-left: 1.2rem;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.wrap_no_profile {
  padding: 5px 10px;
  // border: 1px solid map-get($colors, red);

  span {
    color: map-get($colors, red);
  }
}

/*.btn-broadcast {
  margin-left: 48.5em;
  margin-top: 0.8em;
  width: 400px;
  cursor: pointer;
}*/

.btn-broadcast {
  margin-left: 47.6em;
  margin-top: 1.2em;
  width: 400px;
  cursor: pointer;
}

.btn-img {
  width: 35px;
  margin: 2px 10px;
}

.btn-text {
  font-size: 16px;
  font-weight: 600;
  color: map-get($colors, black);
}

.discussion_room__stream {
  position: relative;

  &__container {
    width: 100%;
    // height: calc(100vh - 15rem);
    display: flex;
    flex-direction: column;
  }
}

.online__info {
  position: absolute;
  width: auto;
  height: auto;
  z-index: 10;
  margin-top: 7rem;
}

.online__info_safari {
  position: absolute;
  margin-left: 6.5em;
  top: 5px;
  width: auto;
  height: auto;
  z-index: 10;
}

.online__block {
  background-color: '#8e9abd70';
  color: #fff;
  display: inline-block;
  padding: 0.2em 0.4em;

  img {
    vertical-align: middle;
    padding-right: 3px;
    width: 50px;
    margin-right: -10px;
  }

  &.online__duration {
    margin: 0 8px;
  }

  .timer {
    color: #ff3737;
    margin-left: 0.5rem;
    border: 1px solid rgba(250, 98, 98, 0.267);
    background: #ffffff0a;
    padding: 0.1rem 0.25rem;
  }
}

.white {
  color: #fff !important;
}

.fz18 {
  font-size: 18px !important;
}

.lh20 {
  line-height: 20px !important;
}

.ant-radio-group .ant-radio-group-outline {
  margin-top: 0.5em;
}

.online__duration {
  position: relative;
  padding-left: 20px;

  &::before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    left: 2px;
    top: 5px;
    border-radius: 50%;
    animation: blink 1.5s linear infinite;
    background-color: map-get($colors, red);
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.discussion_room__head_online {
  display: flex;
  align-items: center;
}

.ml-auto {
  margin-left: auto;
  cursor: pointer;
}

.discussion_room__host__select {
  margin-right: 15px;
}

.mr-5 {
  margin-right: 5px;
}

.select__disable {
  pointer-events: none;
  opacity: 0.4;
}

.discussion_room_card__wrapper {
  position: absolute;
  text-align: 0;
  top: 15px;
  right: 0;
}

.close_passive_user {
  cursor: pointer;
  position: relative;
  font-size: medium;
  top: -8px;
}

.close_user_not_joined {
  right: 25px;
}

.close_user_joined {
  right: 45px;
}

.avoid__clicks {
  pointer-events: none;
}