.room__item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #d6d6d6;

  & > span {
    font-size: 12px;
    font-weight: bold;

    &:first-child {
      text-transform: uppercase;
      color: #000;
    }
  }
}
