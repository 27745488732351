.podcast-error {
    min-width: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.2rem;
}

.podcast_rating {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    // padding: 1.2rem;
    &_title {
        h1 {
            font-size: 32px;
            line-height: 40px;
            font-weight: 700;
            text-transform: capitalize;
            color: #000000;
        }
    }

    &_text {
        padding: 0.6rem 0;
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
    }

    &_value {
        margin-bottom: 0.6rem;
        font-size: 4rem;
    }
}

.podcast-play {
    height: calc(100vh - 110px);
    background-color: #fff;
    padding: 3rem;

    &_body {
        border-radius: 35px;
        width: 100%;

        @media (max-width: 640px) {
            padding: 1.2rem;
        }

        .lsection-col {
            border: 1px solid #C7C7C7;
            height: calc(100vh - 170px);
            overflow-y: auto;
            padding: 1.2rem;
            border-radius: 10px;
        }

        &_guestheading {
            color: #000000;
            font-size: 14px;
            line-height: 18px;
            font-weight: 700;
            padding-left: 1.6rem;
        }

        &_avatar {
            display: flex;
            padding-right: 1.6rem;

            .avatar-circle {
                width: 2.7rem;
                height: 2.7rem;
                justify-content: center;
                border-radius: 50%; //
                overflow: hidden;
                border: solid #f0f0f0;
                margin: 0.3rem;

                img {
                    width: 100%;
                    height: 100%;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        &_lsection {
            position: relative;
            height: calc(55vh - 10.4rem);
            width: 100%;
            padding: 1.6rem;

            &_cover {
                width: 100%;
                border-radius: 25px;
                background-color: #fff;
                height: calc(55vh - 12rem);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }

            &_player {
                position: absolute;
                height: 12rem;
                width: 75%;
                bottom: 2rem;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 60px;
                backdrop-filter: blur(40px);
                background-color: #d0d0d06a;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                @media (max-width: 640px) {
                    width: 95%;
                }
            }
        }

        &_tabs {
            position: relative;
            width: 100%;
            padding: 0 2rem 1.6rem 2rem;

            &_overview {
                &_title {
                    font-size: 24px;
                    font-weight: 600;
                    color: #000000;
                }

                &_desc {
                    line-height: 17.57px;
                    font-size: 14px;
                    color: #000000;
                    padding-top: 0.6rem;
                }

                &_datetime {
                    padding-top: 0.6rem;
                    line-height: 17.57px;
                    font-size: 14px;
                    color: #767676;
                }
            }
        }

        .rsection-col {
            border: 1px solid #C7C7C7;
            height: calc(100vh - 170px);
            overflow-y: auto;
            border-radius: 10px;
        }

        &_rsection {
            padding: 1.2rem;
            position: relative;

            &_title {
                color: #000000;
                font-size: 14px;
                line-height: 18px;
                font-weight: 700;
            }

            &_search {
                padding-top: 1.2rem;
                padding-bottom: 0.6rem;

                .ant-input {
                    border-radius: 20px;
                }
            }
        }

        &_content {
            padding: 1.2rem 3rem;
            border-bottom: 1px solid #F2F2F2;

            &_active {
                background-color: #256BF60F;
                border: none;
            }

            &_name {
                color: #000000;
                text-transform: capitalize;
            }

            &_date {
                color: #767676;

            }

            &:hover {
                background-color: #256BF60F;
                cursor: pointer;
                opacity: 0.8;
            }
        }
    }


}

.podcast-reviews {
    display: flex;
    justify-content: center;
    background-color: #fff;


    &-body {
        border-radius: 35px;
        max-height: 55vh;
        width: 100%;

        &-title {
            padding-top: 1.2rem;
            font-size: 13px;
            line-height: 12.55px;
            font-weight: 500;
            color: #838383;
        }
    }
}

.description-output {
    margin-top: 20px;
}

.description-output div {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    min-height: 100px;
    max-height: 250px;
    overflow-y: auto;
}