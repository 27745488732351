.account-subscription {
  &-title {
    color: black;
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 20px;

    &:not(:first-of-type) {
      margin-top: 20px;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    &-card {
      display: flex;
      gap: 2rem;
      padding: 2rem;
      border: 1px solid #e0e0e0;

      img {
        height: 150px;
        object-fit: cover;
      }

      &-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        &-title {
          color: black;
          font-weight: bold;
          font-size: 14px;
          cursor: pointer;

          &:hover {
            color: #004fd9;
            text-decoration: underline !important;
          }
        }

        &-description {
          font-size: 12px;
        }

        &-price {
          display: flex;
          align-items: flex-end;
          flex-wrap: wrap;
          gap: 1rem;
          margin-top: auto;
          > span {
            width: 142px;
            font-weight: bold;

            h1 {
              color: blue;
              font-weight: 600;
              font-size: 2rem;
            }
          }
        }

        &-date {
          background: #efefef;
          border-radius: 100px;
          padding: 10px 20px;
          font-size: 12px;
          display: flex;
          gap: 2rem;
          align-items: center;
          height: 23px;
          white-space: nowrap;
          font-weight: bold;

          .start-date {
            color: black;
          }

          .end-date {
            color: red;
          }
        }

        &-button {
          height: 23px;
          font-size: 13px;
        }
        .sharebot-btn {
          height: 32px;
          padding: 0 16px;
          font-size: 14px;
          border-radius: 32px;
          color: #fff !important;
        }
        .sharebot-btn-primary {
          color: #fff;
          background-color: #004fd9;
        }
        .blue-bordered-button {
          color: #004fd9;
          border-color: #004fd9;
        }
      }
    }

    &-pagination {
      display: flex;
      justify-content: flex-end;
    }
  }

  &-error {
    padding-left: 1rem;
  }

  &-delete {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;

    &-title {
      text-align: center;
      text-transform: uppercase;

      h2 {
        font-weight: 700;
        color: black;
      }
    }

    &-buttons {
      display: flex;
      justify-content: center;
      gap: 1rem;
      margin: 3rem 0;

      button {
        width: 140px;
        text-transform: uppercase;
        font-weight: 600;
      }

      .no-button {
        background: #efefef;
      }
    }
  }

  &-options {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;

    &-item {
      background: #f5f5f5;
      padding: 1rem 2rem;
      cursor: pointer;
      font-weight: bold;
      color: black;
      display: flex;
      gap: 1rem;
      align-items: center;
      &-container {
        text-align: left;
        margin: 1rem 0;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      &:hover {
        box-shadow: 0px 0px 1px 0px rgb(0, 0, 0);
      }
    }
  }
}
.green {
  span {
    background: green;
  }
}

.red {
  span {
    background: red;
  }
}

.stripe-connect-wrapper {
  position: relative;
  margin-bottom: 30px;
  // only for master hide
  display: none;
  .stripe-connect-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    flex-wrap: wrap;
    border: 1px solid #d9d9d9;
    .stripe-connect-left {
      display: flex;
      align-items: center;
    }

    .stripe-connect-right {
      display: flex;
      align-items: center;
      gap: 30px;
      .ant-btn-disconnect-btn {
        background: #d9d9d9;
      }
    }
    .image {
      width: 100px;
    }
  }
}
.head-row {
  display: flex;
  justify-content: space-between;
}
