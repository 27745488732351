.view-channel-videos {
  margin-top: 2rem;
  height: calc(100vh - 22rem);
  overflow: auto;
  padding: 2px 2rem 0 2rem;
  &-container {
    height: 100%;
    border: 1px solid #dfdfdf;
    box-shadow: 0 0 2px 1px #f2f2f2;
    border-radius: 15px;
  }

  &-search {
    margin: 2rem;
    input {
      border-radius: 10px;
    }
  }

  .selected-video-item {
    background: #f8f8f8;
  }

  &-item {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
    cursor: pointer;
    padding: 1rem;
    box-shadow: 0 0 1px 1px #dfdfdf;

    &:hover {
      box-shadow: 0 0 2px 2px #dfdfdf;
    }

    &-cover {
      width: 120px;
      object-fit: cover;
      height: 70px;
      min-width: 120px;
      max-width: 120px;
      pointer-events: none;
      background: #dfdfdf;

      &-container {
        position: relative;
      }
    }

    &-playIcon {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.189);
      display: grid;
      place-items: center;
      backdrop-filter: blur(10px);
      color: rgb(255, 255, 255);

      text-align: center;
    }

    &-filename {
      display: -webkit-box;
      word-wrap: break-word;
      max-width: 200px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #767676;

      span {
        font-weight: bold;
        font-size: 13px;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }
    &-stats {
      font-size: 1.2rem;
      color: #aaaaaa;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-duration {
        color: rgb(49, 139, 13);
      }
    }
  }
}
