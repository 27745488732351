.live-assessment {
  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    &_questions {
      flex: 1;
      overflow-y: auto;
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_message {
      width: 100%;
    }
  }
  &_index {
    color: black;
    font-size: 16px;
    font-weight: 600;
    margin-left: 1.5px;
    margin-right: 1.5px;
  }
  &_questions {
    cursor: pointer;
    color: black;
    font-size: 16px;
    font-weight: 600;
  }
  &_scores {
    width: 60;
    font-size: 16px;
    color: #000000a1;
  }
}
