.create-maze {
  display: flex;
  height: calc(100vh - 23rem);

  .hide__map {
    width: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #136cfb;
    margin-bottom: 2rem;
    cursor: pointer;
  }

  .show__left {
    flex: 1;
  }

  .show__right {
    flex: 1;
    position: relative;
  }

  .hide__panel {
    display: none;
  }

  .hide_bar {
    cursor: pointer;
  }

  &__left-contents {
    height: 100%;
    scroll-behavior: smooth;
    overflow: auto;
  }

  &__left,
  &__right {
    background: #f5f5f5;
    border-radius: 1rem;
    display: block;
  }

  &__left {
    height: 100%;
    flex: 0.6;
    overflow: hidden;
    padding: 2rem 0 6rem 2rem;
  }

  &__right {
    margin-left: 3rem;
    padding-top: 2rem;
    overflow: hidden;
    flex: 0.4;
    display: flex;
    flex-direction: column;
    position: relative;

    .hide__map {
      margin-left: 2rem;
    }

    // &-top {
    //   display: flex;
    //   justify-content: space-between;
    //   &_right {
    //     font-size: 2rem;
    //     display: flex;
    //     border-radius: 3px;
    //     transform: translateY(-1rem);
    //     div {
    //       cursor: pointer;
    //       font-size: 1.5rem;
    //       display: grid;
    //       place-items: center;
    //       width: 5rem;
    //       border-radius: 50%;
    //       -moz-box-shadow: 0.5px 0.5px 1px 0.5px #ccc;
    //       -webkit-box-shadow: 0.5px 0.5px 1px 0.5px #ccc;
    //       box-shadow: 0.5px 0.5px 1px 0.5px #ccc;
    //       color: #5057d5;
    //       &:hover {
    //         background-color: rgb(231, 237, 253);
    //       }

    //       &:active {
    //         transform: translateY(2px);
    //       }

    //       &:first-child {
    //         margin-right: 1rem;
    //       }
    //     }
    //   }
    // }
  }
  .maze__action {
    display: flex;
    justify-content: center;
    width: 100%;

    button {
      margin-right: 1rem;
    }
  }
}

.maze__loader {
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px dashed #5057d5;
  margin: 2rem;
}

.maze-load-more {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}
