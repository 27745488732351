.upload-btn {
  width: 100%;
  max-width: 48%;
  text-align: left;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  gap: 10px;
  padding: 20px;
  font-size: 14px;

  >div {
    border-radius: 100%;
    width: 24px;
    height: 24px;
  }
}

.image-select-upload {
  display: flex;
  min-height: 25rem;
  align-items: center;
  padding: 3rem;
  position: relative;
  border: 1.5px solid #e3e3e3;
  border-radius: 8px;
  cursor: pointer;

  .image-select-upload-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .image-select-image-wrap {
      display: flex;
      align-items: center;
      gap: 2rem;
      flex-wrap: nowrap;
      width: 100%;

      .image-select-info {
        display: flex;
        flex-direction: column;
        align-items: space-between;
        gap: 2rem;
        flex-basis: 30%;
        max-width: 30%;
        box-sizing: border-box;

        >div {
          display: flex;
          flex-direction: column;
          line-height: 20px;

          label {
            font-weight: 600;
            text-transform: uppercase;
            color: #00000062;
          }

          span {
            color: #000000de;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
          }
        }
      }

    }

    .image-select-data {
      img {
        border-radius: 8px;
        max-height: 20rem;
        object-fit: contain;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      }
    }

    .image-select-choose {
      display: flex;
      gap: 30px;
      align-items: center;
      position: absolute;
      top: 10px;
      right: 15px;

      img {
        width: 15px;
        height: 15px;
        object-fit: cover;
      }
    }
  }
}

.secondary-image-upload {
  padding: 1rem 0rem;

  li {
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #e3e3e3;
    line-height: 0;

    &:last-child {
      border-bottom: none;
    }
  }
}

.image-upload-btn-wrap {
  .has-error {
    div {
      border-color: red;
    }
  }
}