.knowledge-center {
  &-input {
    width: 70vw;
    border-radius: 5px;
  }

  &-head {
    background: white;
    font-size: 2rem;
    padding: 1rem 1rem 1rem 2rem;
    font-weight: bold;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 13rem);
    background: #f4f5fa;
    gap: 2rem;
    padding: 0 2rem;
    border-radius: 0 0 16px 16px;

    h1 {
      font-size: 3rem;
      color: black;
      font-weight: 800;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 2rem;
    }
  }

  &-buttons {
    display: flex;
    gap: 2rem;
    align-items: center;
  }

  &-icon {
    border-radius: 50%;
  }

  &-conversation {
    max-height: calc(100vh - 33rem);
    width: 70vw;
    background: white;
    padding: 2rem;
    border-radius: 10px;
    overflow-y: auto;
  }

  &-item {
    padding: 2rem;
    background: #f7f7f7;
    border-radius: 5px;
    &:not(:first-child) {
      margin-top: 2rem;
    }
    &-avatar {
      background: #2e70f2;
    }

    &-maintext {
      display: flex;
      color: black;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.knowledge-center-head-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}
