.button-create {
  z-index: 1;
  top: 0;
  right: 0;
  position: absolute;
  float: right;

  &:hover {
    background: #1dba61;
    color: white;
  }
}

.ant-modal-close-x {
  position: absolute;
  right: 0;
}

.ant-tabs {
  width: 100%;
  overflow: initial;
}

.tab-nav-margin .ant-tabs-bar {
  margin-bottom: 0px;
}

.custom-ant-modal .ant-modal-content {
  border-radius: 10px;

  .ant-modal-header {
    border-bottom: 0px solid #e8e8e8;
  }

  .ant-modal-body {
    padding: 0px 24px;
    position: relative;
  }

  .ant-modal-footer {
    padding: 0px 16px;
    border-top: none;
  }
}

.bg-modal-left {
  background: #fff;
  margin: 0px -44px;
  padding: 0px 44px;

  .card__content {
    padding: 1.5rem 2rem;
    background: transparent;
  }
}

.canva-container {
  width: 100%;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  cursor: pointer;
  border: 1px dotted black;
}
