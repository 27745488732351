/*.ant-tabs {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #777777;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', 'tnum';
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  overflow: hidden;
  zoom: 1;
}*/

.remove-icon-show i {
  display: none;
}

.ant-tabs {
  color: #000;
}

.left-margin {
  margin-left: 45px;
}

.left-short-margin {
  margin-left: 10px;
}

.ant-tabs-bar {
  border-bottom: 1px solid #efefef;
  background: #fff;
}

.report-overview {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #d8e1eb;
  border-radius: 20px;
  height: 160px;
  width: 97%;
}

.report-overview .overview-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.report-overview .overview-item h2 {
  position: absolute;
  /* bottom: 45%; */
  bottom: 43%;
  font-size: 8px;
  font-weight: bolder;
  letter-spacing: 1px;
  color: #000000;
  line-height: 13px;
  text-transform: uppercase;
}

.report-overview .overview-item span {
  font-weight: bold;
  font-size: 30px;
  line-height: 38px;
  text-transform: uppercase;
  color: #000000;
  margin-top: 4.3rem;
}

.report-overview .overview-item h3 {
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 4.8rem;
  text-align: center;
}

.overview-item-title-average {
  margin-bottom: 4rem !important;
}

.overview-item:not(:last-child)::after {
  content: '';
  height: 90px;
  width: 0.5px;
  position: absolute;
  right: 0;
  top: 3.7rem;
  background-color: #93a5b6;
}

.report-count-container {
  /* padding: 0.6rem; */
  height: 150px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.102);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.report-count-container:hover {
  background: linear-gradient(#ffffff, #ffffff) padding-box,
    linear-gradient(110.38deg, #a4efa7 18.22%, #8dc3f8 82.9%) border-box;
}

.report-count-container img {
  height: 3vw;
  width: 2.2vw;
  object-fit: contain;
}

.report-count-info {
  display: flex;
  /* width: 6.5vw; */
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 0.8rem;
}

.report-count-info .count {
  font-weight: bold;
  font-size: 1.75vw;
  /* line-height: 43px; */
  text-transform: uppercase;
  color: #1294f2;

}

.report-count-info .title {
  font-weight: bold;
  font-size: 0.80vw;
  /* font-size: 14px; */
  line-height: 18px;
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
  /* margin-top: 0.7rem; */
}


.report-count-info .activity {
  margin-bottom: 0.3rem;
  margin-top: 1rem;
}

.report-count-info .activity h3 {
  font-weight: bold;
  font-size: 0.60vw;
  /* font-size: 10px; */
  line-height: 14px;
  text-transform: uppercase;
  color: #000000;
}

.report-count-info .activity::after {
  content: '';
  height: 1px;
  width: 5vw;
  position: absolute;
  top: 0.7rem;
  background-color: #93a5b6;
}

.report-count-info-user {
  text-align: center;
  width: 6.5vw;
}

.report-count-info-user .count-user h1 {
  font-weight: bold;
  font-size: 1.25 vw;
  color: #1294f2;
}

.report-count-info-user .count-user h3 {
  font-weight: bold;
  font-size: 0.85vw;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
  margin-top: 0.7rem;
}

.box-statistic {
  background-color: #fff;
  margin: 0 5px 5px 0;
  height: 150px;
  border-radius: 20px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.102);
  text-align: center;
  display: grid;
  place-items: center;
  cursor: pointer;
  padding: 20px 0;
}

.box-statistic .value-statistic {
  font-size: 50px;
  line-height: 45px;
  color: #1294f2;
  font-weight: bold;
}

.box-statistic .content-statistic {
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  font-weight: 800;
  text-transform: uppercase;
  padding: 0 0.5rem;
}

.ant-timeline-item-content .time {
  display: inline-block;
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  color: #6c7b8a;
  top: 20px;
  position: relative;
}

.workshop-box {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  display: inline-block;
  margin-left: 10px;
  width: 100%;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.102);
}

.workshop-box .description {
  display: inline-block;
  margin-left: 8px;
  padding-top: 0;
}

.workshop-box img {
  height: 50px;
  width: 50px;
}

.workshop-box .title {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #131311;
}

.workshop-box .type {
  font-size: 12px;
  line-height: 15px;
  color: #bdcadb;
}

.ant-timeline-item-head {
  top: 20px;
}

.ant-timeline-item-tail {
  top: 30px;
}

.section-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #131311;
  margin-bottom: 25px;
}

.user-header {
  font-weight: 800;
  color: black;
  font-size: 22px;
  margin-left: 30px;
  margin-bottom: 10px;
}

.ant-drawer-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #3a9bd5;
}

.ant-drawer-body {
  text-align: center;
}

.ant-drawer-body .ant-select-selection {
  width: 100%;
}

.ant-drawer-body button {
  margin-top: 20px;
  background: #008cff;
  border-radius: 100px;
  padding: 5px 40px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  border-color: #008cff;
}

/*
mahesh added*/

.content-warp {
  text-align: left;
  width: 100%;
  height: calc(100vh - 6rem);
  overflow: auto;
  position: relative;
  background: #f0f2f5;
  border-radius: 10px;
}

.section-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #131311;
  margin: 15px 0px 25px 0px;
}

.report-warp .ant-tabs-bar {
  padding: 2rem 3.2rem;

  margin: 0px;
  background-color: #ffffff;
}

.report-warp .ant-tabs-bar .ant-tabs-nav-container {
  font-style: normal;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.report-warp .ant-tabs-nav .ant-tabs-tab {
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  /*margin: 0 70px 0 0;*/
  /* margin: 0 0 0 32px; */
  padding: 20px 0px;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.report-warp .ant-tabs-tabpane {
  padding: 25px;
  background-color: transparent;
}

.box-warp {
  background: #ffffff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.102);
  border-radius: 10px;
}

.box-warp .ant-tabs-bar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #2699fb;
  font-weight: 600;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: auto;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  height: 60px;
  line-height: 60px;
  margin-right: 40px;
  border: none;
  background-color: #fff;
  border-bottom: 1px solid #e8e8e8;
  text-transform: capitalize;
}

.user-image {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 20px;
}

.ant-table-thead>tr>th {
  color: rgba(0, 0, 0, 0.85);
  text-align: left;
  background: #ffffff;
  border-bottom: none;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
}

.ant-table-tbody>tr>td {
  border-bottom: 0.5px solid #bdcadb;
}

.ant-timeline-item-content .box-warp {
  padding: 15px;
}

.ant-timeline-item-content {
  position: relative;
  top: -6px;
  margin: 0 0 0 60px;
  word-break: break-word;
}

.ant-timeline-item-content .time {
  position: absolute;
  left: -40px;
  top: 23px;
  font-size: 10px;
  line-height: 13px;
  color: #6c7b8a;
}

.ant-timeline-item-content .time-ago {
  position: absolute;
  left: -40px;
  top: 33px;
  font-size: 10px;
  line-height: 13px;
  color: #6c7b8a;
}

.ant-timeline-item-head-blue {
  color: #6c7b8a;
  border-color: #6c7b8a;
}

.ant-timeline-item-tail {
  border-left: 1px solid #bdcadb;
}

.media-report {
  display: flex;
  min-height: 80px;
  height: 100%;
  margin-bottom: 12px;
  padding: 1rem 2rem;
  background: white;
  border-radius: 10px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.102);
  width: 91%;
}

.media-report-loading  {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  min-height: 80px;
  height: 100%;
  margin-bottom: 12px;
  padding: 1rem 2rem;
  background: white;
  border-radius: 10px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.102);
  width: 91%;
}

.media h4 {
  font-size: 14px;
  line-height: 18px;
}

.media p {
  font-size: 14px;
  line-height: 18px;
  color: #131311;
  margin: 0px;
}

.download-report {
  position: absolute;
  top: 12px;
  right: 15px;
  z-index: 9;
  font-size: 16px;
  color: #ffffff;
  background: #2699fb;
  border-radius: 10px;
  padding: 5px 25px;
  height: auto;
}

.drawer-warp {
  position: absolute;
  top: 60px;
  background: #ffffff;
  right: 0;
  height: 100%;
  width: 370px;
  padding: 25px;
}

.drawer-warp img {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  height: auto;
}

.drawer-warp .ant-select-selection--single {
  border: 2px solid #bce0fd;
  height: 48px;
  margin-bottom: 20px;
  border-radius: 48px;
  padding: 8px 15px;
}

.drawer-warp button {
  background-color: #7fc4fd;
  border: 1px solid #7fc4fd;
  height: 38px;
  margin-bottom: 20px;
  border-radius: 36px;
  padding: 5px 15px;
  margin-top: 50px;
}

.drawer-warp form {
  margin-top: 50px;
  text-align: center;
}

.drawer-warp h2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #3a9bd5;
  display: inline-block;
}

.close-button {
  float: right;
  margin-top: 13px;
  margin-right: 12px;
}

.close-button i {
  font-size: 24px;
}

.ant-table-column-title {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #6a8aaa;
}

.box-warp .ant-tabs-tab {
  font-size: 18px;
}

/*
user detail css*/

.training-nav {
  margin: 0px -6px;
}

.training-nav .box-warp {
  width: 13.4%;
  padding: 20px;
  text-align: center;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.102);
  border-radius: 10px;
  display: inline-block;
  margin: 0px 6px 20px 6px;
}

.training-nav .box-warp h2 {
  font-size: 36px;
  line-height: 45px;
  color: #2699fb;
  margin: 0px;
}

.training-nav .box-warp p {
  font-size: 14px;
  line-height: 18px;
  color: #6c7b8a;
  margin: 0px;
}

.training-nav .active {
  background: #7fc4fd;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.102);
  border-radius: 10px;
}

.training-nav .active h2,
.training-nav .active p {
  color: #ffffff;
}

.training-info .box-warp {
  padding: 15px;
  margin-bottom: 9px;
  margin-right: 32px;
  margin-left: 33px;
  text-align: center;
}

.training-info .user-img {
  background: #cee9ff;
  opacity: 0.8;
  max-width: 100%;
}

.training-info .box-warp .panel-warp {
  text-align: left;
  margin-left: 24px;
}

.training-info .box-warp .panel-title {
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  color: #000000d9;
  margin: 0px;
}

.training-info {
  padding: 0 2rem;
}

.training-info .box-warp .host-name {
  font-size: 14px;
  line-height: 16px;
  color: #3a9bd5;
  margin-left: 1px;
}

.training-info .box-warp h4 {
  font-size: 17px;
  line-height: 25px;
  color: #2699fb;
  margin-bottom: 0px;
}

.training-info .box-warp p {
  margin-bottom: 0px;
}

.mt-10 {
  margin-top: 10px;
}

.training-description {
  margin: 15px -15px 0px -15px;
  /*border-top: 0.5px solid #BDCADB;*/
  /*uncomment it after poll is added*/
  text-align: left;
  padding: 15px;
}

.training-description h2 {
  font-size: 16px;
  line-height: 23px;
  color: #000000;
}

.training-description h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
}

.comment-warp .media {
  padding: 20px;
  border-top: 0.5px solid #bdcadb;
}

.comment-warp h2 {
  padding: 15px 0px;
  margin: 0px;
}

.comment-warp .media h5 {
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin: 0px;
}

.comment-warp .media h5 .circle {
  display: inline-block;
  background-color: #000;
  content: '.';
  height: 5px;
  width: 5px;
  border-radius: 5px;
  top: -1px;
  position: relative;
  margin: 0px 3px;
}

.comment-warp .media p {
  font-size: 14px;
  line-height: 18px;
  color: #6c7b8a;
  margin: 0px;
}

/*
QUIZ DETAIL*/

.question-nav {
  margin: 0px -6px;
}

.question-nav .box-warp {
  width: 13.4%;
  padding: 20px;
  text-align: center;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.102);
  border-radius: 10px;
  display: inline-block;
  margin: 0px 6px 20px 6px;
}

.question-nav .box-warp h2 {
  font-size: 36px;
  line-height: 45px;
  color: #2699fb;
  margin: 0px;
}

.question-nav .box-warp p {
  font-size: 14px;
  line-height: 18px;
  color: #6c7b8a;
  margin: 0px;
}

.question-nav .active {
  background: #7fc4fd;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.102);
  border-radius: 10px;
}

.question-nav .active h2,
.question-nav .active p {
  color: #ffffff;
}

.question-info .box-warp {
  padding: 25px 25px 25px 35px;
}

.question-info .box-warp h2 {
  margin-bottom: 0px;
}

.question-info .box-warp p {
  color: #000000;
}

.question-info .box-warp p span {
  color: #777777;
  position: relative;
  top: -1px;
  margin: 0px 10px;
}

.question-info .user-img {
  width: 120px;
  height: 72px;
  background: #cee9ff;
  opacity: 0.5;
}

.question-info .box-warp .panel-warp {
  text-align: left;
}

.question-info .box-warp .panel-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  margin: 0px;
}

.question-info .box-warp .host-name {
  font-size: 14px;
  line-height: 16px;
  color: #3a9bd5;
}

.question-info .box-warp h4 {
  font-size: 20px;
  line-height: 25px;
  color: #2699fb;
  margin-bottom: 0px;
}

.question-info .box-warp p {
  margin-bottom: 0px;
}

.mt-10 {
  margin-top: 10px;
}

.question-description {
  margin: 0px -25px 0px -35px;
  text-align: left;
  padding: 25px 25px 25px 35px;
}

.question-description h2 {
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}

.comment-warp .media {
  padding: 20px;
  border-top: 0.5px solid #bdcadb;
}

.comment-warp h2 {
  padding: 15px 0px;
  margin: 0px;
}

.comment-warp .media h5 {
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin: 0px;
}

.comment-warp .media h5 .circle {
  display: inline-block;
  background-color: #000;
  content: '.';
  height: 5px;
  width: 5px;
  border-radius: 5px;
  top: -1px;
  position: relative;
  margin: 0px 3px;
}

.comment-warp .media p {
  font-size: 14px;
  line-height: 18px;
  color: #6c7b8a;
  margin: 0px;
}

.tb-report-warp {
  padding-left: 72px;
}

.tb-report-warp span {
  display: inline-block;
  padding: 0px 15px;
  font-size: 14px;
  color: #777777;
}

.tb-report-warp .score {
  font-size: 16px;
  color: #3d3d3d;
}

.text-danger {
  color: #ff7777 !important;
}

.question-list {
  list-style: none;
  padding: 15px 0px;
  margin: 0px;
  font-size: 14px;
  line-height: 18px;
  color: #6c7b8a !important;
  /*border-top: 1px solid #BDCADB;*/
}

.question-list li span {
  color: #050505 !important;
  padding: 0px;
  font-size: 14px !important;
}

.question-list p {
  color: #6c7b8a !important;
  margin-bottom: 20px !important;
}

.question-list ul {
  list-style: none;
}

.question-list ul li {
  margin-bottom: 15px;
}

.question-list .radio {
  width: 18px;
  height: 18px;
  display: inline-block;
  border: 2px solid #bdcadb;
  box-sizing: border-box;
  border-radius: 18px;
  margin-right: 15px;
  position: relative;
  top: 4px;
  padding: 0;
}

.question-list ul li .active {
  border: 5px solid #27ae60;
}

.question-list ul li .inactive {
  border: 5px solid #e41c1c;
}

.checkbox {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #bdcadb;
  margin-right: 15px;
  position: relative;
  top: 4px;
}

.checkbox.active {
  border: 2px solid #1dba61 !important;
  color: #1dba61;
}

.checkbox.danger {
  border: 2px solid #eb5757 !important;
  color: #1dba61;
}

.checkbox.danger i {
  color: #eb5757;
  font-size: 10px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.checkbox.filled {
  border: 2px solid #1dba61 !important;
  color: #1dba61;
  background: #1dba61;
}

.checkbox.filled i {
  color: #ffffff;
  font-size: 10px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.text-success {
  color: #27ae60 !important;
}

.text-red {
  color: #ff0000 !important;
}

.text-blue {
  color: #136cfb;
}

.question-list ul .box {
  background: #ffffff;
  border: 0.5px solid #bdbdbd;
  border-radius: 5px;
  padding: 15px 5px;
}

.question-list ul .box p {
  display: inline;
}

.question-list ul .box .text-success {
  color: #27ae60 !important;
}

.question-list ul .box .text-red {
  color: #ff0000 !important;
}

.column-2 {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.question-list ul img {
  width: 150px;
  height: 98px;
  object-fit: cover;
  margin-right: 10px;
  display: inline;
  float: left;
}

.question-list ul .box-danger {
  padding: 8px;
  height: 98px;
  border: 1px solid #eb5757;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #eb5757 !important;
}

.question-list ul .box-success {
  padding: 8px;
  height: 98px;
  border: 1px solid #27ae60;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.radio-list {
  padding-left: 65px;
}

.radio-list .radio {
  margin-bottom: 5px;
}

.radio-list p {
  color: #000000 !important;
}

.column p {
  margin-bottom: 0px !important;
}

.column .ant-divider {
  opacity: 0;
  margin: 0;
}

.text-center {
  text-align: center;
}

.radio-list .filled {
  border: 5px solid #27ae60;
}

.radio-list .danger {
  border: 5px solid #eb5757;
}

.radio-list .active {
  border: 2px solid #27ae60;
}

.column span {
  padding: 0px !important;
}

.column .active i {
  font-size: 10px;
  position: absolute;
  top: 3px;
  left: 3px;
  color: #27ae60;
}

.wrap-progress-bar {
  margin-top: 13px;
  padding: 25px 39px 0px 42px;
  border-top: 1px solid #bdcadb;
}

/*new css 3-29-2020*/

.trianing-nav {
  margin: 0px -6px;
}

.trianing-nav .box-warp {
  width: 13.4%;
  padding: 20px;
  text-align: center;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.102);
  border-radius: 10px;
  display: inline-block;
  margin: 0px 6px 20px 6px;
}

.trianing-nav .box-warp h2 {
  font-size: 36px;
  line-height: 45px;
  color: #2699fb;
  margin: 0px;
}

.trianing-nav .box-warp p {
  font-size: 14px;
  line-height: 18px;
  color: #6c7b8a;
  margin: 0px;
}

.trianing-nav .active {
  background: #7fc4fd;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.102);
  border-radius: 10px;
}

.trianing-nav .active h2,
.trianing-nav .active p {
  color: #ffffff;
}

.trianing-info .box-warp {
  padding: 15px;
  margin-bottom: 2em;
  text-align: center;
  margin-left: 2em;
  margin-right: 2em;
}

.trianing-info .user-img {
  width: 120px;
  height: 72px;
  background: #cee9ff;
  opacity: 0.5;
}

.trianing-info .box-warp .panel-warp {
  text-align: left;
}

.trianing-info .box-warp .panel-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  margin: 0px;
}

.trianing-info .box-warp .host-name {
  font-size: 14px;
  line-height: 16px;
  color: #3a9bd5;
}

.trianing-info .box-warp h4 {
  line-height: 25px;
  color: #2699fb;
  margin-bottom: 0px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}

.trianing-info .box-warp p {
  margin-bottom: 0px;
}

.mt-10 {
  margin-top: 10px;
}

.trianing-description {
  margin: 15px -15px 0px -15px;
  border-top: 0.5px solid #bdcadb;
  text-align: left;
  padding: 15px;
}

.trianing-description h2 {
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}

.comment-warp .media {
  padding: 20px;
  border-top: 0.5px solid #bdcadb;
}

.comment-warp h2 {
  padding: 15px 0px;
  margin: 0px;
}

.comment-warp .media h5 {
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin: 0px;
}

.comment-warp .media h5 .circle {
  display: inline-block;
  background-color: #000;
  content: '.';
  height: 5px;
  width: 5px;
  border-radius: 5px;
  top: -1px;
  position: relative;
  margin: 0px 3px;
}

.comment-warp .media p {
  font-size: 14px;
  line-height: 18px;
  color: #6c7b8a;
  margin: 0px;
}

/*new css 3-29-2020*/

.section-title {
  font-size: 18px;
}

.trianing-nav {
  display: flex;
}

.trianing-nav .box-warp {
  width: 20%;
  flex-grow: 1;
  /*padding: 35px;*/
}

.trianing-info {
  margin-bottom: 20px;
}

.user-img {
  background: #e7f4ff !important;
  position: relative;
  opacity: 1 !important;
}

.user-img .timer {
  background: #2b2525;
  color: #fff;
  padding: 1px 5px;
  bottom: -4px;
  right: -8px;
  position: absolute;
}

.host-name {
  font-size: 14px;
  line-height: 1.4;
  color: #3a9bd5;
  margin: 5px 0px;
  display: block;
}

.host-img {
  height: 20px;
  width: 20px;
  border-radius: 20px;
  display: inline-block;
  background-color: #e7f4ff;
  margin-right: 10px;
}

.host-img img {
  height: 20px;
  width: 20px;
  border-radius: 20px;
  object-fit: cover;
}

.trianing-info .box-warp p {
  font-size: 14px;
  line-height: 18px;
  color: #8c98af;
}

.trianing-info .box-warp .panel-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #312f2fe0;
}

.custom-tooltip span {
  display: inline-block;
}

.ant-tooltip-inner {
  background-color: #f4f5fa;
  font-weight: 600;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  font-size: 10px;
  line-height: 20px;
  border-radius: 6px;
  color: #000000;
  padding: 10px;
  position: relative;
  z-index: 8;
}

.ant-tooltip-arrow::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 2px;
  display: block;
  width: 7px !important;
  height: 7px !important;
  margin: auto;
  background-color: #fff;
  content: '';
  pointer-events: auto;
  border: 1px solid #dcdcdc;
  z-index: 999;
}

.trianing-description {
  margin: 15px -15px 0px -15px;
  border-top: 0.5px solid #bdcadb;
  text-align: left;
  padding: 15px 35px;
}

.section-title .participant {
  margin-left: 2em;
}

/* .ant-row-flex-space-around {
  justify-content: space-between;
} */

.reports-user-searchbar {
  margin-right: 2rem;
  margin-bottom: 2rem;
  width: 220px;
  display: flex;
  align-items: center;
}

.reports-user-search {
  margin-right: 2rem;
  margin-bottom: 2rem;
  width: 450px;
  display: flex;
  align-items: center;
}

.reports-user-search .rounded-select {
  min-width: 140px;
  margin-right: 20px;
}

.reports-user-search .ant-select-selection--single {
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 0 4rem;
  /* border-radius: 50px; */
  /* color: #fff;
  background-color: #0068ff;
  border-color: #0068ff; */
  /* color: #0068ff; */
  /* background-color: #f7f7f7; */
  /* text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12); */
  /* -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); */
  /* box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); */
  padding: 0 10px;

  background: #ffffff;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  text-transform: uppercase;
  color: #222222;
}

.reports-user-search .ant-select-selection--single .ant-select-arrow {
  color: #222222;
}

/* .reports-user-search svg {
  fill: white;
} */

.custom-search-container {
  /* display: grid;
  place-items: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #0068ff; */
  height: 30px;
  width: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  border-radius: 100%;
  background: white;
  color: #2e70f2;
  cursor: pointer;
}

/* report changes */

.download-header-report {
  /* background: #e7e7e7;
  padding: 17px 28px;
  border: none;
  color: #136dfb;
  font-size: 16px;
  font-weight: bold;
  height: 57px; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5rem 4rem;
  border: 1px solid #e3e3e3;
  border-radius: 16px;
  transition: 0.2s;
  cursor: pointer;
}

.download-header-report:hover {
  border-color: rgba(119, 119, 119, 0.8);
}

.nav-report {
  font-size: 15px;
  font-weight: bold;
  color: black;
  height: 60px;
  background: transparent;
  display: flex;
  align-items: center;
}

.nav-report .shl-nav-report-date {
  line-height: 65px;
}

.shl-nav-report-head {
  margin-left: 2.5rem;
  line-height: 10px;
}

.nav-report>div {
  width: auto;
}

.nav-report .sharebot-tabs {
  font-weight: normal;
  font-size: 14px;
}

.nav-report>div>div {
  width: auto;
}

.nav-report-left {
  position: absolute;
  z-index: 1;
  font-size: 15px;
  font-weight: bold;
  color: black;
  height: 60px;
  background: transparent;
  line-height: 65px;
  /* width: 20%; */
  left: 12px;
}

.nav-report .ant-select-selection {
  border: none;
}

/* .nav-report .ant-select-selection .ant-select-arrow {
  right: -15px;
} */

.report-title {
  font-size: 12px;
  font-weight: bold;
  color: black;
  margin-top: 14px;
}

.select-option {
  width: fit-content;
  color: black;
  font-weight: bold;
  font-size: 15px;
  line-height: 28px;
  border-radius: 6px;
  padding: 10px 20px;
  width: 100%;
  /* border-bottom: 0.1px solid #bdcadb; */
}

.select-option img {
  width: 30px !important;
  height: 30px !important;
}

.select-option:nth-child(2) img {
  width: 24px !important;
  height: 18px !important;
}

.select-option:nth-child(3) img {
  width: 24px !important;
  height: 24px !important;
}

.select-option:hover {
  width: 100%;
}

#report_type_select>div>div>div>div>img {
  width: 34px !important;
  /* height: 32px !important; */
}

#report_type_select>div>div>div>div>span {
  font-size: 22px;
}

.select-option-user {
  width: 150px;
  color: #0068ff;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  border-radius: 6px;
}

.select-header-option {
  width: 148px;
  color: black;
  font-weight: bold;
  font-size: 13px;
  line-height: 28px;
  border-radius: 6px;
}

.custom-divider {
  width: 2px;
  height: 2em;
}

/* customize calendar */

.ant-calendar {
  font-size: 12px;
  font-weight: bold;
  border-radius: 20px;
  border: none;
  margin-top: 4px;
}

.ant-table-column-title {
  word-break: keep-all;
}

.ant-calendar-input {
  font-weight: bold;
}

.ant-calendar-input-wrap {
  padding: 5px 47px;
}

.ant-calendar-my-select {
  font-size: 17px;
}

.ant-calendar-date {
  color: #000000;
}

.ant-calendar-picker {
  margin: 0px 2px 0px 0px;
}

/* Detail table */

.custom-table {
  margin-top: 2rem;
  background: white;
  border-radius: 20px;
}

.custom-card {
  /* position: absolute; */
  width: 92%;
  border-radius: 24px;
  background: #f5f5f5f7;
  /* display: inline-block; */
  margin: 15px 33px 31px 33px;
  height: 70em;
  border: none;
}

.market-place-custom-card {
  width: 92%;
  border-radius: 24px;
  background: #f5f5f5f7;

  margin: 15px 33px 31px 33px;
  height: auto;
  border: none;
}

.custom-card-empty {
  width: 92%;
  border-radius: 24px;
  background: #f5f5f5f7;
  display: inline-block;
  margin: 31px 33px 31px 33px;
  border: none;
}

.table-img {
  height: 250px;
  width: 100%;
  border-radius: 18px;
}

.custom-description {
  color: #000000;
  margin-top: 5px;
  font-weight: 300;
}

.custom-title {
  color: #000000;
  margin-top: 18px;
}

.report-table-image {
  border-radius: 50%;
  height: 45px;
  width: 45px;
  object-fit: cover;
}

#report-header-time>div:nth-child(2)>div>div {
  width: fit-content !important;
}

@media (max-width: 840px) {
  .report-overview {
    display: block;
    height: auto;
  }

  .report-overview .overview-item h2 {
    position: inherit;
    font-size: 13px;
    margin-bottom: 8px;
  }

  .overview-item:not(:last-child) {
    margin-bottom: 20px;
  }

  .overview-item:not(:last-child)::after {
    background-color: transparent;
  }

  .report-count-info .count span {
    font-size: 3.5rem;
  }

  .report-count-info .count h3 {
    font-size: 1.5rem;
  }

  .report-count-info .activity h3 {
    font-size: 1.2rem;
    margin-top: 10px;
  }

  .report-count-container img {
    height: 6rem;
    width: 20%;
    object-fit: contain;
  }

  .report-count-info {
    width: 50%;
  }

  .report-count-container {
    height: 190px;
  }

  .report-overview {
    background: transparent;
  }

  .report-overview .overview-item {
    background: #d8e1eb;
    border-radius: 20px;
  }

  .nav-report {
    position: relative;
    line-height: initial;
    width: 100%;
    height: auto;
    background: #fff;
    padding: 15px 15px 30px 0;
    display: block;
  }

  .nav-report>div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .nav-report>div>div:nth-child(1) {
    width: 25%;
  }

  .nav-report>div>div:nth-child(2) {
    width: 75%;
  }

  .nav-report>div>div:nth-child(3) {
    width: 100%;
    border: 1px solid #f0f0f0;
    margin-bottom: 20px;
  }

  .report-warp .ant-tabs-bar {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 15px;
  }

  .report-warp .ant-tabs-bar>div {
    float: left !important;
  }

  .report-overview .overview-item h3 {
    font-size: 15px;
  }

  .sharelook-course-wrapper {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 10px 15px !important;
  }

  .sharelook-course-wrapper>div {
    width: auto;
  }

  .sharelook-course-wrapper>div:nth-child(3) {
    left: auto;
    padding: 0 20px;
  }

  .report-warp .ant-tabs-tabpane {
    padding: 25px 15px;
  }

  .sharelook-table .ant-table-content {
    overflow-x: auto;
  }

  .sharelook-table .ant-table-content table tr th {
    word-break: keep-all;
    white-space: nowrap;
  }

  .sharelook-table .ant-table-content table tr {
    height: 30px;
    line-height: 30px;
  }

  .sharelook-live-assessment #report_type_select>div>div {
    width: fit-content !important;
  }

  #report_type_select>div>div>div>div>img {
    width: 100% !important;
    height: 22px !important;
  }

  #report_type_select>div>div>div>div>span {
    font-size: 18px;
  }

  .custom-table>div>div {
    width: 100%;
  }

  .custom-table>div>div:nth-child(2)>div {
    width: auto !important;
  }

  .custom-table>div>div:nth-child(2)>div>div>div>div>div {
    white-space: nowrap;
  }

  .custom-table>div>div:nth-child(2)>div>div>div>div>div:nth-child(1)>div {
    margin-left: 0 !important;
  }

  .custom-table>div>div:nth-child(2)>div>div>div>div>div:nth-child(1)>div>div {
    width: 100% !important;
    left: 0;
    margin: 0;
  }

  .custom-table>div>div:nth-child(2)>div>div>div>div>div:nth-child(1)>div>div:nth-child(3)>div {
    margin-top: 0;
    text-align: left;
    margin-bottom: 15px;
  }

  .custom-table .custom-card-empty {
    width: auto;
    margin: 15px;
  }

  .custom-table .table-img {
    height: auto;
  }

  .box-warp {
    width: 100% !important;
  }

  .report-count-info .activity:not(:first-child)::after {
    background-color: transparent;
  }
}

.market-loading .spinner {
  position: absolute;
  z-index: 9999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgb(0 0 0 / 27%);
  width: 100%;
  height: 100%;
}

@media (max-width: 1440px) {

  .sharelook-course-participants-wrapper .reports-user-search,
  .sharelook-course-wrapper .reports-user-search {
    width: 375px;
  }

  .sharelook-course-participants-wrapper .report-title,
  .sharelook-course-wrapper .report-title {
    left: 15px;
  }

  .sharelook-course-participants-wrapper .report-pagination,
  .sharelook-course-wrapper .report-title {
    right: 1%;
  }
}

@media (max-width: 1440px) {
  .sharelook-report-wrapper {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 10px 15px !important;
  }

  .sharelook-report-wrapper>div {
    width: auto;
  }

  .sharelook-report-wrapper>div:nth-child(3) {
    left: auto;
    padding: 0 20px;
  }

  .sharelook-report-participants-wrapper .reports-user-search,
  .sharelook-report-wrapper .reports-user-search {
    width: 375px;
  }

  .sharelook-report-participants-wrapper .report-title,
  .sharelook-report-wrapper .report-title {
    left: 15px;
  }

  .sharelook-report-participants-wrapper .report-pagination,
  .sharelook-report-wrapper .report-title {
    right: 1%;
  }
}