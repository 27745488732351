@import '../../../../styles/variables';

@mixin scroll-border {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.upload-modal {
  &__text {
    text-align: center;
    font-size: 22px;
  }

  &__dropzone {
    width: 100%;
    flex-shrink: 0;
    padding: 20px 30px;

    > div {
      width: 100% !important;
      border-color: map-get($colors, darkGreyTwo) !important;
      border-radius: 0 !important;

      > input {
        width: 100%;
      }
    }
  }

  &__error-item-wrapper {
    position: relative;
    padding: 80px 20px;
    display: flex;
    justify-content: center;
    border-bottom: 0.5px solid map-get($colors, darkGreyTwo);
  }

  &__error-message-container {
    text-align: center;
    color: map-get($colors, red);
  }

  &__error-bar {
    width: 300px;
    height: 7px;
    border: 1px solid map-get($colors, red);
  }

  &__error-message {
    font-size: 12px;
  }

  &__cancel-error {
    position: absolute;
    bottom: 20px;
    right: 0;
    border-radius: 20px;
  }

  &__list-wrapper {
    border-top: 2px solid map-get($colors, darkBorder);
    padding-top: 25px;
    padding-bottom: 10px;

    &__no-border {
      border-top: none;
    }
  }

  &__list-container {
    max-height: 520px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 30px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
      border: 1px solid map-get($colors, darkGreyTwo);
      @include scroll-border;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: map-get($colors, blue);
      @include scroll-border;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: map-get($colors, purple);
    }
  }

  &__list {
    padding: 5px 0px 20px;
  }

  &__item {
    display: flex;
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 0.5px solid map-get($colors, darkGreyTwo);

    &:last-child {
      border-bottom: none;
    }
  }

  &__thumbnail {
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;

    &__details {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: 11px;

      > span {
        margin-right: 10px;
      }
    }

    &__image {
      max-width: 100%;
      margin-bottom: 15px;
      max-height: 200px;
      border: 0.5px solid #fafafa;
    }

    &__audio-video {
      width: 240px;
      height: 145px;
      background-color: #e8f0ff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
    }

    &__media-type {
      background-color: #efefef;
      color: #777777;
      border-radius: 10px;
      padding: 2px 10px;
    }
  }

  &__footer {
    border-top: 2px solid map-get($colors, darkBorder);
    text-align: right;
    padding-top: 10px;

    > button {
      background-color: #777777 !important;
      border: none;
      padding: 0 50px !important;
    }
  }
}

.upload-dropzone {
  width: 100%;
  height: 100%;
  text-align: center;
  transition: border-color 0.2s, background 0.2s;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &_event {
    // padding: 16rem 3rem;
    // border-radius: 0;
    border-radius: 8px;
    cursor: pointer;
    min-height: 25rem;
    position: relative;
    border: 1.5px solid map-get($colors, darkGreyTwo);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    border-color: rgba(0, 104, 255, 1);
  }

  &_active {
    border-color: rgba(0, 104, 255, 1);
    background: rgba(0, 104, 255, 0.2);
  }

  &_invalid {
    border-color: rgb(255, 0, 0);
  }

  &__button {
    margin-bottom: 1rem;
  }

  &__text-wrapper {
    font-size: 1.4rem;
    display: flex;
    align-items: center;
  }

  &__text {
    display: inline-block;
    width: 110px;
    text-align: left;
    margin-bottom: 0;
    margin-left: 20px;

    > span {
      color: map-get($colors, blue);
      font-weight: 700;
    }
  }
}

.upload-modal-item {
  position: relative;
  padding: 1rem 0;
  min-width: 350px;
  width: 350px;
  flex: 1;

  &__title {
    text-transform: uppercase;
  }

  &__weight {
    font-weight: 700;
    text-align: center;

    &__complete {
      text-transform: uppercase;
    }

    &__canceled {
      text-transform: uppercase;
      color: map-get($colors, red);
    }
  }

  &__percent-border {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0.8rem;
    border: 0.5px solid #efefef;
  }

  &__percent {
    background: map-get($colors, green);
    width: 0;
    height: 0.8rem;
    transition: width 0.2s;

    &__cancel {
      background: map-get($colors, red);
    }
  }
}

.media-page__list.media-list-outer {
  .media-page__item {
    @media (min-width: 1340px) {
      width: 20%;
    }
    @media (max-width: 840px) {
      width: 100%;
    }
  }
}
.media-page {
  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 -1rem;
  }

  &__item {
    padding: 0 1rem;
    margin-bottom: 2rem;
    @media (min-width: 1340px) {
      width: 33.33%;
    }
    @media (max-width: 840px) {
      width: 100%;
      margin-bottom: 3.5rem;
    }
  }

  &__controls {
    padding: 2rem 0 0 0;
    margin: 0;
  }

  &__pagination {
    float: left;
    width: 75%;
    overflow: hidden;
  }

  &__buttons {
    float: left;
    width: 25%;
    overflow: hidden;
  }

  &__no-medial-list {
    padding: 100px;
    text-align: center;
    font-style: italic;
    font-weight: 300;
    font-size: 18px;
  }
}

.media-item {
  display: initial;

  &.updated {
    display: none;
  }
}

.search__container {
  .controls__part {
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    .search {
      margin-right: 2rem;

      input {
        padding-right: 12.5rem;
      }
    }
  }
}

.custom-ant-modal {
  .ant-modal-content {
    padding: 25px 50px;
  }
}

.ant-tabs-tab-active {
  color: #777777 !important;
  font-weight: 700 !important;
}

.ant-tabs-bar {
  margin-bottom: 30px;
}

.filter-container {
  border: 1px solid #efefef;
  padding: 35px 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 840px) {
    padding: 0px 10px;
    display: block;
  }
  .left-block {
    @media (max-width: 840px) {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      height: 80px;
      margin-bottom: 20px;
    }
    .filter {
      display: initial;
      text-transform: uppercase;
      color: #777777;
      font-size: 11px;
      margin-bottom: 15px;
    }

    .status-button {
      display: none;
      justify-content: space-between;

      > button {
        margin-right: 10px;
        padding-left: 60px;
        padding-right: 60px;
      }
    }

    &.hide-filter {
      .filter {
        display: none;
      }

      .status-button {
        display: flex;
      }
    }
  }

  .right-block {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 840px) {
      justify-content: start;
    }
    > div {
      @media (max-width: 840px) {
        justify-content: start;
      }
    }
  }
}

.selectedMedia {
  align-items: center;
  display: flex;
  flex-direction: row;
  color: #333333;

  .titleMedia {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.delete-modal {
  .ant-modal-header {
    .ant-modal-title {
      font-size: 20px;
      font-weight: 800;
    }
  }
}
