.ant-tabs-nav-wrap {
  background: white;
}

.report-card-container {
  .poll_data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .ant-tabs-nav-scroll {
    text-align: left;
  }
}
