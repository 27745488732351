@import '../../../../styles/variables';

.element {
  width: 100%;
  height: 100%;

  &-draggable {
    $self: &;
    width: 100%;
    height: 100%;
    border: 1px dashed #d8d8d8;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: border-color 0.2s, background 0.2s;
    cursor: pointer;

    &:hover {
      border-color: #0068ff;
      background: rgba(#0068ff, 0.05);

      #{$self}__text {
        color: #0068ff;
      }
    }

    &__text {
      transition: color 0.2s;

      .wrap_loading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }

      .upload-modal-item {
        position: relative;
        padding: 1rem 0;
        flex: 1;

        &__title {
          text-transform: uppercase;
        }

        &__weight {
          font-weight: 700;
          text-align: center;

          &__complete {
            text-transform: uppercase;
          }

          &__canceled {
            text-transform: uppercase;
            color: map-get($colors, red);
          }
        }

        &__percent-border {
          position: relative !important;
          left: 0;
          top: 0;
          width: 100%;
          height: 0.8rem;
          border: 0.5px solid #efefef;
        }

        &__percent {
          background: map-get($colors, green);
          width: 0;
          height: 0.8rem;
          transition: width 0.2s;

          &__cancel {
            background: map-get($colors, red);
          }
        }
      }
    }

    &__preview {
      width: 100%;
      height: 100%;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
