.publish-option-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  .publish-option-buttons {
    width: 100%;
    max-width: 48%;
    .publish-option-btn {
      button {
        border: 1.5px solid #e3e3e3;
        &:hover {
          color: #5057d5;
          border: 1.5px solid #5057d5;
        }
      }
    }
  }
}
