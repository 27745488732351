.ai-course__table-of-content {
  border-radius: 20px;
  flex-basis: 400px;
  flex-grow: 1;
  flex-shrink: 1;
  background: white;

  .ai-course-table-of-content-inner {
    border: 1.5px solid #e3e3e3;
    margin: 2rem 3rem 3rem 3rem;
    border-radius: 16px;
  }

  &__generated-content-modal {
    min-width: 600px;
    width: auto !important;

    .manage-content {
      > h1 {
        font-weight: 500;
        color: #212121;
        font-size: 20px;
      }

      .content-section {
        border: 1px solid #d8d8d8;
        border-radius: 8px;
        margin: 2rem 0;

        .ant-divider {
          margin: 1rem 0 !important;
        }

        &__title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 2rem;
          padding: 2rem 3rem;
          border-bottom: 1px solid #d8d8d8;

          h2 {
            font-size: 16px;
            color: #212121;
            font-weight: 700;
          }
          span {
            font-size: 12px;
            color: #5b6ee2;
            cursor: pointer;
            font-weight: 400;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .button-group {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        width: 100%;
        button {
          height: 40px;
          border-radius: 30px;
          width: 100%;
          font-weight: 600;
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    border-bottom: 1.5px solid #e3e3e3;
  }

  &__progress {
    .ant-statistic-content {
      white-space: nowrap;
    }
  }

  &__note {
    padding-bottom: 1rem;
    font-size: 12px;
    font-style: italic;
  }
  &__section {
    max-height: 60vh;
    overflow-y: auto;
    margin-top: 1rem;
    padding-bottom: 2rem;

    .section {
      padding: 2rem;
      padding-bottom: 1rem;

      .ant-list-items {
        margin-left: 0;
        margin-right: 0;
      }

      li {
        padding: 0;
      }

      .ai-course__table-of-content__lesson-title {
        padding: 1rem 2rem;
        background: #e9eff64d;
        margin-bottom: 1rem;
        border-radius: 8px;
        position: relative;
        font-weight: 500;

        &.edit-ready {
          background: #e9eff64d;
          padding: 0 2rem 0 1rem;
          border: 1.5px solid #5b6ee2;
          input {
            background-color: transparent;
            border: none;
            &:focus {
              box-shadow: none;
            }
          }
        }
        // &::before {
        //   content: '';
        //   position: absolute;
        //   width: 100%;
        //   height: 1px;
        //   background: #d8d8d8;
        //   top: -20px;
        //   left: 0;
        // }
      }
    }
  }

  &__add-topic {
    color: #5b6ee2;
    font-weight: 400;
    font-size: 14px;
    line-height: 12.55px;
    cursor: pointer;
    background: #fff;
    border: 1.5px solid #edf2f5;
    width: 100%;
    position: relative;
    display: flex;
    padding: 1.3rem 2rem;
    border-radius: 8px;

    &:hover {
      text-decoration: underline;
    }
  }

  &__add-lesson {
    color: #2e70f2;
    font-weight: 600;
    font-size: 11px;
    line-height: 12.55px;
    cursor: pointer;
    // margin: 0 2rem;

    &:hover {
      text-decoration: underline;
    }
  }

  &__pop-over {
    &__container {
      display: flex;
      gap: 1rem;
      cursor: pointer;
    }

    &__edit {
      color: #2e70f2;
      border-bottom: none !important;

      display: grid;
      place-items: center;

      &:hover {
        text-decoration: underline;
      }
    }

    &__generate {
      color: green;
    }

    &__warning {
      color: #ff000091;
    }

    &__delete {
      color: #ff0000;
      display: grid;
      place-items: center;
    }
  }

  &__document-modal {
    &__choices {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;

      &__item {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 1.5rem;
        background: #fff;
        color: #000;
        width: 100%;
        padding: 3rem 2rem 2rem 2rem;
        border-radius: 5px;
        button {
          width: 100%;
          height: 40px;
          border-radius: 30px;
          font-size: 18px;
        }

        p {
          padding-top: 1rem;
          width: 100%;

          span {
            font-weight: bold;
          }
        }
      }
    }
  }

  &__create_lesson {
    display: flex;
    justify-content: center;
    margin: 0 2rem;
    padding: 1rem 0;
    width: 100%;
    gap: 15px;
    align-items: center;
    button {
      border-radius: 30px;
      height: 40px;
    }
    .shl-secondary-btn {
      border-radius: 30px;
      color: #5b6ee2;
      height: 40px;
      font-size: 14px;
    }

    &__button-with-notes {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      .ant-alert-banner {
        display: flex;
        align-items: center;
        height: 40px;
        border-radius: 30px;
      }
      button {
        border-radius: 30px;
        height: 40px;
      }
    }
  }
}
.generated-content {
  min-height: 300px !important;
}

.ai-course__table-of-content__lesson-topic {
  border-bottom: none;
  padding: 1rem 2rem;
  background: #fff;
  margin-bottom: 1rem;
  border-radius: 8px;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1.5px solid #e3e3e3;
  &.edit-ready {
    background: #fff;
    padding: 0 2rem 0 1rem;
    border: 1.5px solid #5b6ee2;
    input {
      background-color: transparent;
      border: none;
      &:focus {
        box-shadow: none;
      }
    }
  }
  span {
    flex: 1;
    i {
      margin-left: 5px;
    }
  }
}

.sortableHelper {
  z-index: 99999999 !important;
}

.ai-confirm-content {
  .ant-modal-content {
    padding: 2rem;
  }
  .ant-modal-title {
    color: #212121;
    font-weight: 500;
    font-size: 20px;
    text-transform: capitalize;
  }
  .ant-modal-header,
  .ant-modal-footer {
    border: none;
  }
  .ant-modal-body {
    padding: 2rem;
  }
  .ai-warning {
    p {
      padding: 1rem;
      border: 1px solid #ff9900;
      border-radius: 8px;
      margin-bottom: 3rem;
    }
    i {
      display: block;
      text-align: left;
      margin-bottom: 0.4rem;
    }
  }
  .ai-check {
    p {
      padding: 1rem;
      border: 1px solid #d80027;
      border-radius: 8px;
      margin-bottom: 1rem;
    }

    i {
      display: block;
      text-align: left;
      margin-bottom: 0.4rem;
    }
  }
  .ai-proceed {
    margin-top: 3rem;
    text-align: center;
    color: #384568;
    font-weight: 600;
    font-size: 14px;
  }
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .shl-secondary-btn,
  .shl-primary-btn {
    height: 50px;
    width: 100%;
    border-radius: 30px;
    font-weight: 600;
    font-size: 16px;
  }
  .shl-secondary-btn {
    border-color: #5b6ee2;
    color: #5b6ee2;
  }
  .ai-warning-inner {
    display: flex;
    gap: 6px;
    margin-bottom: 5px;
  }
}
.add-new-topic-open,
.add-new-lesson-open {
  .ant-modal-content {
    padding: 2rem 0;
  }
  .ant-modal-title {
    color: #212121;
    font-size: 20px;
    font-weight: 500;
  }
  .ant-modal-footer {
    > div {
      display: flex;
      gap: 15px;
      align-items: center;
      button.ant-btn {
        width: 100%;
        height: 40px;
        border-radius: 30px;
      }
    }
  }
}

.ai_text_container {
  position: relative;
  height: 50vh;
  width: 100%;
  color: #000;
  font-weight: 400;
  padding: 20px;
  .container__textarea {
    height: 100%;
    width: 100%;
    background: transparent;
    caret-color: #000;
    color: transparent;
    position: relative;
    border: none;
    outline: none;
    resize: none;
    padding: 0;
    &::selection {
      background: #40a9ff73;
      color: #333;
    }
  }
  .container__mirror {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    height: 100%;
    width: 100%;
    user-select: none;
    overflow: hidden;
    pointer-events: none;
  }
  .container__mirror a {
    pointer-events: auto;
  }
}

.ant-modal.add-course-download-open {
  max-width: 650px;
  min-width: 650px;
  .ant-modal-content {
    padding: 2rem 0;
  }
  .ant-modal-title {
    color: #212121;
    font-size: 20px;
    font-weight: 500;
  }
  .ant-modal-footer {
    > div {
      display: flex;
      gap: 15px;
      align-items: center;
      button.ant-btn {
        width: 100%;
        height: 40px;
        border-radius: 30px;
      }
    }
  }
}

.images-modal__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  background: #f4f5fa;
  padding: 2rem;
  border-radius: 8px;

  .images-modal__item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      pointer-events: none; /* Allow clicks to pass through to the image */
    }

    h2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      z-index: 1;
      pointer-events: none; /* Also prevent text from interfering with clicks */
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
      transition: transform 0.3s ease-in-out;
    }

    &:hover img {
      transform: scale(1.05);
    }
  }
}

/* When it's the last item and odd-numbered */
.images-modal__list .images-modal__item:last-child:nth-child(odd) {
  grid-column: 1 / 2; /* Place in first column */
  transform: translateX(50%); /* Move it right by 50% of its grid column */
}
