.shl-create-channel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  flex-wrap: wrap;

  h1 {
    font-weight: bold;
  }
}
