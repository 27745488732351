.scenario-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 2rem;
  gap: 2rem;
  p {
    text-align: center;
  }

  .btn-group {
    display: flex;
    gap: 2rem;
  }
}

.scenario-creation {
  &-modal {
    .ant-modal-body {
      background: rgba(240, 240, 240, 1);
      border-radius: 20px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    h3 {
      font-size: 2rem;
      color: #000;
    }
  }

  &__body {
    display: flex;
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__option {
    width: 100px;
    padding: 1rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    cursor: pointer;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      border: 1px solid #d3d3d3;
      border-radius: 20px;
      padding: 0.75rem 0.5rem;
      background: rgba(240, 240, 240, 1);
      &:hover {
        background: #f5f5f5;
      }
    }

    &.active {
      background: #fff;
    }

    &.none-active {
      border-radius: 5px;
    }

    img {
      height: 100%;
      width: 100%;
      max-width: 50px;
      max-height: 75px;
    }

    span {
      text-align: center;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 10px;
    }
  }

  &__form {
    background: #fff;
    width: 100%;
    padding: 3rem 2rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;

    &__label {
      display: flex;
      justify-content: space-between;
      font-weight: 600;

      span:first-child {
        color: #000;
      }

      span:last-child {
        color: rgba(3, 103, 254, 1);
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .ant-form-item-label label {
      color: #000 !important;
      text-transform: none;
      font-weight: 600;
      font-size: 14px;
    }

    .media-preview {
      &-container {
        position: relative;
        background: #e6e6e6;
        margin-bottom: 1rem;

        img,
        .react-player {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        img {
          object-fit: contain;
          max-height: 30rem;
        }

        &.audio {
          background: #f4f5fa;
          padding: 1rem;

          audio {
            width: 95%;
          }

          .media-preview__close-btn {
            margin: 0;
            right: 2rem;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      &__close-btn {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        cursor: pointer;
        color: #fff;
        font-size: 20px;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: rgba(255, 0, 0, 0.5);
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);

        &:hover {
          background: rgba(255, 0, 0, 0.8);
        }
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      gap: 2rem;
    }

    .options-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      .ant-form-explain {
        display: none !important;
      }
      .option-text {
        flex: 1;
        .ant-form-item-control-wrapper {
          width: 100%;
        }
      }
      .add-option {
        color: rgba(46, 112, 242, 1);
        font-size: 14px;
        font-weight: 800;
        cursor: pointer;
        width: fit-content;
        &:hover {
          text-decoration: underline;
        }
      }
      .option-item {
        display: flex;
        align-items: center;
        gap: 10px;

        .ant-form-item {
          display: flex;
          align-items: center;
          margin-bottom: 0 !important;
        }

        // .option-index {
        //   margin-bottom: 23px;
        // }

        // i {
        //   margin-bottom: 25px;
        // }
      }
    }

    &__image .ant-card-body {
      display: flex;
      align-items: center;
      gap: 1rem;

      .ant-form-item {
        flex: 1;
      }
    }
  }
}

.scenario-generation {
  width: 800px !important;
  .ant-modal-header {
    padding-top: 40px;
    .ant-modal-title {
      color: #212121;
      font-family: 'Mulish';
      font-size: 20px;
      font-weight: 500;
    }
  }

  .scenario-form {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-button-center {
      max-width: 100%;
      flex: 0 0 100%;
      justify-content: end;
    }
    > div {
      max-width: 48%;
      flex: 0 0 48%;
      margin-bottom: 0;
      .ant-form-item-children {
        > div {
          > div {
            border-radius: 8px;
            border: 1.5px solid #e3e3e3;
            outline: none;
            box-shadow: none;
            &:hover {
              border: 1.5px solid #5b6ee2;
            }
            > div {
              &:nth-child(2) {
                > span {
                  display: none;
                }
                > div {
                  color: #212121;
                }
              }
            }
          }
        }
      }
    }
    .scenario-form-label {
      color: #212121;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}
