.center {
  text-align: center;
}

.tui-image-editor-container .tui-image-editor-header-logo,
.tui-image-editor-container .tui-image-editor-controls-logo {
  float: left;
  width: 30%;
  padding: 17px;
  display: none;
}
.tui-image-editor-container .tui-image-editor-header-buttons {
  display: none;
}
.tui-image-editor-container .color-picker-control {
  top: -165px;
  left: -77px;
  width: 132px;
}
.tui-image-editor-container {
  margin: 15px;
}

.tui-image-editor-container.bottom .tui-image-editor-submenu>div {
  padding-bottom: 24px;
  // opacity: 0.6;
}

.tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-style {
  opacity: 0.7;
}

.preset-none{
  display: none !important;
}

.preset-square{
  display: none !important;
}

.preset-3-2{
  display: none !important;
}

.preset-4-3{
  display: none !important;
}

.preset-5-4{
  display: none !important;
}

.preset-7-5{
  display: none !important;
}







