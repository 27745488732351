.error-container {
  display: grid;
  place-items: center;

  .error-title {
    font-size: 16px;
    color: rgb(90, 29, 29);
    text-transform: uppercase;
    display: none;
  }

  .error-heading {
    color: rgb(21, 121, 179);
  }

  .error-points {
    margin: 1rem 0;

    .error-item {
      margin: 0.5rem 0;
      font-weight: bold;

      span {
        margin-left: 1rem;
      }
    }
  }
}
