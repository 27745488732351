.add-wrapper {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 32px;

    .trivia {
        margin: 5rem 15rem;
        color: #000000;
        width: 100%;

        .header {
            padding-top: 0;
            padding-bottom: 0;

            .title {
                font-size: 24px;
                font-weight: 600;
                text-align: center;
            }
        }

        .question {
            text-align: center;
            border: 1px solid lightgray;
            background-color: #F8F8F8;
            border-radius: 15px;
            padding: 20px;

            .ant-form-item {
                margin-bottom: 0px !important;
            }

            .ant-form-explain {
                text-align: left !important;
            }

            &_body {
                padding: 20px;

                &_context {
                    display: flex;
                    justify-content: left;
                    width: inherit;

                    &_left {
                        min-width: 50px;
                        margin-top: 18px;
                    }

                    &_right {
                        width: 100%;
                    }
                }

                &_title {
                    font-size: 16px;
                    font-weight: 600;
                    text-align: left;
                    margin: 10px 0;
                }

                .imgtitle {
                    font-size: 16px;
                    font-weight: 600;
                    padding-bottom: 10px;
                }

                .imginput {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    height: 100px;
                    width: 150px;
                    border: 1px dashed lightgray;
                    border-radius: 7px;
                    background-color: #fff;

                    img {
                        max-height: 100px;
                        max-width: 150px;
                    }

                    &:hover {
                        cursor: pointer;
                        opacity: 0.7;
                    }
                }

                .questionimg {
                    position: relative;
                    text-align: center;

                    .removebtn {
                        position: absolute;
                        right: 5px;
                        top: 20px;
                        font-size: 28px;
                        color: red;
                    }

                }

                .ansimg {
                    position: relative;
                    text-align: center;

                    .removebtn {
                        position: absolute;
                        right: 5px;
                        top: 20px;
                        font-size: 28px;
                        color: red;
                    }
                }

            }

        }
    }

}

@media screen and (min-width:780px) and (max-width: 1050px) {
    .add-wrapper {
        padding-top: 32px;

        .trivia {
            margin: 0 3.5rem;

            .header {
                padding-top: 0;
                padding-bottom: 0;
            }

            .question {
                padding: 0;

            }
        }

    }
}

@media screen and (max-width:780px) {
    .add-wrapper {
        padding-top: 32px;

        .trivia {
            margin: 0 1.5rem;

            .header {
                padding-top: 0;
                padding-bottom: 0;
            }

            .question {
                padding: 0;

            }
        }

    }
}