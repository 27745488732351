.ant-modal-header {
  color: #777777;
  background: #fff;
  border-radius: 16px 16px 0 0;
}

.jLfTGW .ql-editor {
  word-break: break-word;
  color: black;
  border: 1px solid #bdcadb;
  min-height: 400px;
  height: 400px;
  padding: 29px;
  background: #ffffff;
  margin-top: 44px;
}

.jLfTGW .ql-toolbar.ql-snow {
  margin-bottom: -44px;
}

.result-relevant-answers {
  overflow-x: hidden;
  max-height: 30em;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0.8em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
}
