@import '../../styles/variables';

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  width: 100%;
}

.spin {
  $self: &;
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 6.4rem;
  height: 6.4rem;

  &_small {
    width: 4.8rem;
    height: 4.8rem;

    #{$self}__bounce {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  &__bounce {
    width: 1.6rem;
    height: 1.6rem;
    background: map-get($colors, blue);

    border-radius: 100%;
    animation: bouncedelay 1.4s infinite ease-in-out both;

    &:nth-child(1) {
      animation-delay: -0.32s;
    }

    &:nth-child(2) {
      animation-delay: -0.16s;
    }
  }
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
