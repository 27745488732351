@import '../../styles/variables.scss';

.chat {
  &__head {
    padding: 1rem 11rem;
    border-bottom: 0.1rem solid #efefef;

    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  &__disabled {
    padding: 4rem 2.4rem;
    text-align: center;
    background: #efefef;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(100vh - 27rem);
  }

  &__content {
    max-height: 100rem;
    overflow-y: auto;
    flex-direction: column;
    margin-bottom: 1.5rem;
  }

  &__input {
    display: block;
    width: 100%;
    height: 5rem;
    padding: 1rem;
    font-size: 1.8rem;
    background: #f7f7f7;
    border-radius: 0.4rem;
    border: 0;
    resize: none;
    outline: none;
    transition: box-shadow 0.2s;

    &:focus {
      box-shadow: 0 0 0 0.1rem #0068ff;
    }
  }

  &__message {
    justify-content: flex-start;
    position: relative;
    display: flex;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;

    &:not(:last-child) {
      margin-bottom: 0;
    }

    &_my {
      justify-content: flex-end;
      margin-right: 1.2rem;
    }
    &__image {
      height: 3.2rem;
      width: 3.2rem;
    }
  }

  &-message {
    // overflow:hidden;
    align-items: flex-start;
    height: 100%;
    text-align: left;
    padding: 0.5rem 1rem;
    border-radius: 1rem 1rem 1rem 0;
    color: map-get($colors, textChat);

    &_animate {
      -webkit-transition-property: top;
      /* Safari */
      -webkit-transition-duration: 0.5s;
      /* Safari */
      transition-property: top;
      transition-duration: 0.5s;
      top: 0;
    }

    &_my {
      max-width: 80%;
      background: #0068ff;
      word-break: break-all;
      color: #fff;
      border-radius: 1rem 1rem 0 1rem;

      a {
        color: #fff;
        text-decoration: underline !important;
      }
    }

    &__thumb {
      width: 2.4rem;
      flex-shrink: 0;

      img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 100%;
      }
    }

    &__data {
      flex: 1;
    }

    &__avatar {
      position: relative;
      top: -1rem;
      height: 3.2rem;
      width: 3.2rem;
      border-radius: 50%;
      background-color: map-get($colors, darkGreyThree);
      justify-content: center;
      align-items: center;
      display: flex;
    }

    &__author {
      font-size: 1rem;
      font-weight: 700;
      padding: 0.4rem 0;
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: row;
    }

    &__content {
      justify-content: center;
      align-items: flex-start;
    }

    &__viewer {
      display: flex;
      justify-content: center;
      flex-direction: column;
      background-color: map-get($colors, chatBg);
      margin-left: 1rem;
      border-radius: 0 1rem 1rem 1rem;
      padding: 1rem;
      max-width: 400px;
      min-width: 400px;
    }

    // &__owner {
    //   display: flex;
    //   justify-content: center;
    //   flex-direction: column;
    //   border-radius: 0 1rem 1rem 1rem;
    // }

    &__wrap_info {
      display: inline-block;
    }

    &__over_flow {
      max-height: 12.5rem;
      overflow: hidden;
    }

    &__more_less {
      color: map-get($colors, blue);
      font-size: 11px;
      font-weight: bold;
    }

    // &__more_less_owner {
    //   color: map-get($colors, white);
    //   font-size: 11px;
    //   font-weight: bold;
    // }

    &__username {
      font-weight: map-get($typography, chatFontWeight);
      font-size: map-get($typography, h3FontSize);
      color: map-get($colors, usernameChat);
      font-family: 'Mulish';
    }

    &__text {
      font-weight: map-get($typography, chatFontWeight);
      font-size: map-get($typography, h2FontSize);
      font-family: 'Mulish';
      word-break: break-word;
      letter-spacing: -0.1rem;

      a:hover {
        text-decoration: underline !important;
      }
    }
  }
}
