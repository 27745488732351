.p-breadcrumbs-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.p-breadcrumbs {
  background: #fff;
  padding: 2rem 3.2rem;
  border-radius: 16px 16px 0 0;

  &-link {
    $self: &;
    display: flex;
    align-items: center;
    color: #0068ff;
    cursor: pointer;

    &:hover {
      #{$self}__icon {
        transform: translateX(-0.2rem);
      }
    }

    &__icon {
      position: relative;
      margin-right: 0.8rem;
      transition: transform 0.2s;
    }

    &__text {
      span {
        font-weight: 700;
      }
    }
  }
}
