.price-section {
  &-wrapper {
    display: flex;
    align-items: center;
    gap: 2rem;
    .ant-radio-group label {
      width: 150px;
      text-align: center;
    }
  }

  .ant-form-item-label label {
    text-transform: none;
    font-weight: bold;
  }
}
