.event__overview {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  padding-bottom: 1rem;
  &_cover {
    width: 100%;
    height: 30vh;
    object-fit: contain;
    background: #000;
  }
  &_description {
    margin-top: 1rem;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    &_top {
      position: relative;
      display: flex;
      gap: 1rem;
      justify-content: flex-start;
      margin-bottom: 2rem;
      &_tags {
        display: flex;
        flex-wrap: wrap;
        gap: 0.3rem;
      }

      &::after {
        content: '';
        height: 1px;
        width: 100%;
        background: #d6d6d6;
        position: absolute;
        bottom: -1.5rem;
      }
    }
  }

  p {
    flex: 0.45;
    padding: 1rem;
    overflow-y: auto;
    background: rgba(255, 255, 255, 0.705);
    -moz-box-shadow: inset 0 0 5px 3px #9d9d9d;
    -webkit-box-shadow: inset 0 0 3px 5px #9d9d9d;
    box-shadow: inset 0 0 5px 3px #9d9d9d;
    font-size: 17px;
    color: #000000cf;
    font-weight: 600;
  }
}
