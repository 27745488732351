.upload-btn-wrap {
  cursor: pointer;
  position: relative;

  .error {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1rem;
    color: red;
  }

  .upload-btn {
    width: 100%;
    max-width: 48%;
    text-align: left;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    gap: 10px;
    padding: 20px;
    font-size: 14px;

    > div {
      border-radius: 100%;
      width: 24px;
      height: 24px;
    }
  }

  .shl-image-upload {
    display: flex;
    min-height: 25rem;
    align-items: center;
    padding: 3rem;
    position: relative;

    .shl-image-upload-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .shl-image-image-wrap {
        display: flex;
        align-items: center;
        gap: 2rem;
        flex-wrap: nowrap;

        .shl-image-info {
          display: flex;
          flex-direction: column;
          align-items: space-between;
          gap: 2rem;

          > div {
            display: flex;
            flex-direction: column;
            line-height: 20px;

            label {
              font-weight: 600;
              font-size: 10px;
              text-transform: uppercase;
              color: #00000062;
            }

            span {
              font-weight: 600;
              color: #000000de;
            }
          }
        }
      }

      .shl-image-data {
        img {
          border-radius: 8px;
          max-height: 20rem;
          object-fit: contain;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        }
      }

      .shl-image-choose {
        display: flex;
        gap: 30px;
        align-items: center;
        position: absolute;
        top: 10px;
        right: 15px;

        img {
          width: 15px;
          height: 15px;
          object-fit: cover;
        }
      }
    }
  }
}

.secondary-image-upload {
  padding: 1rem 0rem;

  li {
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #e3e3e3;
    line-height: 0;

    &:last-child {
      border-bottom: none;
    }
  }
}
