.events__sidebar__modal {
  position: absolute;
  top: 0;
  z-index: 100;
  background: #f5f5f5;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  padding: 2rem;

  &__header {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 2rem;
    &_title {
      font-weight: bold;
      font-size: 20px;
      text-transform: uppercase;
      color: #000000;
    }

    &_close {
      color: #000000;
      font-weight: bold;
      width: 3rem;
      height: 3rem;
      display: grid;
      place-items: center;
      cursor: pointer;

      &:hover {
        background: white;
        border-radius: 50%;
        transform: scale(1.1);
      }
    }

    &:after {
      content: '';
      height: 1px;
      width: 100%;
      background: #d6d6d6;
      position: absolute;
      bottom: -1rem;
    }
  }

  &__items {
    height: calc(100vh - 19rem);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
