.emotions {
  max-width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;

  &__label {
    font-size: 1.8rem;
    font-weight: 600;
  }

  &__list {
    display: flex;
    position: relative;
    right: -1rem;
    align-items: center;
    // margin: 0 -.4rem;
  }

  &__item {
    width: auto;
    height: auto;
  }
}

.emotion {
  height: 5rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  // background: #F7F7F7;
  border-radius: 0.4rem;
  text-align: center;
  transition: 0.2s;
  padding: 0 1rem;

  &:hover {
    background: transparent;
  }

  &_disabled {
    &:hover {
      background: transparent;
      cursor: not-allowed;
    }
  }

  &__icon {
    img {
      display: block;
      width: 2.5rem;
      height: auto;
    }
  }

  &__value {
    color: black;
    padding-left: 0.5rem;
  }
}
