@import url(./vendor/quill.css);
@import url("https://fonts.googleapis.com/css?family=Mulish&display=swap");
.ant-badge-count {
  box-shadow: none !important; }

.ant-btn.rounded {
  border-radius: 5rem; }

.ant-btn.header-button {
  border-radius: 5rem;
  background-color: white !important;
  color: #0068ff;
  padding-left: 25px;
  padding-right: 25px; }

.ant-btn.ant-btn--with-icon {
  display: flex;
  align-items: center;
  justify-content: space-around; }

.ant-btn.ant-btn--secondary {
  background-color: #00bd5d !important;
  border-color: #00bd5d !important;
  font-weight: 700; }

.ant-btn[disabled] {
  background-color: #d8d8d8 !important;
  border-color: #d8d8d8 !important;
  color: #ffffff !important; }

.ant-btn-primary {
  background-color: #0068ff;
  border-color: #0068ff; }

.ant-btn-danger {
  background-color: #ff4b55 !important;
  border-color: #ff4b55 !important; }

.ant-btn-green {
  background-color: #00bd5d !important;
  border-color: #00bd5d !important;
  color: #ffffff !important; }

.ant-form {
  font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }
  .ant-form-item {
    font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }
    .ant-form-item-label {
      line-height: initial !important;
      margin-bottom: 0.4rem; }
      .ant-form-item-label label {
        font-weight: 500;
        text-transform: uppercase;
        color: #777777 !important; }
  .ant-form label {
    font-size: 1.2rem; }

.ant-input.rounded {
  border-radius: 5rem; }

.ant-spin-nested-loading > div > .ant-spin.global_ant_spin {
  max-height: initial; }

.ant-skeleton-paragraph {
  margin: 0;
  padding: 0; }

.ant-table {
  overflow-x: auto; }

.react-tel-input {
  display: flex;
  font-size: 1.4rem !important;
  font-family: inherit !important; }
  .react-tel-input input[type='text'],
  .react-tel-input input[type='tel'] {
    height: initial;
    box-sizing: border-box !important;
    margin: 0 !important;
    padding: 0 !important;
    position: relative !important;
    display: inline-block !important;
    width: 100% !important;
    height: 32px !important;
    padding: 4px 11px 4px 50px !important;
    color: #777777 !important;
    font-size: 14px !important;
    line-height: 1.5 !important;
    background-color: #fff !important;
    background-image: none !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 0 !important;
    -webkit-transition: all 0.3s !important;
    transition: all 0.3s !important; }
    .react-tel-input input[type='text']:hover, .react-tel-input input[type='text']:focus,
    .react-tel-input input[type='tel']:hover,
    .react-tel-input input[type='tel']:focus {
      border-color: #0068ff !important; }
    .react-tel-input input[type='text']:focus,
    .react-tel-input input[type='tel']:focus {
      box-shadow: 0 0 0 2px rgba(0, 104, 255, 0.2) !important; }
  .react-tel-input .flag-dropdown {
    border-radius: 0 !important; }
  .react-tel-input .selected-flag {
    border-radius: 0 !important; }
  .react-tel-input .country-list {
    width: 24rem !important;
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.15) !important; }

.styled-pagination .ant-pagination {
  color: #0068ff; }
  .styled-pagination .ant-pagination .ant-pagination-item {
    background-color: #eeeeee;
    border-color: #eeeeee;
    border-radius: 50%;
    font-weight: bold;
    min-width: 1.8rem;
    height: 1.8rem;
    line-height: 1.6rem; }
    .styled-pagination .ant-pagination .ant-pagination-item a {
      margin: 0;
      height: 100%;
      font-size: 1.1rem;
      color: #777777; }
    .styled-pagination .ant-pagination .ant-pagination-item.ant-pagination-item-active {
      background-color: #0068ff;
      border-color: #0068ff; }
      .styled-pagination .ant-pagination .ant-pagination-item.ant-pagination-item-active a {
        color: #ffffff; }

.styled-pagination .ant-pagination-simple .ant-pagination-prev,
.styled-pagination .ant-pagination-simple .ant-pagination-next {
  vertical-align: middle !important; }

.styled-pagination .ant-pagination-simple .ant-pagination-simple-pager input {
  pointer-events: none !important;
  border: none !important;
  padding: 0 !important; }

.ant-btn-background-ghost.ant-btn-primary {
  color: #0068ff;
  background: transparent !important;
  border-color: #0068ff !important;
  text-shadow: none; }

.ant-btn-background-ghost.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none;
  box-shadow: none; }

h1, .head__title {
  font-weight: 300;
  font-size: 1.8rem;
  margin: 0; }

h2 {
  font-weight: 600;
  font-size: 1.6rem;
  margin: 0; }

h3, .head__description {
  font-weight: 700;
  font-size: 1.4rem;
  margin: 0; }

h4 {
  font-weight: 300;
  font-size: 1.2rem;
  margin: 0; }

.ql-container {
  font-family: 'Mulish', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.6rem; }

.ql-editor h1, .ql-editor .head__title {
  font-weight: 800;
  font-size: 42px; }

.ql-editor h2 {
  font-weight: 800;
  font-size: 32px; }

.ql-editor h3, .ql-editor .head__description {
  font-weight: 800;
  font-size: 26px; }

.ql-editor h4 {
  font-weight: 800;
  font-size: 22px; }

.ql-editor h5 {
  font-weight: 800;
  font-size: 18px; }

.ql-editor h6 {
  font-weight: 800;
  font-size: 16px; }

.ql-editor a {
  text-decoration: underline; }

.ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px; }

.ql-editor code,
.ql-editor pre {
  background-color: #f0f0f0;
  border-radius: 3px; }

.ql-editor pre {
  white-space: pre-wrap;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px 10px; }

.ql-editor code {
  font-size: 85%;
  padding-bottom: 2px;
  padding-top: 2px; }

.ql-editor code:before,
.ql-editor code:after {
  content: '\A0';
  letter-spacing: -2px; }

.ql-editor pre.ql-syntax {
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible; }

.ql-editor img {
  max-width: 100%; }

.shl-primary-btn,
.ant-btn-primary {
  background: linear-gradient(180deg, #698bf2 0%, #5057d5 100%);
  color: #fff;
  border-radius: 30px; }
  .shl-primary-btn:hover, .shl-primary-btn:active, .shl-primary-btn:focus,
  .ant-btn-primary:hover,
  .ant-btn-primary:active,
  .ant-btn-primary:focus {
    background: linear-gradient(180deg, #698bf2 0%, #5057d5 100%);
    opacity: 0.8;
    color: #fff; }

.ant-btn {
  border-radius: 8px; }

.shl-secondary-btn {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 8px; }

*:not(input):not(textarea),
*:focus:not(input):not(textarea) {
  user-select: auto; }

html {
  font-size: 62.5%; }

.zsiq_floatmain {
  bottom: 70px !important;
  right: 60px !important; }

body {
  font-size: 1.4rem !important;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

#app {
  font-family: 'Mulish', sans-serif; }

.section {
  padding: 3.2rem; }
  @media (max-width: 840px) {
    .section {
      padding: 3.2rem 1.5rem; } }
  .section_divider {
    border-top: 1px solid #ececec; }

.head {
  padding-bottom: 2.4rem; }
  .head_nopadding {
    padding: 0; }
  .head__title {
    font-weight: 600;
    margin-bottom: 0.4rem;
    color: #212121; }
  .head__description {
    font-weight: 400;
    font-size: 14px;
    color: #676973; }

.controls {
  display: flex;
  justify-content: space-between; }
  .controls__part {
    display: flex;
    align-items: center; }
  .controls__item:not(:last-child) {
    padding-right: 1.6rem; }

.media-page__head {
  padding-bottom: 3.2rem; }

.p-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: #fff;
  border-top: 1px solid #eee; }
  .p-footer__inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1.4rem 3.2rem 1.4rem 9.6rem; }
    .p-footer__inner_justify {
      justify-content: space-between; }
  .p-footer__col {
    display: flex;
    align-items: center; }
  .p-footer__item:not(:first-child) {
    padding-left: 2rem; }
  .p-footer__item .ant-btn {
    height: 40px; }

.video {
  position: relative;
  padding-top: 56.25%; }
  .video__player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }

.static-page {
  padding: 4rem 0; }

.container {
  padding: 0 2rem;
  max-width: 128rem;
  margin: 0 auto; }

.p-tabs .ant-tabs-bar {
  margin: 0; }

.p-tabs .ant-tabs-nav {
  width: 100%;
  box-sizing: border-box; }
  .p-tabs .ant-tabs-nav > div:first-child {
    width: 100%;
    display: flex;
    align-items: center; }
    .p-tabs .ant-tabs-nav > div:first-child .ant-tabs-tab {
      flex: 1;
      margin: 0;
      text-align: center;
      font-family: 'Mulish', sans-serif;
      font-size: 1.4rem;
      font-weight: 700; }
      .p-tabs .ant-tabs-nav > div:first-child .ant-tabs-tab-active {
        color: #777; }

.shl-workshop-create {
  display: flex;
  overflow-x: auto;
  white-space: nowrap; }

.button-plus {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 7px;
  border: 1px solid #e3e3e3;
  border-radius: 16px;
  transition: 0.2s;
  margin-bottom: 2rem;
  cursor: pointer; }
  @media (max-width: 840px) {
    .button-plus {
      padding: 2rem;
      justify-content: inherit; } }
  .button-plus + .button-plus {
    margin-left: 2rem; }
  .button-plus__icon {
    position: relative;
    width: 3rem;
    height: 3rem;
    background: #edf2f5;
    border-radius: 100%; }
    .button-plus__icon::before, .button-plus__icon::after {
      content: '';
      display: block;
      width: 1.2rem;
      height: 0.2rem;
      background: #5b6ee2;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .button-plus__icon::after {
      transform: translate(-50%, -50%) rotate(90deg); }
  .button-plus__text {
    font-weight: 600;
    margin-left: 1rem; }
  .button-plus:hover {
    border-color: rgba(119, 119, 119, 0.8); }

.btn-success {
  background: #1dba61;
  color: #fff; }
  .btn-success:hover {
    background: #1dba61;
    color: #fff; }

.create_course__startmodal {
  border-radius: 5px;
  min-width: 70vw;
  padding: 0rem 24px; }
  .create_course__startmodal_content {
    padding: 12px;
    padding-top: 24px; }
  .create_course__startmodal_radiogroup {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1.5rem; }
    .create_course__startmodal_radiogroup label {
      margin-top: 1rem;
      height: auto !important;
      min-height: 40px;
      display: flex;
      align-items: center;
      line-height: 1.5 !important;
      padding: 5px 12px; }

.d-flex {
  display: flex; }

.justify-content-between {
  justify-content: space-between; }

@media (max-width: 840px) {
  .workshop-wrapper > div > div {
    width: 100%; }
    .workshop-wrapper > div > div:nth-child(2) {
      text-align: left !important;
      margin-bottom: 20px; }
    .workshop-wrapper > div > div .button-plus {
      margin: auto 10px;
      width: 90%;
      justify-content: flex-start;
      padding: 2rem 1.5rem; }
    .workshop-wrapper > div > div .button-plus + .button-plus {
      margin-left: 0;
      margin: 10px;
      width: 90%;
      justify-content: flex-start;
      padding: 2rem 1.5rem; }
  .workshop-head-wrapper > div:nth-child(2) {
    display: block !important; }
    .workshop-head-wrapper > div:nth-child(2) > div:nth-child(1) {
      overflow-x: scroll;
      overflow-y: hidden;
      height: 50px;
      white-space: nowrap;
      margin-bottom: 20px; }
  .workshop-head-wrapper > div:nth-child(3) > div {
    padding: 1.3rem 0; }
    .workshop-head-wrapper > div:nth-child(3) > div > div {
      display: block;
      padding: 0;
      margin-bottom: 35px; }
      .workshop-head-wrapper > div:nth-child(3) > div > div > div {
        display: block;
        width: 100%;
        margin: 0;
        padding: 15px 10px; }
        .workshop-head-wrapper > div:nth-child(3) > div > div > div:nth-child(1) {
          width: 100%;
          min-width: 100%;
          height: 150px; }
        .workshop-head-wrapper > div:nth-child(3) > div > div > div:nth-child(3) {
          padding: 0 10px; }
        .workshop-head-wrapper > div:nth-child(3) > div > div > div:nth-child(4) > div {
          flex-direction: inherit !important;
          gap: 50px; }
          .workshop-head-wrapper > div:nth-child(3) > div > div > div:nth-child(4) > div > a,
          .workshop-head-wrapper > div:nth-child(3) > div > div > div:nth-child(4) > div > div {
            border: 1px solid #0068ff;
            border-radius: 100%;
            height: 30px;
            width: 30px;
            line-height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0.5rem; }
            .workshop-head-wrapper > div:nth-child(3) > div > div > div:nth-child(4) > div > a div,
            .workshop-head-wrapper > div:nth-child(3) > div > div > div:nth-child(4) > div > div div {
              justify-content: center;
              display: flex;
              align-items: center; }
            .workshop-head-wrapper > div:nth-child(3) > div > div > div:nth-child(4) > div > a svg,
            .workshop-head-wrapper > div:nth-child(3) > div > div > div:nth-child(4) > div > div svg {
              width: 1.2rem; }
        .workshop-head-wrapper > div:nth-child(3) > div > div > div:nth-child(3) > div:nth-child(2) > div {
          flex-direction: inherit;
          gap: 20px; }
          .workshop-head-wrapper > div:nth-child(3) > div > div > div:nth-child(3) > div:nth-child(2) > div span {
            width: auto;
            color: #67686e;
            font-weight: 700;
            margin-top: 0; }
  .training-wrapper input {
    width: 100% !important; } }

.marketplace-head {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  align-items: center; }

.ant-modal-wrap {
  background: linear-gradient(106.91deg, rgba(248, 244, 232, 0.8) 1.04%, rgba(233, 238, 245, 0.8) 44.36%); }

.ant-btn[disabled] {
  background: #e3e3e3 !important;
  border-color: #e3e3e3 !important;
  color: #ffffff !important; }

.course-header {
  border-radius: 16px 16px 0 0; }
  .course-header .ant-steps-item.ant-steps-item-finish {
    pointer-events: none; }

.ssl-main-wrap .ant-spin-nested-loading {
  position: unset; }
  .ssl-main-wrap .ant-spin-nested-loading .ant-spin-container {
    position: unset; }

.ant-btn {
  height: 40px;
  border-radius: 30px; }

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn {
  padding: 0 4rem; }

.ant-input {
  border-radius: 8px; }

.ant-input {
  height: 40px;
  border: 1.5px solid #e3e3e3;
  color: #212121;
  border-radius: 8px; }
  .ant-input:hover, .ant-input:focus {
    border: 1.5px solid #5057d5;
    box-shadow: none; }

.margin_top textarea.ant-input:hover, .margin_top textarea.ant-input:focus {
  border: 1.5px solid #5057d5;
  box-shadow: none; }

.ant-form-item-label label {
  color: #212121 !important;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px; }

.isMarket-place-price {
  margin: 0 30px; }
  .isMarket-place-price .switch-bg_active {
    background: #5057d5; }

.quill-div_row {
  margin-top: 2rem; }
  .quill-div_row .ant-col-24 {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; }
    .quill-div_row .ant-col-24 .margin_top {
      margin: 0;
      max-width: 49%;
      flex: 0 0 49%; }
      .quill-div_row .ant-col-24 .margin_top:last-child {
        max-width: 99%;
        flex: 0 0 99%; }

.marketplace-topic {
  color: #212121;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 14px;
  margin-bottom: 4px; }

.shl-choose-modal-open {
  width: 500px; }

.ant-dropdown.ant-dropdown-placement-bottomRight ul {
  padding: 1rem;
  border-radius: 8px; }
  .ant-dropdown.ant-dropdown-placement-bottomRight ul li {
    font-weight: 600;
    font-size: 16px;
    padding: 1rem; }
    .ant-dropdown.ant-dropdown-placement-bottomRight ul li.ant-dropdown-menu-item-active {
      background: #edf2f5;
      border-radius: 8px; }

.ant-modal.image-popup-show {
  width: 1000px !important; }
  .ant-modal.image-popup-show .ant-modal-close-x {
    position: absolute;
    right: 5px;
    width: 20px;
    height: 20px;
    top: -8px; }

.scorm-full-width {
  width: 100%;
  height: 25rem;
  border-width: 1.5px;
  border-color: #e3e3e3;
  border-style: solid;
  border-radius: 8px;
  margin-top: 1.6rem; }
