.menus__container {
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: space-around;
  background: #f5f5f5;
  border-radius: 10px;
  margin-top: 1rem;
  width: 100%;
  @media (max-width: 840px) {
    overflow: auto;
    white-space: nowrap;
    align-items: center;
    justify-content: flex-start;
  }
  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    height: 100%;
    padding: 1rem 2rem;
    position: relative;

    .count {
      position: absolute;
      right: 20%;
      top: 10%;
    }
    i {
      font-size: 2rem;
      color: black;
    }

    img {
      height: 2rem;
    }

    &:hover {
      transform: scale(1.1);
      background: rgb(244, 252, 255);
    }

    .name {
      width: 100%;
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .disabled {
    cursor: not-allowed;
    opacity: 0.5;
    .name,
    // i {
    //   color: rgb(214, 212, 206);
    // }
    &:hover {
      transform: scale(1);
      background: #f5f5f5;
    }
  }

  & > img {
    height: 5rem;
    cursor: pointer;
    transition: 0.2s ease-in;

    &:hover {
      transform: scale(1.2);
    }
  }

  .active_item {
    transform: scale(1.1);
    background: rgb(244, 252, 255);
  }
}

.popoverItem {
  .menu {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    cursor: pointer;
    position: relative;
    &:not(:last-child)::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: #d6d6d6;
      height: 0.5px;
      width: 100%;
    }

    .name {
      margin-left: 1rem;
      color: black;
      text-transform: capitalize;
    }
    i > svg {
      fill: black;
    }

    img {
      height: 1.5rem;
      width: 2rem;
    }
    &:hover {
      background: rgb(231, 231, 231);
    }
  }

  .disabled {
    cursor: not-allowed;
    opacity: 0.2;
  }
}
