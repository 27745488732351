@import '../../../../../styles/variables';

.b-graph {
  position: relative;

  &__video {
    margin-bottom: 2rem;
  }

  &__progress {
    position: relative;
    background: rgba(#efefef, .5);

    &::before,
    &::after {
      position: absolute;
      font-size: 1.2rem;
    }

    &::before {
      content: attr(data-time-start);
      left: 0;
      top: 100%;
    }

    &::after {
      content: attr(data-time-end);
      right: 0;
      top: 100%;
    }

    &-line {
      height: .5rem;
      background: rgba(#777, .5);
    }
  }

  &__data {
    position: relative;
  }

  &__cursor {
    width: 2px;

    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: map-get($colors, blue);
    z-index: 2;
    transition: all .1s linear;

    &:hover {
      box-shadow: 0 0 .2rem map-get($colors, blue);
      cursor: col-resize;
    }

    &:before {
      content: attr(data-time-played);
      position: absolute;
      top: -2.2rem;
      left: 50%;
      transform: translateX(-50%);
      font-size: 1.2rem;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);

      width: .5rem;
      height: .5rem;
      border-radius: 100%;
      background: map-get($colors, white);
      border: .1rem solid #979797;
    }
  }

  &__box {
    position: fixed;
    -webkit-perspective: 1000;
    padding: 1rem;
    border-radius: .4rem;
    background: map-get($colors, white);
    border: 1px solid map-get($colors, darkGreyThree);
    z-index: 2;
    opacity: 0;
    transition: opacity .2s;

    &_active {
      opacity: 1;
    }

    &-emotion {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: .5rem;
      }

      &::before {
        content: '';
        width: 2rem;
        height: 2rem;
        border-radius: 100%;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: .5rem;
      }

      &_angry {
        &::before {
          background-image: url('/images/icons/emotions/angry.png');
        }
      }

      &_sad {
        &::before {
          background-image: url('/images/icons/emotions/sad.png');
        }
      }

      &_neutral {
        &::before {
          background-image: url('/images/icons/emotions/neutral.png');
        }
      }

      &_smile {
        &::before {
          background-image: url('/images/icons/emotions/smile.png');
        }
      }

      &_happy {
        &::before {
          background-image: url('/images/icons/emotions/happy.png');
        }
      }
    }
  }

  &__chart {
    position: relative;
    width: 100%;
    padding: 12rem 0 4rem 0;

    &-line {
      position: absolute;
      bottom: 4.4rem;
      left: 0;
      right: 0;
      height: .2rem;
      background: map-get($colors, darkGreyTwo);
    }

    &-points {
      position: relative;
      display: flex;
      align-items: center;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: .2rem;
        background: map-get($colors, darkGreyTwo);
      }
    }

    &-point {
      display: none;
      position: absolute;
      bottom: 4rem;
      width: 1rem;
      height: 1rem;
      flex-shrink: 0;
      border-radius: 100%;
      background: map-get($colors, blue);
      transition: box-shadow .2s;
      cursor: pointer;
      z-index: 1;
      transform: translate(-50%, 0);

      &:hover {
        box-shadow: 0 0 0 5px rgba(map-get($colors, blue), .1);
      }

      &::before,
      &::after {
        user-select: none;
        pointer-events: none;
      }

      &_1 {
        display: block;
        transform: translate(-50%, -2rem);

        &::before {
          content: '';
          position: absolute;
          left: 50%;
          top: 2rem;
          transform: translateX(-50%);

          width: 0;
          height: 0;
          border-bottom: .6rem solid white;
          border-right: .5rem solid transparent;
          border-left: .5rem solid transparent;
          z-index: 2;
        }

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          top: 1.6rem;
          transform: translateX(-50%);

          width: 0;
          height: 0;
          border-bottom: 1rem solid map-get($colors, darkGreyTwo);;
          border-right: .8rem solid transparent;
          border-left: .8rem solid transparent;
          z-index: 1;
        }
      }

      &_2 {
        display: block;
        transform: translate(-50%, -4rem);

        &::before {
          content: '';
          position: absolute;
          left: 50%;
          top: 2.4rem;
          transform: translateX(-50%);

          width: 0;
          height: 0;
          border-bottom: 2.5rem solid white;
          border-right: .6rem solid transparent;
          border-left: .6rem solid transparent;
          z-index: 2;
        }

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          top: 1.6rem;
          transform: translateX(-50%);

          width: 0;
          height: 0;
          border-bottom: 3rem solid map-get($colors, darkGreyTwo);;
          border-right: .8rem solid transparent;
          border-left: .8rem solid transparent;
          z-index: 1;
        }
      }

      &_3 {
        display: block;
        transform: translate(-50%, -6rem);

        &::before {
          content: '';
          position: absolute;
          left: 50%;
          top: 2.6rem;
          transform: translateX(-50%);

          width: 0;
          height: 0;
          border-bottom: 4rem solid white;
          border-right: .6rem solid transparent;
          border-left: .6rem solid transparent;
          z-index: 2;
        }

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          top: 1.6rem;
          transform: translateX(-50%);

          width: 0;
          height: 0;
          border-bottom: 5rem solid map-get($colors, darkGreyTwo);;
          border-right: .8rem solid transparent;
          border-left: .8rem solid transparent;
          z-index: 1;
        }
      }

      &_4 {
        display: block;
        transform: translate(-50%, -8rem);

        &::before {
          content: '';
          position: absolute;
          left: 50%;
          top: 3rem;
          transform: translateX(-50%);

          width: 0;
          height: 0;
          border-bottom: 6rem solid white;
          border-right: .6rem solid transparent;
          border-left: .6rem solid transparent;
          z-index: 2;
        }

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          top: 1.6rem;
          transform: translateX(-50%);

          width: 0;
          height: 0;
          border-bottom: 7rem solid map-get($colors, darkGreyTwo);;
          border-right: .8rem solid transparent;
          border-left: .8rem solid transparent;
          z-index: 1;
        }
      }

      &_5 {
        display: block;
        transform: translate(-50%, -10rem);

        &::before {
          content: '';
          position: absolute;
          left: 50%;
          top: 3.2rem;
          transform: translateX(-50%);

          width: 0;
          height: 0;
          border-bottom: 8rem solid white;
          border-right: .6rem solid transparent;
          border-left: .6rem solid transparent;
          z-index: 2;
        }

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          top: 1.6rem;
          transform: translateX(-50%);

          width: 0;
          height: 0;
          border-bottom: 9rem solid map-get($colors, darkGreyTwo);;
          border-right: .8rem solid transparent;
          border-left: .8rem solid transparent;
          z-index: 1;
        }
      }
    }
  }
}

.b-overall {
  display: inline-flex;
  margin-top: 2rem;
  background: rgba(map-get($colors, darkGreyThree), .5);
  padding: 1rem;
  border-radius: .4rem;

  align-items: center;

  &__title {
    font-size: 1.6rem;
    margin-right: 1rem;
  }

  &__box {
    background: map-get($colors, darkGreyThree);
    border-radius: .4rem;
    padding: 1rem;

    display: flex;
    align-items: center;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    i {
      display: block;
      width: 2rem;
      height: 2rem;
      border-radius: 100%;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: .5rem;
    }

    &_angry {
      i {
        background-image: url('/images/icons/emotions/angry.png');
      }
    }

    &_sad {
      i {
        background-image: url('/images/icons/emotions/sad.png');
      }
    }

    &_neutral {
      i {
        background-image: url('/images/icons/emotions/neutral.png');
      }
    }

    &_smile {
      i {
        background-image: url('/images/icons/emotions/smile.png');
      }
    }

    &_happy {
      i {
        background-image: url('/images/icons/emotions/happy.png');
      }
    }
  }
}
