.alert_message {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 104, 255, 0.2);
  z-index: 100;
}

.wrap_modal_tabs {
  padding: 0px 0px;
}

.wrap_btn_actions {
  margin-top: 10px;
  height: 80px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  background-color: #f5f5f5;
  border: 1px solid #d8d8d8;
  border-radius: 10px;

  .action_button {
    border-radius: 25px;
    width: 150px;
  }
}

/*MAHESH_ADDED*/

.bg-modal {
  background: #fff;
  margin: 0px -44px;
  padding: 20px 44px;

  .card__content {
    padding: 1.5rem 2rem;
    background: transparent;
  }
}

.audio-img-warp {
  width: 45px;
  margin-right: 10px;
  float: left;
  text-align: center;

  img {
    display: block;
    margin: auto;
    max-width: 100%;
  }
}

.toolbar__item-icon {
  line-height: 1.2;
}

.wrap-video-icons {
  position: relative;
  > img {
    display: none;
  }
}

.play-icon-inside-rectangle {
  position: absolute;
  top: 8px;
  left: 11px;
}

.text-icon-inside-rectangle {
  position: absolute;
  top: 6px;
  left: 8px;
}

.create-annotation {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 38px;
  margin-left: 1px;
  text-transform: capitalize;
  color: #000000;
}

.text-wrapper-block {
  padding-left: 7px;
}

.custom-ant-modal .ant-modal-content {
  padding: 25px 20px;

  .ant-modal-footer {
    text-align: right;
    margin-top: 9px;
  }

  .ant-modal-header {
    border-bottom: 0 solid #e8e8e8;
    padding-top: 0;
  }

  .ant-modal-title {
    color: rgb(103, 104, 108);
    font-weight: normal;
  }
}

.landscape-portrait-button {
  margin-bottom: 10px;
}

.wrap-btn-actions {
  width: '100%';
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.page-builder-preview {
  cursor: pointer;
  // width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
  width: 207px;
  border: 1.5px solid #edf2f5;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  &:hover {
    box-shadow: 0 0 4px 0 #acacac;
  }
  // on clck

  &.active {
    border-color: rgba(46, 112, 242, 1);
  }

  &.selected {
    border-color: rgba(46, 112, 242, 1);
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 100%;
    overflow-y: auto;

    .page-item {
      position: relative;

      .delete-page {
        position: absolute;
        top: auto;
        bottom: 0;
        left: 0;
        background: #ffffff5d; // shade of red
        font-weight: bold;
        font-size: 12px;
        color: #e0e0e0;
        height: 22px;
        width: 22px;
        cursor: pointer;
        border-radius: 50%;
        display: grid;
        place-items: center;
        margin: 1rem;
        // outline: 1px solid #e0e0e0;
        box-shadow: 0 0 4px 0 #acacac;

        &:hover {
          outline: 1px solid red;
        }
      }
    }
  }

  .page-number,
  .selected-check,
  .delete-page {
    position: absolute;
    top: 0;
    font-weight: bold;
    height: 22px;
    width: 22px;
    margin: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    border-radius: 50%;
  }

  .page-number {
    right: 0;
  }

  .selected-check {
    left: 0;
    background: #5057d5;
    color: white;
    font-weight: bold;
  }

  .add-new-page {
    padding: 25px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
    width: 100%;

    span {
      font-size: 35px;
      line-height: 1;
    }

    p {
      font-size: 12px;
      font-weight: 500;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0rem 2rem;
    height: calc(55.3rem + 30px + 3rem);
    background: white;
    box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    .layout-options {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 1rem;
      overflow: auto;

      height: 17rem;

      padding: 1rem;

      .layout-img {
        cursor: pointer;
        border-radius: 10px;
        outline: 1.5px solid rgb(204, 204, 204);

        &:hover {
          box-shadow: 0 0 4px 0 #acacac;
        }
      }

      .layout-selected {
        outline-color: #698bf2;
      }
    }

    .type-options {
      min-width: 100px;
      max-width: 175px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      button {
        border-radius: 30px;
        box-shadow: 0px 0px 8px 0px #f1f1ee;
        color: #fff;
        &.ant-btn-default {
          color: #212121;
        }
        &:hover {
          opacity: 0.8;
        }
      }

      // .type-option {
      //   height: 40px;
      // }
    }

    .lesson-title {
      font-weight: 600;
      font-size: 16px;
      white-space: wrap;
      max-width: 20rem;
      color: #212121;
      margin-top: 10px;
    }

    .create-story {
      display: flex;
      justify-content: center;
      margin-bottom: 5rem;
    }

    .add {
      border: 1.5px dotted #e3e3e3;
      overflow: visible;
      margin-bottom: 2rem;

      &:hover {
        border: 2px dotted #0068ff;

        .add-new-page {
          color: #0068ff;
        }
      }
    }

    .active {
      border-color: rgba(46, 112, 242, 1);
      outline: 1px solid rgba(46, 112, 242, 1);
    }

    .selected {
      border-color: #5057d5;
      outline: 2px solid #5057d5;
      box-shadow: none;

      .page-number {
        color: #5057d5;
      }
    }
  }

  > div {
    background: none;
    box-shadow: none;
    padding: 0;
    height: 100%;
    width: 100%;
  }

  .page {
    transform: scale(0.2);
    transform-origin: top left;
    pointer-events: auto;
    background: white;
    cursor: pointer;
    width: 104rem;

    & * {
      pointer-events: none;
    }
  }
}

.form-button-center {
  display: flex;
  justify-content: center;
  width: 100%;
}

.story-editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  &__part {
    width: 100%;

    textarea {
      min-height: 30vh;
    }
  }
  .ant-card-bordered {
    border: none;
    .ant-card-body {
      padding: 0;
      .story-choose {
        display: flex;
        gap: 1rem;
      }
    }
  }
}
.edit-story {
  width: 500px !important;
  .ant-modal-header {
    padding-top: 40px;
    .ant-modal-title {
      color: #212121;
      font-family: 'Mulish';
      font-size: 20px;
      font-weight: 500;
    }
  }
  .ant-form-item-children {
    position: relative;
    width: 100%;
    display: inline-block;
    .ant-select {
      width: 100%;
      .ant-select-selection {
        height: 40px;
        border: 1.5px solid #e3e3e3;
        border-radius: 8px;
        box-shadow: none;
        outline: none;
      }
    }
  }
  .shl-primary-btn {
    justify-content: end;
  }
}

.story-course-form {
  &-label {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    // text-transform: none;
    // font-weight: 500;
    // color: #000000;
    // font-size: 16px;

    i {
      color: #0068ff;
    }
  }

  .ant-form-item-label label::before,
  .ant-form-item-label label::after {
    content: '';
  }
}

.left-section {
  height: calc(55.3rem + 30px + 3rem);
  overflow: visible;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  span.ant-divider-inner-text {
    &::after {
      border: none;
    }
  }
}
