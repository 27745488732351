.reviews {
  &_container {
    height: 93%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    button {
      margin: 0 auto !important;
    }
  }

  &_list {
    flex: 1;

    hr {
      height: 1px;
      background-color: #ecebeb;
      border: none;
    }

    b {
      color: #136cfb;

      &::after {
        content: '•';
        color: rgb(196, 194, 194);
        margin-left: 1rem;
      }
    }

    &_time {
      color: #000;
    }
  }
}

.podcast-rating {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 32px;
  padding: 0.6rem 1.2rem;

  &-body {
    padding-right: 1.2rem;
  }

  &-value {
    color: #000;
    font-size: 1.6rem;
    font-weight: 600;
    padding-right: 1.2rem;
  }
}

.btn-actions {
  button {
    padding: 0 1.2rem;
    border-radius: 25px;
  }
}