.ssl-account-detail {
  padding: 2.6rem 3rem;
  display: flex;
  gap: 16px;
  cursor: pointer;
  align-items: center;
  cursor: pointer;
  .ssl-org-thumb {
    position: relative;
    width: 7rem;
    height: 7rem;
    border-radius: 100%;
    overflow: hidden;
    > div {
      display: flex;
      width: 7rem;
      height: 7rem;
      align-items: center;
      justify-content: center;
    }
  }
  .ssl-username {
    > span {
      display: block;
    }
    .user-org {
      color: #d5d9e2;
      font-size: 16px;
      text-transform: uppercase;
      line-height: 1.2;
      font-weight: 500;
    }
    .user-name {
      color: #fff;
      font-size: 18px;
      line-height: 1.3;
      font-weight: 600;
    }
    .user-mail {
      color: #d5d9e2;
      font-size: 16px;
      line-height: 1.3;
      font-weight: 500;
    }
  }
}

.ssl-scrollbar-bg {
  max-height: 21.6rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.ssl-scrollbar-bg::-webkit-scrollbar {
  width: 12px;
}

.ssl-scrollbar-bg::-webkit-scrollbar-track {
  background: #111926;
  width: 12px;
}

.ssl-scrollbar-bg::-webkit-scrollbar-thumb {
  background-color: #384568;
  border-radius: 10px;
  border: 3px solid #111926;
}

.bluish-border-color {
  background: linear-gradient(#f4faff, #f4faff) padding-box,
    linear-gradient(110.38deg, #a4efa7 18.22%, #8dc3f8 82.9%) border-box;
  border: 1px solid transparent;
  border-radius: 30px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
