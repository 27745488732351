@import '../../styles/variables';

.card {
  $self: &;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  height: fit-content;

  color: map-get($colors, darkGreyOne);
  border-radius: 8px;
  overflow: hidden;

  box-shadow: 0 1px 3px 0 rgba(68, 68, 68, 0.055),
    0 2px 5px 0 rgba(63, 62, 62, 0.1);
  border: 1px solid rgba(163, 190, 247, 0.188);
  transition: box-shadow 0.2s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07), 0 3px 10px 0 rgba(0, 0, 0, 0.1);

    #{$self}__thumb {
      &::after {
        background: rgba(map-get($colors, black), 0);
      }
    }
  }

  &_horizontal {
    min-width: 26rem;
    max-width: 26rem;
    @media (max-width: 840px) {
      min-width: 22rem;
      max-width: 22rem;
    }

    &:not(:last-child) {
      margin-right: 0.2rem;
    }
  }

  &__thumb {
    position: relative;
    overflow: hidden;
    height: 14rem;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(map-get($colors, black), 0.1);
      z-index: 1;

      transition: background 0.2s;
    }
  }

  &__badge {
    position: absolute;
    right: 1.5rem;
    bottom: 1.5rem;

    border-radius: 2rem;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.4rem 1rem;
    text-transform: uppercase;
    z-index: 2;

    &_completed {
      background: map-get($colors, green);
      color: map-get($colors, white);
    }

    &_enrolled {
      background: map-get($colors, darkGreyOne);
      color: map-get($colors, white);
    }
  }

  &__content {
    padding: 1.5rem 2rem;
    background: map-get($colors, white);
  }

  &__progress {
    position: absolute;
    left: 0;
    bottom: 0;

    height: 0.6rem;
    background: map-get($colors, green);
  }

  &__title {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    color: map-get($colors, blue);
    margin-bottom: 1rem;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__log_wrapper {
    position: relative;
  }

  &__log_unread {
    position: absolute;
    z-index: 1;
    top: 1px;
    left: 7px;
    font-size: 10px;
    color: white;
  }

  &__log {
    fill: #f4596a;
  }
  &__content-inner {
    display: flex;
    flex-direction: column;
  }
}
